import React, {FunctionComponent, useMemo, useState} from 'react';
import {Button, notification} from 'antd';
import {api} from '../../../../shared/api_client';
import {ProjectError} from '../../../../ApiEpo';
import moment from 'moment';
import {getLoadDataInfo, getPassportCache, loadData} from '../../../table_extends/loadData';
import {getUserId, getUserIsGrp} from "../../../../actions/tokenActions";
import {getWData} from "../../../table_extends/getWData";
import {getEmptyTableRecord} from "../TableRecord";
import dateFormatter from "../../../../shared/dateFormat";
import {getDateDiff, getTimeClosed} from "../utils/getErrorFieldPrefics";
import { usePassportList } from '../../../../actions/usePassportList';

const ModalErrorConfirm: FunctionComponent<{ error: ProjectError, onAction: (type: 'confirm' | 'cancel') => void, hasErrorSizeWithDates: boolean }> = (props) => {
    const [loading, setLoading] = useState<'none' | 'loadingConfirm' | 'loadingCancel'>('none');
    const passportList = usePassportList({status: 'active'});

    async function handleConfirm() {
        try {
            setLoading('loadingConfirm');
            await api.errors.closeCreate(props.error.id ?? '');
            await loadData(getPassportCache().id ?? '');
            props.onAction('confirm');
            passportList.clearCache();
        } catch (e) {
            console.log(e);
            notification.error({
                message: 'Ошибка',
                description: (e as any).error?.message ?? 'Неизвестная ошибка',
            });
        }

        setLoading('none');
    }

    async function handleCancel() {
        try {
            setLoading('loadingCancel');
            await api.errors.cancelCreate(props.error.id ?? '');
            await loadData(getPassportCache().id ?? '');
            props.onAction('cancel');
            passportList.clearCache();
        } catch (e) {
            console.log(e);
            notification.error({
                message: 'Ошибка',
                description: (e as any).error?.message ?? 'Неизвестная ошибка',
            });
        }

        setLoading('none');
    }

    const timeClosed = useMemo(() => {
        if (props.error.closed_at) {
            return getTimeClosed( props.error.created_at!, props.error.closed_at );
        }

        return '';
    }, [props.error]);

    const userId = useMemo(() => getUserId(), []);
    const allowAction = useMemo(() => {
        if (getUserIsGrp()) {
            return true;
        }
        return props.error.mentors?.filter(e => e)?.map(e => e.id).includes(userId);
    }, [props.error, userId]);
    const allowActionCancel = useMemo(() => {
        if (props.error.closed_at) {
            return props.error.closed_at
        }

        if (getUserIsGrp()) {
            return true;
        }

        if (props.error.mentors?.filter(e => e)?.map(e => e.id).includes(userId)) {
            return true
        }

        return props.error.user?.id == getUserId();
    }, [props.error, userId]);

    const isErrorWithDates = useMemo(() => {
        return props.error.type == 'contract_work_start_date_error' || props.error.type == 'contract_work_end_date_error'
    }, [props.error]);

    const isErrorWithPlaneDates = useMemo(() => {
        return props.error.type== 'contract_planning_date_error'
    }, [props.error]);

    const nomenclature = useMemo(() => getWData().rows3.find(row => row.cns_nomenclature_id == props.error?.nomenclature_id) ?? getEmptyTableRecord(), [props.error]);

    const level = useMemo(() => {
        // if (isErrorWithCompleted) return 'error';
        return props.error.error?.level;
    }, [props.error]);

    const hasErrorOverSize = useMemo(() => {
        return props.error.type == 'contract_over_error'
    }, [props.error]);

    (window as any).modalErrorConfirm = {
        ...props,
        timeClosed,
        userId,
        allowAction,
        allowActionCancel,
        nomenclature,
        level,
        isErrorWithPlaneDates,
        isErrorWithDates,
    }

    const buttonConfirm = useMemo(() => <Button type={'link'} size={'small'}
                                                className={'modal-error-confirm__approve'}
                                                onClick={handleConfirm}
                                                disabled={loading == 'loadingConfirm'} loading={loading == 'loadingConfirm'}>
        <img src={'/canvas_table/icon_warning4.svg'} alt={'Warning'}/>
        Согласовать
    </Button>, [loading])

    const buttonCancel = useMemo(() => <Button type={'link'} size={'small'}
                                               className={'modal-error-confirm__cancel'}
                                               onClick={handleCancel}
                                               disabled={loading == 'loadingCancel'} loading={loading == 'loadingCancel'}>
        <img src={'/canvas_table/icon_warning3.svg'} alt={'Warning'}/>
        Отменить
    </Button>, [loading])

    return <div className={`modal-error-confirm ${(props.hasErrorSizeWithDates && !isErrorWithDates && !props.error.closed_at) ? 'two-line' : ''} ${props.error.closed_at ? 'closed' : ''} level-${level}`}>
        {!props.error.closed_at && <>
            {(allowAction || allowActionCancel) && <>
                {props.error.type == 'contract_planning_date_error' && <>
                    {level == 'warning' && <>10 суток до плановой даты
                        контракта: {dateFormatter(nomenclature.cns_contact_date)}</>}
                    {level == 'error' && <> {getDateDiff(nomenclature.cns_contact_date ?? '')} суток до плановой даты
                        контракта: {dateFormatter(nomenclature.cns_contact_date)}</>}
                    <span className={'modal-error-confirm__buttons'}>
                        <span></span>
                        <span></span>
                        {/*{buttonConfirm2}*/}
                </span>
                </>}
                {props.error.type != 'contract_planning_date_error' && isErrorWithDates && hasErrorOverSize && <>
                    {level == 'warning' && <>{(props.error as any).description}</>}
                    {level == 'error' && <>{(props.error as any).description}</>}
                    <span className={'modal-error-confirm__buttons'}>
                        <span></span>
                        <span></span>
                        {/*{buttonConfirm2}*/}
                </span>
                </>}
                {isErrorWithPlaneDates && <>
                <span className={'modal-error-confirm__title'}>
                    Плановая дата заключения контракта {props.error.error?.contract_data?.planning_date}
                </span>
                    <span className={'modal-error-confirm__buttons'}>
                    {allowAction && buttonConfirm}
                        {allowActionCancel && buttonCancel}
                </span>
                </>}
                {!props.hasErrorSizeWithDates && isErrorWithDates && <>
                <span className={'modal-error-confirm__title'}>
                    Нарушение графика:
                </span>
                    <span className={'modal-error-confirm__buttons'}>
                    {allowAction && buttonConfirm}
                        {allowActionCancel && buttonCancel}
                </span>
                </>}
                {props.hasErrorSizeWithDates && !isErrorWithDates && <>
                <span className={'modal-error-confirm__title'}>
                    <img src={'/canvas_table/icon_warning3.svg'} alt={'Warning'} />
                    Ввод некорректных данных и нарушение графика:
                </span>
                    <span className={'modal-error-confirm__buttons'}>
                    {allowAction && buttonConfirm}
                        {allowActionCancel && buttonCancel}
                </span>
                </>}
                {!isErrorWithDates && !props.hasErrorSizeWithDates && <>
                    {allowAction && <>
                <span className={'modal-error-confirm__title'}>
                    <img src={'/canvas_table/icon_warning3.svg'} alt={'Warning'} />
                    Ввод некорректных данных:
                </span>

                        <span className={'modal-error-confirm__buttons'}>
                        {allowAction && buttonConfirm}
                            {allowActionCancel && buttonCancel}
                    </span>
                    </>}
                    {allowActionCancel && !allowAction && <>
                    <span className={'modal-error-confirm__title'}>
                        <img src={'/canvas_table/icon_warning3.svg'} alt={'Warning'} />
                        Ввод некорректных данных:
                    </span>

                        <span className={'modal-error-confirm__buttons'}>
                        <span></span>
                            {allowActionCancel && buttonCancel}
                    </span>
                    </>}
                </>}
            </>}
            {!allowAction && !allowActionCancel && <> Нет прав на принятие решения по этой ошибке </>}
        </>}
        {props.error.closed_at && <>
            <img src={'/canvas_table/icon_warning5.svg'} alt={'Warning'} />
            <span>
                    Ввод некорректных данных согласован {props.error.closed_by_user?.position ?? props.error.closed_by_user?.full_name ?? ''} через {timeClosed}
                </span>

        </>}
    </div>;
};

export default ModalErrorConfirm;
