import { Spin, Table, TableProps } from 'antd';
import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HistoryItem, HistoryList } from '../../../../ApiHistory';
import { HistoryRequest, HistoryRow } from '../../../../actions/useHistoryData';
import { historyApi } from '../../../../shared/api_client';
import {DateFormat, DateFormatFull, dateFormatter, numberFormatter} from '../../../../shared/dateFormat';
import { sizes } from '../../../../shared/sizes';
import {getValueStore} from "../../../../shared/store";
import {
    HistoryBillRecord,
    HistoryContractDataRecord,
    HistoryInvoiceRecord, HistoryResponsibleUserRecord,
    HistoryWayBillRecord,
} from '../../../../Models/history_types';

interface HistoryRecord {
    key: string;
    date: string;
    eventType: string;
    comment: string;
    user: string;
}

const columns: TableProps<HistoryRecord>['columns'] = [
    {
        title: 'Дата и время',
        dataIndex: 'date',
        key: 'date',
        width: window.innerWidth < sizes.mobile ? 90 : 150,
        render: (text, record) => (record.key ? text : ''),
    },
    {
        title: 'Тип события',
        dataIndex: 'eventType',
        key: 'eventType',
        width: window.innerWidth < sizes.mobile ? 100 : 'auto',
        render: (text, record) => (record.key ? text : ''),
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        render: (text, record) =>
            record.key ? (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
                <div style={{ textAlign: 'center', padding: 20 }}>
                    <Spin size="small"></Spin>
                </div>
            ),
        width: window.innerWidth < sizes.mobile ? 200 : 'auto',
    },
    {
        title: 'Сотрудник',
        key: 'user',
        dataIndex: 'user',
        width: window.innerWidth < sizes.mobile ? 150 : 'auto',
        render: (text, record) => (record.key ? text : ''),
    },
];
const ModalHistoryTable: FunctionComponent<{ params: HistoryRequest }> = function (props) {
    const tableRef = useRef();

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paramsOld, setParamsOld] = useState<HistoryRequest | undefined>(undefined);

    const [historyData, setHistoryData] = useState<HistoryList | undefined>();
    const [historyPages, setHistoryPages] = useState<{ [page: number]: HistoryItem[] }>({});

    async function load(page = 1, params: HistoryRequest) {
        (window as any).modalHistoryTable.loading = true;
        setLoading(true);
        const res = await historyApi.changes.changesList({ ...params, page, page_size: 100 });
        setHistoryData(res.data);
        (window as any).modalHistoryTable.historyData = res.data;

        if (res.data?.data?.length && res.data.meta?.current_page) {
            setHistoryPages({
                ...(window as any).modalHistoryTable.historyPages,
                [res.data.meta.current_page]: res.data.data ?? [],
            });
        }

        (window as any).modalHistoryTable.loading = false;
        setLoading(false);
    }

    function getScrollPositionByEnd() {
        const tb = document.querySelector('.ant-table-body');
        return tb ? tb.scrollHeight - tb.scrollTop - tb.clientHeight : 0;
    }

    function loadNext() {
        if ((window as any).modalHistoryTable.loading) return;
        if (
            (window as any).modalHistoryTable?.historyData?.meta?.last_page &&
            (window as any).modalHistoryTable.historyPages &&
            (window as any).modalHistoryTable.historyPages.length >
                ((window as any).modalHistoryTable.historyData.meta.last_page ?? 0)
        )
            return;
        if (
            getScrollPositionByEnd() < window.innerHeight / 2 &&
            !(window as any).modalHistoryTable.loading &&
            (window as any).modalHistoryTable?.page <
                ((window as any).modalHistoryTable?.historyData?.meta?.last_page ?? 1000)
        ) {
            setPage(page + 1);
            (window as any).modalHistoryTable.page++;
            console.log(`load next page ${(window as any).modalHistoryTable.page}`);
            load((window as any).modalHistoryTable.page, (window as any).modalHistoryTable.params);
        }
    }

    useEffect(() => {
        if (JSON.stringify(props.params) == JSON.stringify(paramsOld)) return;
        setHistoryData(undefined);
        setHistoryPages({});
        setPage(1);
        setParamsOld(props.params);
        setTimeout(() => load(1, props.params), 100);

        (window as any).modalHistoryTable.page = 1;
        (window as any).modalHistoryTable.params = props.params;
        (window as any).modalHistoryTable.historyPages = {};

        const tb = document.querySelector('.ant-table-body');
        tb?.removeEventListener('scroll', loadNext);
        tb?.addEventListener('scroll', loadNext);
    }, [props.params]);

    const data: HistoryRecord[] = [];

    for (let item of Object.values(historyPages)) {
        let index = 0;
        for (let row of item) {
            index++;
            const user =
                row.changes?.user?.username ?? row.changes?.user?.login ?? row.user_id ?? 'Неизвестный пользователь';

            const obj = row.changes?.object?.repr ?? 'Неизвестная сущность';

            let eventType = `${row.object_type} ${obj}`;

            const action = (row.changes?.changes as any)?.action;
            const sections = (row.changes?.changes as any)?.sections?.[0]
                ?.map((item: any) => `${item?.title}: ${numberFormatter(item?.guideline_amount ?? 0)}`)
                .join(', ');
            const record: HistoryRow | undefined = (row.changes as any)?.row;
            const recordView = record
                ? `<b>${record.title} (${record.unit})</b> с ценой ${record.plan_budget?.price} и объемом ${record.plan_budget?.volume}`
                : '';

            let localeLevel = '';

            if (record) {
                if (record.nomenclature_id) {
                    localeLevel = 'Номенклатура';
                } else if (record.type_id) {
                    localeLevel = 'Вид работ';
                } else if (record.section_id) {
                    localeLevel = 'Раздел';
                } else if (record.project_id) {
                    localeLevel = 'Проект';
                }
            }

            let debugComment = true;

            let resultComment = [action, sections].filter((e) => e).join('\n');

            const operation = (row as any).operation;

            if (row.object_type == 'Project' && operation == 1) {
                eventType = 'Проект изменен';
                const changeLog: string[] = [];

                if ((row.changes?.changes as any)?.start_date) {
                    changeLog.push(
                        `Изменена дата начала проекта с ${dateFormatter((row.changes?.changes as any)?.start_date?.[0])} на ${dateFormatter((row.changes?.changes as any)?.start_date?.[1])}.`,
                    );
                }

                if ((row.changes?.changes as any)?.end_date) {
                    changeLog.push(
                        `Изменена дата завершения проекта с ${dateFormatter((row.changes?.changes as any)?.end_date?.[0])} на ${dateFormatter((row.changes?.changes as any)?.end_date?.[1])}.`,
                    );
                }

                if ((row.changes?.changes as any)?.members?.[0]?.length) {
                    changeLog.push(
                        `Удалены участники проекта: ${(row.changes?.changes as any)?.members?.[0]?.map((member: any) => member?.full_name).join(', ')}.`,
                    );
                }

                if ((row.changes?.changes as any)?.members?.[1]?.length) {
                    changeLog.push(
                        `Добавлены участники проекта: ${(row.changes?.changes as any)?.members?.[1]?.map((member: any) => member?.full_name).join(', ')}.`,
                    );
                }

                if ((row.changes?.changes as any)?.title?.[1]?.length) {
                    changeLog.push(
                        `Изменен шифр проекта с ${(row.changes?.changes as any)?.title?.[0]} на ${(row.changes?.changes as any)?.title?.[1]}.`,
                    );
                }

                if ((row.changes?.changes as any)?.total_area?.[1]) {
                    changeLog.push(
                        `Изменена площадь проекта с ${(row.changes?.changes as any)?.total_area?.[0]} на ${(row.changes?.changes as any)?.total_area?.[1]}.`,
                    );
                }

                if ((row.changes?.changes as any)?.location?.[1]?.length) {
                    changeLog.push(
                        `Изменено расположение проекта с ${(row.changes?.changes as any)?.location?.[0]} на ${(row.changes?.changes as any)?.location?.[1]}.`,
                    );
                }

                if (!changeLog.length) {
                    changeLog.push('Изменения не определены.');
                }

                resultComment = changeLog.join('<br />');
                debugComment = false;
            }

            // Добавление ответственного в номенклатуру
            if (row.object_type == 'ProjectNomenclatureResponsibleUser') {
                let responsibleUsers = (row.changes?.row as any)?.responsible_users as HistoryResponsibleUserRecord[];
                const responsibleUsersView = responsibleUsers.map( user => {
                    return `<b>${user?.name}</b>`;
                }).join(', ')

                if (operation == 0) {
                    eventType = 'Добавлен ответственный пользователь';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлен ответственный пользователь: ${responsibleUsersView}.`;
                }
                if (operation == 2) {
                    eventType = 'Удален ответственный пользователь';
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удалены ответственный пользователь: ${responsibleUsersView}.`;
                }
                debugComment = false;
            }

            // Добавление контрагента в номенклатуру
            if (row.object_type == 'ProjectNomenclatureContractor') {
                let contract_data = (row.changes?.row as any)?.contract_data as any[];
                let contractors = contract_data?.[4] as HistoryContractDataRecord[] ?? [];
                const hasManyContractors = contractors?.length > 1;
                const contractorsView = contractors.map( contractor => {
                    return `<b>${contractor?.name}</b>`;
                }).join(', ')

                if (operation == 0 && hasManyContractors) {
                    eventType = 'Добавлены поставщики';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлены поставщики: ${contractorsView}.`;
                }
                if (operation == 0 && !hasManyContractors) {
                    eventType = 'Добавлен поставщик';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлены поставщик: ${contractorsView}.`;
                }
                if (operation == 2 && hasManyContractors) {
                    eventType = 'Удалены поставщики';
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удалены поставщики: ${contractorsView}.`;
                }
                if (operation == 2 && !hasManyContractors) {
                    eventType = 'Удален поставщик';
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удален поставщик: ${contractorsView}.`;
                }
                debugComment = false;
            }

            // Работа с контрактом
            if (row.object_type == 'ProjectNomenclatureContract') {
                let contract = row.changes?.row?.contract;
                const contractView = contract
                    ? `<b>${contract?.contractor} от ${dateFormatter(contract?.contract_date ?? '')}</b> с ценой ${contract?.price}, объемом ${contract?.volume} и суммой ${contract?.amount}`
                    : '';
                if (operation == 0) {
                    eventType = 'Добавлен контракт';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлен контракт № ${contractView}.`;
                }
                if (operation == 2) {
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удален контракт № ${contractView}.`;
                    eventType = 'Удален контракт';
                }
                debugComment = false;
            }

            // Работа со счетом
            if (row.object_type == 'ProjectNomenclaturePaymentInvoice') {
                let paymentInvoice = row.changes?.row?.payment_invoice;
                const paymentView = paymentInvoice
                    ? `<b>${paymentInvoice?.invoice_number} от ${dateFormatter(paymentInvoice?.invoice_date ?? '')}</b> с ценой ${numberFormatter(paymentInvoice?.price ?? 0)}, объемом ${numberFormatter(paymentInvoice?.volume ?? 0)} и суммой ${numberFormatter(paymentInvoice?.amount ?? 0)}`
                    : '';
                if (operation == 0) {
                    eventType = 'Добавлен счет';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлен счет № ${paymentView}.`;
                }
                if (operation == 2) {
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удален счет № ${paymentView}.`;
                    eventType = 'Удален счет';
                }
                debugComment = false;
            }

            // Работа с ТТН
            if (row.object_type == 'ProjectNomenclatureWaybill') {
                let wayBill = (row.changes?.row as any)?.waybill;
                const paymentView = wayBill
                    ? `<b>${wayBill?.waybill_number} от ${dateFormatter(wayBill?.waybill_date)}</b> с ценой ${numberFormatter(wayBill?.price)}, объемом ${numberFormatter(wayBill?.volume)} и суммой ${numberFormatter(wayBill?.amount)}`
                    : '';
                if (operation == 0) {
                    eventType = 'Добавлен ТТН';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлен ТТН № ${paymentView}.`;
                }
                if (operation == 2) {
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удален ТТН № ${paymentView}.`;
                    eventType = 'Удален ТТН';
                }
                debugComment = false;
            }

            // Работа с СФ
            if (row.object_type == 'ProjectNomenclatureBill') {
                let wayBill = (row.changes?.row as any)?.bill;
                const paymentView = wayBill
                    ? `<b>${wayBill?.bill_number} от ${dateFormatter(wayBill?.bill_date)}</b> с ценой ${numberFormatter(wayBill?.price)}, объемом ${numberFormatter(wayBill?.volume)} и суммой ${numberFormatter(wayBill?.amount)}`
                    : '';
                if (operation == 0) {
                    eventType = 'Добавлена СФ';
                    resultComment = `В номенклатуру ${row.changes?.object?.repr} добавлена СФ № ${paymentView}.`;
                }
                if (operation == 2) {
                    resultComment = `В номенклатуре ${row.changes?.object?.repr} удалена СФ № ${paymentView}.`;
                    eventType = 'Удалена СФ';
                }
                debugComment = false;
            }

            // Изменение дат
            if (row.object_type == 'ProjectNomenclatureDates') {
                const changes = row.changes?.changes as any;
                if (changes?.contract_data?.planning_date?.[1]) {
                    eventType = 'Изменены даты';
                    resultComment = `Установлена плановая дата ${changes?.contract_data?.planning_date?.[1]} для номенклатуры <b>${row.changes?.object?.repr}</b>.`;
                    debugComment = false;
                } else {
                    eventType = 'Изменены даты';
                }
            }

            // Смена статуса
            if ((row.changes?.changes as any)?.status?.[1]) {
                const status = (row.changes?.changes as any)?.status?.[1];
                if (status == 'active') {
                    eventType = 'Проект восстановлен';
                }
                if (status == 'active' && operation == 0) {
                    eventType = 'Проект создан';
                }
                if (status == 'archive') {
                    eventType = 'Проект отправлен в архив';
                }
                resultComment = `Проект <b>${obj}</b>`;
                debugComment = false;
            }

            // Удаление номенклатуры
            if (row.object_type == 'ProjectNomenclature' && operation == 2) {
                eventType = 'Номенклатура удалена';
                resultComment = `Номенклатура ${recordView} удалена`;
                debugComment = false;
            }

            // Подтверждение БО
            if (row.object_type == 'ProjectGuidelineBudgetApprove') {
                eventType = 'Подтвержден БО';
                resultComment = `Проект ${obj}: ` + resultComment;
                debugComment = false;
            }

            // БП отправлен на согласование
            if ((row.changes?.changes as any)?.plan_budget?.status) {
                const statuses: string[] = (row.changes?.changes as any)?.plan_budget?.status;

                if (statuses[1] == 'on_approval') {
                    eventType = 'БП отправлен на согласование';
                }
                if (statuses[1] == 'approved') {
                    eventType = 'БП согласован';
                }

                if (statuses[1] == 'rejected') {
                    eventType = 'БП отклонен';
                }

                if (statuses[1]?.toString() == 'draft') {
                    eventType = 'Разрешил изменение';
                }

                resultComment = `${localeLevel} ${recordView || obj}`;
                debugComment = false;
            }

            if (debugComment || getValueStore('debugHistory')) {
                let changes = JSON.stringify(
                    row.changes?.changes,
                    null,
                    getValueStore('debugHistoryPrint') ? 2 : 0,
                )
                    .replaceAll('\n', '<br>')
                    .replaceAll(' ', '&nbsp;&nbsp;');
                resultComment += `<br><b>changes.row:</b> ${JSON.stringify(row.changes?.row)}<br><b>changes.changes:</b> ${changes}<br><b>object_type:</b> ${row.object_type}<br><b>operation:</b> ${operation}`;
            }

            data.push({
                key: `${index}_${Math.random()}`,
                date:
                    window.innerWidth > sizes.mobile
                        ? moment((row.timestamp ?? 0) * 1000).format(DateFormatFull)
                        : moment((row.timestamp ?? 0) * 1000).format(DateFormat),
                comment: resultComment,
                eventType: eventType,
                user: user,
            });
        }
    }

    (window as any).modalHistoryTable = {
        ...((window as any).modalHistoryTable ?? {}),
        historyPages,
        historyData,
        data,
        params: props.params,
        loadNext,
    };

    useEffect(() => {
        (window as any).modalHistoryTable = {
            ...((window as any).modalHistoryTable ?? {}),
            page: page,
        };
    }, []);

    return (
        <Table
            ref={tableRef.current}
            className={'ModalHistoryTable'}
            columns={columns}
            dataSource={
                (window as any).modalHistoryTable.loading
                    ? [...data, { key: '', user: '', date: '', eventType: '', comment: '' }]
                    : data
            }
            pagination={false}
            scroll={{ y: (document.querySelector('.ModalHistoryTable')?.getBoundingClientRect().height ?? 200) - 80 }}
        />
    );
};

export default ModalHistoryTable;
