import { TypeModalEnum } from '../../utils/getErrorFieldPrefics';
import { ProjectError } from '../../../../../ApiEpo';
import { ErrorTypeEnum } from '../../utils/error_type';
import { TableRecord, TableRecordContragent } from '../../TableRecord';

export function getErrorsByContracts(props: {type: TypeModalEnum, errors: ProjectError[], item: TableRecordContragent, contracts: ( TableRecordContragent & { line?: TableRecord })[]}) {
  let errors2 = props.errors.filter(e => props.type == TypeModalEnum.contracts && (e.error as any)?.contract?.id == props.item.id);
  errors2.sort((e1, e2) => e1.type == "contract_over_error" ? 1 : -1);
  const errorsByContract = errors2.filter(e => !e.closed_at);
  const errorsByContractClosed = errors2.filter(e => e.closed_at);


  const isErrorSize = errors2.find(e => [ErrorTypeEnum.CONTRACT_OVER_ERROR, ErrorTypeEnum.INVOICE_OVER_ERROR, ErrorTypeEnum.WAYBILL_OVER_ERROR, ErrorTypeEnum.BILL_OVER_ERROR].includes(e.type as ErrorTypeEnum));
  const isErrorWithDates = errors2.find(e => e.type == ErrorTypeEnum.CONTRACT_WORK_START_DATE_ERROR || e.type == ErrorTypeEnum.CONTRACT_WORK_END_DATE_ERROR);

  let hasStatusWrapClass;

  props.contracts.some((it) => {
    //  console.log('contracts',it);
    let errorsWrap = errorsByContract.find(e => e.nomenclature_id == it.line?.cns_nomenclature_id)
      || errorsByContractClosed.find(e => e.nomenclature_id == it.line?.cns_nomenclature_id);
    if (errorsWrap) {
      if (it.status !== 'signed') {
        hasStatusWrapClass = errorsWrap.closed_at ? 'has_collapse_approval_by_spm2 has_collapse_warning' : 'has_collapse_approval_by_spm2 has_collapse_error';
      } else {
        hasStatusWrapClass = errorsWrap.closed_at ? 'has_collapse_warning' : 'has_collapse_error';
      }

      return true;
    } else if (it.status !== 'signed') {
      hasStatusWrapClass = 'has_collapse_approval_by_spm2'
      return true;
    }
    return false;
  });



  return {errorsByContract, errorsByContractClosed, isErrorSize, isErrorWithDates, hasStatusWrapClass}
}


