/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreatePriceResponse {
  /**
   * @format uuid
   * @example "2023-11-27T03:39:53.608Z"
   */
  created_at?: string;
  /** @example "{}" */
  data?: string;
  /** @example null */
  deleted_at?: any;
  /**
   * @format uuid
   * @example "17e05299-4423-4de9-9aad-9ea9ee7f831f"
   */
  id?: string;
  /**
   * @format uuid
   * @example "e113fa80-6bc3-4e42-b21a-e00a81122eaf"
   */
  measure_unit_id?: string;
  /** @example "Работа. Противопожарный водопровод В2" */
  name?: string;
  /** @example 3 */
  order?: number;
  /** @example [{"price":123.1,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77"},{"price":100,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083"}] */
  prices?: {
    /** @example 123.1 */
    price?: number;
    /**
     * @format uuid
     * @example "03381f7d-c02a-4213-bf52-2c47b0337f77"
     */
    region_id?: string;
  }[];
  /** @example "active" */
  status?: string;
  /** @example "Работа. Противопожарный водопровод В2" */
  title?: string;
  /** @example 1 */
  type?: number;
  /** @example "2023-11-27T03:39:53.608Z" */
  updated_at?: string;
}

export interface CreateContractorRequest {
  /** Наименование */
  name: string;
  /**
   * Идентификатор типа контрагентов
   * @format uuid
   */
  type_id?: string;
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /** Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый) */
  rating?: 1 | 2 | 3;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date: string;
  documents?: {
    /**
     * Идентификатор загруженного файла
     * @format uuid
     */
    file_id?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
  }[];
}

export interface UpdateContractorRequest {
  /** Наименование */
  name?: string;
  /**
   * Идентификатор типа контрагентов
   * @format uuid
   */
  type_id?: string;
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /** Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый) */
  rating?: 1 | 2 | 3;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор загруженного файла
     * @format uuid
     */
    file_id?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
  }[];
}

export interface Contractor {
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  id?: string;
  /**
   * Номер контрагента
   * @example 1
   */
  row_number?: number;
  /** Наименование */
  name?: string;
  type?: {
    /**
     * Идентификатор типа контрагентов
     * @format uuid
     */
    id?: string;
    /**
     * Название типа контрагента
     * @example "по счету"
     */
    name?: string;
  };
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /**
   * Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый)
   * @example 1
   */
  rating?: number;
  /** @example "Черный" */
  rating_label?: string;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор документа
     * @format uuid
     */
    id?: string;
    /** @example "Документ 1.pdf" */
    name?: string;
    /**
     * Ссылка на файл
     * @format url
     */
    link?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /**
     * Идентификатор реквизита
     * @format uuid
     */
    id?: string;
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface ContractorShort {
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  id?: string;
  /**
   * Номер контрагента
   * @example 1
   */
  row_number?: number;
  /** Наименование */
  name?: string;
  type?: {
    /**
     * Идентификатор типа контрагентов
     * @format uuid
     */
    id?: string;
    /**
     * Название типа контрагента
     * @example "по счету"
     */
    name?: string;
  };
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /**
   * Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый)
   * @example 1
   */
  rating?: number;
  /** @example "Черный" */
  rating_label?: string;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор документа
     * @format uuid
     */
    id?: string;
    /** @example "Документ 1.pdf" */
    name?: string;
    /**
     * Ссылка на файл
     * @format url
     */
    link?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /**
     * Идентификатор реквизита
     * @format uuid
     */
    id?: string;
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
}

export interface MetaData {
  /** @example 1 */
  current_page?: number;
  /** @example 10 */
  per_page?: number;
  /** @example 20 */
  total?: number;
  /** @example 2 */
  last_page?: number;
}

export interface SimpleResponse {
  /** @example true */
  success?: boolean;
}

export interface Error {
  message?: string;
  code?: string;
}

export interface UploadedFile {
  /**
   * Идентификтор файла
   * @format uuid
   */
  id?: string;
  /**
   * Имя файла
   * @example "file.pdf"
   */
  name?: string;
  /**
   * Mime-тип файла
   * @example "application/pdf"
   */
  content_type?: string;
  /** Размер файла в байтах */
  size?: number;
  /**
   * Расширение файла (если удалось определить)
   * @example ".pdf"
   */
  ext?: string;
  /** Название бакета в S3 хранилище */
  bucket?: string;
  /** Путь до файла в S3 */
  path?: string;
  /** Ссылка на файл */
  url?: string;
  /**
   * Дата загрузки файла
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата обновления файла
   * @format date-time
   */
  "updated-at"?: string;
}

export interface ContractorList {
  data?: ContractorShort[];
  meta?: MetaData;
  filters?: {
    types?: {
      /** @format uuid */
      value?: string;
      label?: string;
    }[];
    rating?: {
      value?: number;
      label?: string;
    }[];
  };
}

export type DirectoryPlaneList = Directory[];

export interface Directory {
  /**
   * Идентификатор объекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование объекта
   * @example "Россия"
   */
  title?: string;
  /**
   * Код объекта
   * @example "ru"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Порядковый номер объекта в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления объекта в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления объекта в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export type WorkSectionPlaneList = WorkSection[];

export interface WorkSection {
  /**
   * Идентификатор раздела
   * @format uuid
   */
  id?: string;
  /**
   * Наименование раздела
   * @example "Россия"
   */
  title?: string;
  /**
   * Код раздела
   * @example "ru"
   */
  name?: string;
  /**
   * Статус раздела в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Сдвиг плановой даты заключения контрактов от даты старта работ
   * @example 30
   */
  contract_date_shift?: number;
  /**
   * Порядковый номер раздела в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления раздела в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления раздела в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export type NomenclaturePlaneList = Nomenclature[];

export interface Prices {
  /** @example [{"work_sections_order":0,"work_sections_status":"active","work_sections_title":"Дополнительные работы (TI)","work_types":[{"prices":[{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":1},{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":1},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":100,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":2},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":123.1,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":2}],"work_types_order":0,"work_types_status":"active","work_types_title":"Вид работ 4"}]}] */
  data?: {
    /** @example 0 */
    work_sections_order?: number;
    /** @example "active" */
    work_sections_status?: string;
    /** @example "Дополнительные работы (TI)" */
    work_sections_title?: string;
    /** @example [{"prices":[{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":1},{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":1},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":100,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":2},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":123.1,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":2}],"work_types_order":0,"work_types_status":"active","work_types_title":"Вид работ 4"}] */
    work_types?: {
      /** @example [{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":1},{"nomenclature_id":"41fb4856-6279-4fa8-a551-ecb7a97518b9","nomenclature_order":0,"nomenclature_status":"active","nomenclature_title":"Строка 2","price":200,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":1},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":100,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","region_name":"Томск","region_order":2,"region_status":"active","row_number":2},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","nomenclature_order":3,"nomenclature_status":"active","nomenclature_title":"Работа. Противопожарный водопровод В2","price":123.1,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77","region_name":"Новосибирск","region_order":1,"region_status":"active","row_number":2}] */
      prices?: {
        /**
         * @format uuid
         * @example "41fb4856-6279-4fa8-a551-ecb7a97518b9"
         */
        nomenclature_id?: string;
        /** @example 0 */
        nomenclature_order?: number;
        /** @example "active" */
        nomenclature_status?: string;
        /** @example "Строка 2" */
        nomenclature_title?: string;
        /** @example 200 */
        price?: number;
        /**
         * @format uuid
         * @example "e4d1d0f2-b41d-438d-a3bd-80cdded5f083"
         */
        region_id?: string;
        /** @example "Томск" */
        region_name?: string;
        /** @example 2 */
        region_order?: number;
        /** @example "active" */
        region_status?: string;
        /** @example 1 */
        row_number?: number;
      }[];
      /** @example 0 */
      work_types_order?: number;
      /** @example "active" */
      work_types_status?: string;
      /** @example "Вид работ 4" */
      work_types_title?: string;
    }[];
  }[];
  filters?: {
    /** @example [{"id":"03381f7d-c02a-4213-bf52-2c47b0337f77","name":"Новосибирск","order":1,"status":"active","title":"Новосибирск"},{"id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083","name":"Томск","order":2,"status":"active","title":"Томск"}] */
    regions?: {
      /**
       * @format uuid
       * @example "03381f7d-c02a-4213-bf52-2c47b0337f77"
       */
      id?: string;
      /** @example "Новосибирск" */
      name?: string;
      /** @example 1 */
      order?: number;
      /** @example "active" */
      status?: string;
      /** @example "Новосибирск" */
      title?: string;
    }[];
  };
}

export type Examples = any;

export interface Nomenclature {
  /**
   * Идентификатор номенклатуры
   * @format uuid
   */
  id?: string;
  /**
   * Наименование номенклатуры
   * @example "Россия"
   */
  title?: string;
  /**
   * Код номенклатуры
   * @example "ru"
   */
  name?: string;
  /**
   * Статус номенклатуры в справочнике
   * @example "active"
   */
  status?: string;
  /** Наименование статуса */
  status_label?: string;
  measure_unit?: {
    /**
     * Идентификатор ед. измерения из справочника
     * @format uuid
     */
    id?: string;
    /**
     * Наименование ед. измерения из справочника
     * @example "шт."
     */
    title?: string;
  };
  /** Дополнительные свойства */
  data?: object;
  /**
   * Порядковый номер раздела в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления раздела в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления раздела в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export interface DirectoryList {
  data?: Directory[];
  meta?: MetaData;
}

export interface WorkSectionList {
  data?: WorkSection[];
  meta?: MetaData;
}

export interface NomenclatureList {
  data?: Nomenclature[];
  meta?: MetaData;
}

export interface WorkSectionTable {
  headers?: string[];
  /** Список строк таблицы */
  body?: TableRow[];
}

/** Объект строки, для удобства это объект, но вообще это массив, каждый элемент соответствует своей колонке */
export interface TableRow {
  /**
   * Идентификатор раздела
   * @format uuid
   */
  section_id?: string;
  /**
   * Идентификатор группы
   * @format uuid
   */
  type_id?: string;
  /**
   * Идентификатор номенклатуры
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Тип строки
   * @example "nomenlcature"
   */
  row_type?: "section" | "work_type" | "nomenclature";
  /**
   * Уровень вложенности
   * @example 3
   */
  level?: number;
  /**
   * Тип номенклатуры (1 - работа, 2 - материал, 3 - работа и материал)
   * @example 2
   */
  nomenclature_type?: number;
  /**
   * Номер строки
   * @example "1.1.1"
   */
  row_number?: string;
  /**
   * Наименование раздела/группы/номенклатуры
   * @example "Щебень известняковый фр. 5*20 М600"
   */
  title?: string;
  /**
   * Ед. измерений номенклатуры
   * @example "м3"
   */
  unit?: string;
  /**
   * Идентификатор ед. измерений
   * @format uuid
   */
  unit_id?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://directory-api.develop.pnk.itmegastar.com/directory/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title PNK Directory Service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://directory-api.develop.pnk.itmegastar.com/directory/api
 * @externalDocs https://www.notion.so/megastar/v2-3388738db3264bd7b57b4cfe343e1690
 * @contact <d.kornilov@itmbegastar.com>
 *
 * Сервис справочников (Контрагентов) проекта PNK
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  helpers = {
    /**
     * @description Получить список локаций контрагентов для выпадающего списка
     *
     * @tags Helpers
     * @name ContractorLocationsList
     * @summary Список использованных локаций в контрагентах
     * @request GET:/helpers/contractor-locations
     * @secure
     */
    contractorLocationsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
        },
        Error
      >({
        path: `/helpers/contractor-locations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список тэгов контрагентов для выпадающего списка
     *
     * @tags Helpers
     * @name ContractorTagsList
     * @summary Список использованных тэгов в контрагентах
     * @request GET:/helpers/contractor-tags
     * @secure
     */
    contractorTagsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
        },
        Error
      >({
        path: `/helpers/contractor-tags`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список единиц измерений номенклатур для выпадающего списка
     *
     * @tags Helpers
     * @name UnitsList
     * @summary Список единиц измерений для номенклатур
     * @request GET:/helpers/units
     * @secure
     */
    unitsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /**
             * Идентификатор ед. измерения
             * @format uuid
             */
            value?: string;
            /**
             * Наименование
             * @example "шт."
             */
            text?: string;
            /** Флаг показывает, что значение находится в архиве */
            disabled?: boolean;
          }[];
        },
        Error
      >({
        path: `/helpers/units`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * @description Загрузка временного файла в S3 хранилище
     *
     * @tags Files
     * @name UploadCreate
     * @summary Загрузка временного файла
     * @request POST:/files/upload
     * @secure
     */
    uploadCreate: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedFile, Error>({
        path: `/files/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление временного файла из S3 хранилища
     *
     * @tags Файлы
     * @name FilesDelete
     * @summary Удаление временного файла
     * @request DELETE:/files/{id}
     * @secure
     */
    filesDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/files/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contractors = {
    /**
     * @description Получить постраничный список контрагентов из справочника
     *
     * @tags Contractors
     * @name ContractorsList
     * @summary Список контрагентов
     * @request GET:/contractors
     * @secure
     */
    contractorsList: (
      query?: {
        /** Поиск по наименованию или началу ИНН */
        search?: string;
        /**
         * Фильтр по тэгам, разделитель "|"
         * @example "Арматура;Бетон"
         */
        tags?: string;
        /** Фильтр по рейтингу (0 - Черный, 1 - Желтый, 2 - Зеленый) */
        rating?: "0" | "1" | "2";
        /**
         * Фильтр по типу контрагента
         * @format uuid
         */
        type_id?: string;
        /**
         * Поиск по списку локаций, разделитель "|"
         * @example "Москва;Самара"
         */
        location?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorList, Error>({
        path: `/contractors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать нового контрагента в справочнике
     *
     * @tags Contractors
     * @name ContractorsCreate
     * @summary Создать контрагента
     * @request POST:/contractors
     * @secure
     */
    contractorsCreate: (data: CreateContractorRequest, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о контрагенте по его идентификатору
     *
     * @tags Contractors
     * @name ContractorsDetail
     * @summary Просмотр контрагента
     * @request GET:/contractors/{id}
     * @secure
     */
    contractorsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные контрагента в справочнике
     *
     * @tags Contractors
     * @name ContractorsUpdate
     * @summary Обновление контрагента
     * @request PUT:/contractors/{id}
     * @secure
     */
    contractorsUpdate: (id: string, data: UpdateContractorRequest, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление контрагента из справочника
     *
     * @tags Contractors
     * @name ContractorsDelete
     * @summary Удалить контрагента
     * @request DELETE:/contractors/{id}
     * @secure
     */
    contractorsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/contractors/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  regions = {
    /**
     * @description Получить постраничный список регионов из справочника
     *
     * @tags Регионы
     * @name RegionsList
     * @summary Список регионов
     * @request GET:/regions
     * @secure
     */
    regionsList: (
      query?: {
        /** Поиск по наименованию или коду региона */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "created_at" | "updated_at";
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryList, Error>({
        path: `/regions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый регион в справочнике
     *
     * @tags Регионы
     * @name RegionsCreate
     * @summary Создать регион
     * @request POST:/regions
     * @secure
     */
    regionsCreate: (
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/regions`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Регионы
     * @name SortCreate
     * @summary Обновление порядковых номеров регионов в справочнике
     * @request POST:/regions/sort
     * @secure
     */
    sortCreate: (
      data: {
        ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryPlaneList, Error>({
        path: `/regions/sort`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о регионе по его идентификатору
     *
     * @tags Регионы
     * @name RegionsDetail
     * @summary Просмотр региона
     * @request GET:/regions/{id}
     * @secure
     */
    regionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Directory, Error>({
        path: `/regions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные региона в справочнике
     *
     * @tags Регионы
     * @name RegionsUpdate
     * @summary Обновление региона
     * @request PUT:/regions/{id}
     * @secure
     */
    regionsUpdate: (
      id: string,
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title?: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/regions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление региона из справочника
     *
     * @tags Регионы
     * @name RegionsDelete
     * @summary Удалить регион
     * @request DELETE:/regions/{id}
     * @secure
     */
    regionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/regions/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workSections = {
    /**
     * @description Получить постраничный список разделов работ из справочника
     *
     * @tags Номенклатуры
     * @name WorkSectionsList
     * @summary Список разделов
     * @request GET:/work-sections
     * @secure
     */
    workSectionsList: (
      query?: {
        /** Поиск по наименованию или коду раздела */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "created_at" | "updated_at";
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkSectionList, Error>({
        path: `/work-sections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый раздел работ в справочнике
     *
     * @tags Номенклатуры
     * @name WorkSectionsCreate
     * @summary Создать раздел
     * @request POST:/work-sections
     * @secure
     */
    workSectionsCreate: (
      data: {
        /**
         * Наименование раздела
         * @example "Регион"
         */
        title: string;
        /**
         * Код раздела
         * @example "region"
         */
        name?: string;
        /**
         * Статус раздела в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Сдвиг плановой даты заключения контрактов от даты старта работ
         * @min 0
         * @default 30
         */
        contract_date_shift?: number;
        /**
         * Порядок вывода разделов в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkSection, Error>({
        path: `/work-sections`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Номенклатуры
     * @name SortCreate
     * @summary Обновление порядковых номеров разделов работ в справочнике
     * @request POST:/work-sections/sort
     * @secure
     */
    sortCreate: (
      data: {
        ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkSectionPlaneList, Error>({
        path: `/work-sections/sort`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить таблицу разделов работ с вложенными группами и номенклатурами
     *
     * @tags Номенклатуры
     * @name TableList
     * @summary Таблица разделов
     * @request GET:/work-sections/table
     * @secure
     */
    tableList: (params: RequestParams = {}) =>
      this.request<WorkSectionTable, Error>({
        path: `/work-sections/table`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о разделе работ по его идентификатору
     *
     * @tags Номенклатуры
     * @name WorkSectionsDetail
     * @summary Просмотр раздела
     * @request GET:/work-sections/{id}
     * @secure
     */
    workSectionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WorkSection, Error>({
        path: `/work-sections/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные раздела работ в справочнике
     *
     * @tags Номенклатуры
     * @name WorkSectionsUpdate
     * @summary Обновление раздела
     * @request PUT:/work-sections/{id}
     * @secure
     */
    workSectionsUpdate: (
      id: string,
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title?: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Сдвиг плановой даты заключения контрактов от даты старта работ
         * @min 0
         * @default 30
         */
        contract_date_shift?: number;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkSection, Error>({
        path: `/work-sections/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление раздела работ из справочника
     *
     * @tags Номенклатуры
     * @name WorkSectionsDelete
     * @summary Удалить раздел
     * @request DELETE:/work-sections/{id}
     * @secure
     */
    workSectionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/work-sections/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workTypes = {
    /**
     * @description Получить постраничный список видов работ из справочника
     *
     * @tags Номенклатуры
     * @name WorkTypesList
     * @summary Список групп (видов работ)
     * @request GET:/work-types
     * @secure
     */
    workTypesList: (
      query?: {
        /** Поиск по наименованию или коду группы */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "created_at" | "updated_at";
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryList, Error>({
        path: `/work-types`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый вид работ в справочнике
     *
     * @tags Номенклатуры
     * @name WorkTypesCreate
     * @summary Создать группу (вид работ)
     * @request POST:/work-types
     * @secure
     */
    workTypesCreate: (
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
        sections?: {
          /**
           * Идентификатор раздела, в который вставляется группа
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор родительской группы, в которую добавляется группа
           * @format uuid
           */
          type_id?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/work-types`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Номенклатуры
     * @name SortCreate
     * @summary Обновление порядковых номеров видов работ в справочнике
     * @request POST:/work-types/sort
     * @secure
     */
    sortCreate: (
      data: {
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор родительской группы
         * @format uuid
         */
        type_id?: string;
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/work-types/sort`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о виде работ по его идентификатору
     *
     * @tags Номенклатуры
     * @name WorkTypesDetail
     * @summary Просмотр группы
     * @request GET:/work-types/{id}
     * @secure
     */
    workTypesDetail: (id: string, params: RequestParams = {}) =>
      this.request<Directory, Error>({
        path: `/work-types/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные вида работ в справочнике
     *
     * @tags Номенклатуры
     * @name WorkTypesUpdate
     * @summary Обновление группы (вида работ)
     * @request PUT:/work-types/{id}
     * @secure
     */
    workTypesUpdate: (
      id: string,
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title?: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/work-types/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление вида работ из справочника
     *
     * @tags Номенклатуры
     * @name WorkTypesDelete
     * @summary Удалить группу (вид работ)
     * @request DELETE:/work-types/{id}
     * @secure
     */
    workTypesDelete: (
      id: string,
      query?: {
        /**
         * Флаг, что нужно удалить только связь группы с разделом
         * @min 0
         * @max 1
         * @default 0
         */
        only_rel?: number;
        /**
         * Идентификатор раздела, работает только с only_rel параметром
         * @format uuid
         */
        section_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/work-types/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  nomenclatures = {
    /**
     * @description Получить постраничный список номенклатур из справочника
     *
     * @tags Номенклатуры
     * @name NomenclaturesList
     * @summary Список номенклатур
     * @request GET:/nomenclatures
     * @secure
     */
    nomenclaturesList: (
      query?: {
        /** Поиск по наименованию или коду номенклатуры */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "created_at" | "updated_at";
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<NomenclatureList, Error>({
        path: `/nomenclatures`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новую номенклатуру в справочнике
     *
     * @tags Номенклатуры
     * @name NomenclaturesCreate
     * @summary Создать номенклатуру
     * @request POST:/nomenclatures
     * @secure
     */
    nomenclaturesCreate: (
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title: string;
        /**
         * Наименование ед. измерения
         * @example "шт."
         */
        unit: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
        sections?: {
          /**
           * Идентификатор раздела, в который вставляется номенклатура
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор группы, в которую добавляется номенклатура
           * @format uuid
           */
          type_id?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Nomenclature, Error>({
        path: `/nomenclatures`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Номенклатуры
     * @name SortCreate
     * @summary Обновление порядковых номеров номенклатур в справочнике
     * @request POST:/nomenclatures/sort
     * @secure
     */
    sortCreate: (
      data: {
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id: string;
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/nomenclatures/sort`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о номенклатуре по ее идентификатору
     *
     * @tags Номенклатуры
     * @name NomenclaturesDetail
     * @summary Просмотр номенклатуры
     * @request GET:/nomenclatures/{id}
     * @secure
     */
    nomenclaturesDetail: (id: string, params: RequestParams = {}) =>
      this.request<Nomenclature, Error>({
        path: `/nomenclatures/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные номенклатуры в справочнике
     *
     * @tags Номенклатуры
     * @name NomenclaturesUpdate
     * @summary Обновление номенклатуры
     * @request PUT:/nomenclatures/{id}
     * @secure
     */
    nomenclaturesUpdate: (
      id: string,
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title?: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Наименование ед. измерения
         * @example "шт."
         */
        unit?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Nomenclature, Error>({
        path: `/nomenclatures/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление номенклатуры из справочника
     *
     * @tags Номенклатуры
     * @name NomenclaturesDelete
     * @summary Удалить номенклатуру
     * @request DELETE:/nomenclatures/{id}
     * @secure
     */
    nomenclaturesDelete: (
      id: string,
      query?: {
        /**
         * Флаг, что нужно удалить только связь номенклатуры с группой в разделе
         * @min 0
         * @max 1
         * @default 0
         */
        only_rel?: number;
        /**
         * Идентификатор раздела, работает только с only_rel параметром
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы, работает только с only_rel параметром
         * @format uuid
         */
        type_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/nomenclatures/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  nomenclaturePrices = {
    /**
     * @description Получение таблицы цен со всеми вложенностями
     *
     * @tags Цены номенклатур
     * @name NomenclaturePricesList
     * @summary Получение таблицы цен
     * @request GET:/nomenclature-prices
     * @secure
     */
    nomenclaturePricesList: (params: RequestParams = {}) =>
      this.request<Prices, Error>({
        path: `/nomenclature-prices`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Цены номенклатур
     * @name NomenclaturePricesCreate
     * @summary Обновление порядковых номеров номенклатур в справочнике
     * @request POST:/nomenclature-prices
     * @secure
     */
    nomenclaturePricesCreate: (
      data: {
        /** @example [{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","price":123.1,"region_id":"03381f7d-c02a-4213-bf52-2c47b0337f77"},{"nomenclature_id":"17e05299-4423-4de9-9aad-9ea9ee7f831f","price":100,"region_id":"e4d1d0f2-b41d-438d-a3bd-80cdded5f083"}] */
        prices?: {
          /**
           * @format uuid
           * @example "17e05299-4423-4de9-9aad-9ea9ee7f831f"
           */
          nomenclature_id?: string;
          /** @example 123.1 */
          price?: number;
          /**
           * @format uuid
           * @example "03381f7d-c02a-4213-bf52-2c47b0337f77"
           */
          region_id?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<CreatePriceResponse, Error>({
        path: `/nomenclature-prices`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
