import parseJwt from "../shared/parseJwt";
import {getValueStore} from "../shared/store";

export enum AccessItem {
  NONE = 'NONE',
  VIEW = 'VIEW',
  VIEW_ONLY = 'VIEW_ONLY',
  VIEW_ALL = 'VIEW_ALL',
  EDIT = 'EDIT',
  ADD = 'ADD',
  DELETE = 'DELETE',
  ALLOW = 'ALLOW',
  FULL = 'FULL',
}

export function getAccessValue(name = '') {
  const token = getValueStore('token') ?? '';
  const jwt = parseJwt(token);

  const val = jwt.permissions?.find((e: string) => e.indexOf(`${name}:`) == 0)?.split(':')?.[2] ?? 'N';

  switch (val) {
    case 'N':
      return AccessItem.NONE
    case 'Y':
      return AccessItem.ALLOW
    case 'R':
      return AccessItem.VIEW
    case 'R_OWN':
      return AccessItem.VIEW_ONLY
    case 'R_ALL':
      return AccessItem.VIEW_ALL
    case 'E':
      return AccessItem.EDIT
    case 'F':
      return AccessItem.FULL
  }

  return AccessItem.NONE
}

const tokenActions = {
  /** @type {AccessItem} Список проектов */
  listProjects: getAccessValue('EPO_PR_LIST'),
  /** @type {AccessItem} Список проектов */
  finContr: getAccessValue('FIN_CONTR'),
  /** @type {AccessItem} Добавить проект */
  createProject: getAccessValue('EPO_PR_ADD'),
  /** @type {AccessItem} Архивировать проект */
  archiveProject: getAccessValue('EPO_PR_ARCHIVE'),
  /** @type {AccessItem} Восстановить проект */
  restoreProject: getAccessValue('EPO_PR_ACTIVATE'),
  /** @type {AccessItem} Просмотр финансовой составляющей проектов в списке проектов */
  finViewProjects: getAccessValue('EPO_PR_LIST_MON'),
  /** @type {AccessItem} Бюджет план */
  budgetPlan: getAccessValue('EPO_PR_PB'),
  /** @type {AccessItem} Согласование бюджет плана */
  budgetPlanApprove: getAccessValue('EPO_PR_PB_APPROVE'),
  /** @type {AccessItem} Разрешено обновлять площадь проекта */
  allowUpdArea: getAccessValue('EPO_PR_UPD_AREA'),
  /** @type {AccessItem} Плановые даты */
  planeDates: (() => {
    if (getValueStore('planeDates') && getValueStore('planeDates') != AccessItem.NONE) {
      if (AccessItem.EDIT && getAccessValue('EPO_PR_DATES') == AccessItem.ALLOW) {
        return AccessItem.EDIT;
      }
      return AccessItem.VIEW;
    }

    return getAccessValue('EPO_PR_DATES') == AccessItem.ALLOW ? AccessItem.EDIT : AccessItem.VIEW;
  })(),
  /** @type {AccessItem} Контракты, основные поля */
  contracts: getAccessValue('EPO_PR_CD'),
  /** @type {AccessItem} Даты контрактов */
  contractsDates: getAccessValue('EPO_PR_DATES'),
  /** @type {AccessItem} История изменений */
  history: getAccessValue('EPO_PR_HISTORY'),
  /** @type {AccessItem} Подрядчики, справочник */
  contragents: getAccessValue('CON_DIR'),
  /** @type {AccessItem} Рекомендованный поставщик */
  recommendedContragents: getAccessValue('EPO_PR_CONTRACTORS'),
  /** @type {AccessItem} Ответственный */
  responsible: getAccessValue('EPO_PR_RESPONSIBLE'),
  /** @type {AccessItem} Бюджет факт */
  budgetFact: getAccessValue('EPO_PR_FB'),
  /** @type {AccessItem} Факт оплаты */
  faktPayment: getAccessValue('EPO_PR_FP'),
  /** @type {AccessItem} Отклонение от бюджет-плана */
  otklBp: getAccessValue('EPO_PR_DPB'),
  /** @type {AccessItem} Занесение счетов на оплату */
  invoices: getAccessValue('EPO_PR_IP'),
  /*     invoices: (() => {
          if (getValueStore('invoices') && getValueStore('invoices') != AccessItem.NONE) {
              return AccessItem.EDIT;
          }

          return getAccessValue('EPO_PR_IP') == AccessItem.EDIT ? AccessItem.EDIT : AccessItem.VIEW;
      })(), */
  /** @type {AccessItem} Выполнение на объекте */
  wellBill: (() => {
    if (getValueStore('wellBill')) {
      return getValueStore('wellBill');
    }

    return getAccessValue('EPO_PR_DC');
  })(),
  /** @type {AccessItem} Счет-фактуры */
  bill: getAccessValue('EPO_PR_B'),
  /** @type {AccessItem} Сводка по проектам */
  projectListDetails: getAccessValue('EPO_PR_LIST_DETAIL'),
  deletDocument: getAccessValue('EPO_PR_DD'),
  directoryRegions: getAccessValue('REG_DIR'),
  allowScreenShot: getAccessValue('EPO_SCREENSHOT'),
  directoryNomeclatures: getAccessValue('NOM_DIR'),


  // contragents: getAccessValue('EPO_CG'),

  // listProjects: AccessItem.EDIT,
  // createProject: AccessItem.ALLOW,
  // archiveProject: AccessItem.ALLOW,
  // restoreProject: AccessItem.ALLOW,
  // budgetOrientire: AccessItem.EDIT,
  // budgetPlan: AccessItem.EDIT,
  // budgetPlanApprove: AccessItem.ALLOW,
  // budgetFact: AccessItem.VIEW,
  // contracts: AccessItem.EDIT,
  // contractsDates: AccessItem.ALLOW,
  // history: AccessItem.ALLOW,
};

(window as any).tokenActions = tokenActions;
(window as any).AccessItem = AccessItem;

(window as any).tokenPayload = () => {
  const token = getValueStore('token') ?? '';
  return parseJwt(token);
};

export function getUserId(): string {
  const token = getValueStore('token') ?? '';
  const payload = parseJwt(token);
  return payload.sub ?? '';
}

export function getUserIsGrp(): boolean {
  const token = getValueStore('token') ?? '';
  const payload = parseJwt(token);
  return payload?.groups?.includes('chief_project_manager');
}

export default tokenActions
