import {CellDefine} from 'cheetah-grid/list-grid/layout-map/api';
import dateFormatter, {numberFormatter} from '../../../shared/dateFormat';
import {RecordStatus, TableRecord} from '../../canvas_table/components/TableRecord';
import {allowViewBudgetPlanCell} from '../allowEditCell';
import {getWData} from '../getWData';
import {themeTable} from '../table_theme';
import {tableBodyMain} from './table_body_main';
import {getBgCellBudgetSumWat} from '../getCellBg';
import {
  checkConfirmBudgetPlan,
  checkConfirmBudgetPlanHeader,
  checkViewBudgetPlanAction,
} from '../checkConfirmBudgetPlan';
import tokenActions, {AccessItem} from '../../../actions/tokenActions';
import moment from 'moment';
import {getBodyCellStyle, getBorderUnionRightStyle} from './body_cell_style';


export enum TableMode {
  EDIT = 'edit',
  VIEW = 'view',
  SCROLL = 'scroll',
}

// Бюджет план
const tableBodyBudgetPlan = (): CellDefine<TableRecord>[] => [
  {
    field: (record: TableRecord) => {
      return numberFormatter(record['cns_budget_plan_size'], {isNull: '-'});
    },
    style: (e: TableRecord) =>
      getBodyCellStyle(e, {field: 'cns_budget_plan_size'}),
  },
  {
    field: (record: TableRecord) => numberFormatter(record['cns_budget_plan_price'], {isNull: '-'}),
    style: (e: TableRecord) => getBodyCellStyle(e, {field: 'cns_budget_plan_price'}),
  },
  {
    field: (record: TableRecord) =>
      numberFormatter(record['cns_budget_plan_sum_wat'], {isNol: '-', isInt: true, isNull: '-'}),
    style: (record) => ({
      ...(getBodyCellStyle(record, {
        field: 'cns_budget_plan_sum_wat',
      }) as any),
      padding: getWData().rows3.find(e => e.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW) ? [0, 0, 0, 0] : [0, 8, 0, 0],
      borderColor: (allowViewBudgetPlanCell('cns_budget_plan_sum_wat', record)) && record.cns_row_type != '' ? [
        themeTable.borderColor,
        getBgCellBudgetSumWat(record),
        themeTable.borderColor,
        themeTable.borderColor,
      ] : [],
      textOverflow: 'ellipsis',
    }),
    width: getWData().rows3.find(e => e.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW) ? 130 : 169,
  },
  {
    field: () => '',
    width: getWData().rows3.find(e => e.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW) && (tokenActions.budgetPlanApprove == AccessItem.ALLOW || tokenActions.budgetPlan == AccessItem.EDIT) ? 40 : 8,
    style: (record) => ({
      ...(getBodyCellStyle(record, {
        field: 'cns_budget_plan_sum_wat',
      }) as any),
      padding: [-1].includes(record.cns_level)
        ? [0, 8, 0, -80]
        : [8, 8, 8, 4],
      borderColor: (allowViewBudgetPlanCell('cns_budget_plan_sum_wat', record)) && record.cns_row_type != '' ? [
        themeTable.borderColor,
        themeTable.borderColor,
        themeTable.borderColor,
        getBgCellBudgetSumWat(record),
      ] : [],
      textOverflow: 'ellipsis',
    }),
    icon: (record: TableRecord) => {
      if (checkConfirmBudgetPlanHeader(record) || checkConfirmBudgetPlan(record) || checkViewBudgetPlanAction(record)) {
        return {
          src: '/canvas_table/icon_money.svg',
          offsetTop: 0,
        };
      }

      return undefined;
    },
  },
  {
    field: (record: TableRecord) => {
      return numberFormatter(record['cns_budget_plan_far'], {isNol: '-', isInt: true});
    },
    style: (e: TableRecord) =>
      ({
        ...getBodyCellStyle(e, {field: 'cns_budget_plan_far'}) as any,
        textOverflow: 'ellipsis',
        padding: [0, 4, 0, 4],
      }),
  },
  {
    field: (record: TableRecord) => {
      if (record.cns_budget_plan_sum_wat == '0' || record.cns_budget_plan_sum_wat === null) {
        return '-';
      }

      if (record.cns_budget_plan_sum_wat != '0' && record['cns_budget_plan_far2'] == '0') {
        return '0.00%';
      }

      if (Number(record['cns_budget_plan_far2']) < 0.01 && record.cns_level != 0) {
        return '0.00%';
      }

      return numberFormatter(record['cns_budget_plan_far2'], {
        isInt: record.cns_row_type == 'project',
        isTwoDigit: true,
        suffix: '%',
      });
    },
    style: (e: TableRecord) =>
      getBodyCellStyle(e, {field: 'cns_budget_plan_far2'}),
  },
];

// Бюджет план размер
const headersBpSize = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => numberFormatter(record['cns_plane_size']),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_plane_size'}) as any,
      }),
    },
  ];
};

// График
const tableBodyPlaneDates = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => dateFormatter(record['cns_plane_date_start']),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_plane_date_start'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => dateFormatter(record['cns_plane_date_end']),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_plane_date_end'}) as any,
      }),
    },
  ];
};

// Контракт
const tableBodyContakt = (): CellDefine<TableRecord>[] => {
  // const hasApprovedRows3 = getWData().rows3.find(e => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);
  // const itemsBfSize = getWData().rows3.filter(e =>
  //     e.cns_contact_date
  //     && e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED
  //     && (Number(e.cns_budget_fakt_size ?? '0') < Number(e.cns_budget_plan_size ?? '0'))
  //     && (moment(e.cns_contact_date, DateFormat).add(-getWData().dedlainDays, 'days') <= (moment(moment().format(DateFormat), DateFormat))),
  // );

  return [
    {
      field: (record: TableRecord) => dateFormatter(record['cns_contact_date']),
      style: (record: TableRecord) => {
        // Законтрактован вовремя
        const lastDateContract = record.cns_contracts.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime())[0];
        const isCorrectContracts = record.cns_budget_plan_sum_wat <= record.cns_budget_fakt_sum_wat && moment(lastDateContract?.doc_date, 'YYYY-MM-DD').isSameOrBefore(moment(record.cns_contact_date, 'YYYY-MM-DD'), 'day');

        if (isCorrectContracts) {
          return ({
            ...getBodyCellStyle(record, {field: 'cns_contact_date'}) as any,
            bgColor: themeTable.bgColorSuccess,
          });
        }

        return ({
          ...getBodyCellStyle(record, {field: 'cns_contact_date'}) as any,
        });
      },
    },
    {
      field: (record: TableRecord) => dateFormatter(record['cns_contact_date_start'], {placeholder: ''}),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_contact_date_start'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => dateFormatter(record['cns_contact_date_end'], {placeholder: ''}),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_contact_date_end'}) as any,
        // bgColor: themeTable.borderColor,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_responsibles === null) {
          return '-';
        }
        if (!record.cns_responsibles || record.cns_responsibles.length == 0) {
          return '';
        }

        if (record.cns_responsibles.length == 1) {
          return record.cns_responsibles[0]?.full_name;
        }

        return `Всего: ${record.cns_responsibles.length}`;
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_responsibles'}) as any,
        textOverflow: 'ellipsis',
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_responsibles === null) {
          return '-';
        }
        if (record.cns_row_type == 'nomenclature') {
          if (record['cns_contractors']?.length == 1) {
            return record['cns_contractors'][0]['label'];
          }
          if (record['cns_contractors']?.length) {
            return `Всего: ${record['cns_contractors']?.length}`;
          }
        }

        if (record.cns_row_type == 'contract' && record['cns_contractors']?.[0]) {
          return ` ${record['cns_contractors'][0]['label']}`;
        }

        return '';
      },
      style: (e: TableRecord) => {
        return ({
          ...getBodyCellStyle(e, {field: 'cns_contractors'}) as any,
          textOverflow: 'ellipsis',
        });
      },
    },
    {
      width: 30,
      field: () => '',
      style: (e: TableRecord) => {
        const color = Number(e.cns_budget_fakt_size)/* && getBgCellContractsStatus(e)*/ ? themeTable.bgColor3 : null;
        return ({
          ...getBodyCellStyle(e, {field: 'cns_budget_fakt_size'}) as any,
          textOverflow: 'ellipsis',
          color,
          // bgColor: 'red',
          padding: [0, 0, 0, 0],
          borderColor: getBorderUnionRightStyle(e),
        });
      },
      icon: (record: TableRecord) => {
        if (
          record.cns_row_type == 'nomenclature' &&
          record.cns_status == RecordStatus.BUDGET_PLAN_APPROVED &&
          (tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts == AccessItem.FULL)
        ) {
          return {
            src: '/canvas_table/btn_add_contract.svg',
          };
        }
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_responsibles === null) {
          return '-';
        }
        if (record.cns_row_type != 'nomenclature' && record.cns_row_type != 'detail' && record.cns_row_type != 'contract') return '';
        return numberFormatter(record['cns_budget_fakt_size']);
      },
      style: (e: TableRecord) => {
        return ({
          ...getBodyCellStyle(e, {field: 'cns_budget_fakt_size'}) as any,
          textOverflow: 'ellipsis',
        });
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_responsibles === null) {
          return '-';
        }
        if (record.cns_row_type != 'nomenclature' && record.cns_row_type != 'detail' && record.cns_row_type != 'contract') return '';
        return numberFormatter(record['cns_budget_fakt_price']);
      },
      style: (e: TableRecord) => {
        return ({
          ...getBodyCellStyle(e, {field: 'cns_budget_fakt_price'}) as any,
          textOverflow: 'ellipsis',
        });
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'detail' && record.cns_budget_fakt_sum_wat === null) {
          return '-';
        }
        return numberFormatter(record['cns_budget_fakt_sum_wat'], {isNol: '-', isInt: true});
      },
      style: (e: TableRecord) => {
        return ({
          ...getBodyCellStyle(e, {field: 'cns_budget_fakt_sum_wat'}) as any,
          textOverflow: 'ellipsis',
        });
      },
    },
  ];
};

// Занесение счетов
const tableBodyInvoices = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => {
        if (record['cns_invoice_count'] === null) return '-';
        if (record.cns_row_type != 'nomenclature' && record.cns_row_type != 'detail') return '';
        return record['cns_invoice_count'];
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_count'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => {
        return dateFormatter(record['cns_invoice_date']);
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_date'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => '',
      width: 30,
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_size'}) as any,
        padding: [0, 0, 0, 0],
        borderColor: getBorderUnionRightStyle(e),
      }),
      icon: (record: TableRecord) => {
        if (record.cns_row_type == 'contract') {
          return {
            src: '/canvas_table/btn_add_contract.svg',
          };
        }
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';
        return numberFormatter(record['cns_invoice_size'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_size'}) as any,
        font: e.cns_row_type == 'contract' ? themeTable.fontMedium : themeTable.font,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';
        return numberFormatter(record['cns_invoice_price'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_price'}) as any,
        font: e.cns_row_type == 'contract' ? themeTable.fontMedium : themeTable.font,
      }),
    },
    {
      field: (record: TableRecord) => {
        return numberFormatter(record['cns_invoice_sum'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_sum'}) as any,
        font: e.cns_row_type == 'contract' ? themeTable.fontMedium : themeTable.font,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record['cns_invoice_status'] === null) return '-';
        switch (record['cns_invoice_status']) {
          case 'on_work':
            return 'В работе';
          case 'approved':
            return 'Согласовано';
          case 'rejected':
            return 'Отклонено';
          case 'approved_cpm':
            return 'Согласовано ГРП';
          case 'to_pay':
            return 'К оплате';
          case 'sent_cb':
            return 'Отправлено в КБ';
          case 'transferred_sgc':
            return 'Переведено СГП';
          case 'approved_sgc':
            return 'Проверено СГП';
          case 'paid':
            return 'Оплачено';
        }
        return record['cns_invoice_status'];
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_invoice_status'}) as any,
      }),
    },
  ];
};

// Отклонение от Бюджет Плана
const tableBodyOtklBp = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => {
        if (record.cns_otkl_sum === null) {
          return '-';
        }

        return numberFormatter((record.cns_otkl_sum), {isInt: false, isNol: '0', isNull: '-'});
      },
      style: (record: TableRecord) => {
        const delta = record.cns_otkl_sum;

        const style = getBodyCellStyle(record, {field: 'cns_otkl_sum'}) as any;
        let bg = style.bgColor;

        if (record.cns_row_type == 'nomenclature') {
          if (delta == 0) {

          } else if (delta > 0) {
            bg = themeTable.bgColorError;
          } else if (delta < 0) {
            bg = themeTable.bgColorSuccess;
          }
        }

        return ({
          ...style,
          bgColor: bg,
        });
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_otkl_percent === null) {
          return '-';
        }

        return numberFormatter(Math.abs(record.cns_otkl_percent), {
          isInt: false,
          isTwoDigit: true,
          isNol: '0.00%',
          suffix: '%',
          isNull: '-',
        });
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_otkl_percent'}) as any,
      }),
    },
  ];
};

// Выполнение на объекте
const tableBodyPercentSum = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => {
        if (record.cns_invoices.length == 0) {
          return '-';
        }

        return numberFormatter(record.cns_percent_payment, {
          isInt: false,
          isTwoDigit: true,
          suffix: '%',
          isNol: '0.00%',
          isNull: '-',
        });
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_percent_payment'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_invoices.length == 0) {
          return '-';
        }

        return numberFormatter(record.cns_payment_future, {
          isInt: false,
          isTwoDigit: true,
          isNol: '0.00%',
          suffix: '%',
          isNull: '-',
        });
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_payment_future'}) as any,
      }),
    },
  ];
};

// Поставлено и выполнено на объекте
const tableBodyCompletedObj = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';

        return numberFormatter(record['cns_well_bill_percent'], {isInt: false, isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_percent'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';

        return numberFormatter(record['cns_well_bill_size'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_size'}) as any,
        font: e.cns_row_type == 'detail' ? themeTable.font : themeTable.fontMedium,
      }),
    },
    {
      field: (record: TableRecord) => '',
      width: 30,
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_delivery_sum'}) as any,
        padding: [0, 0, 0, 0],
        borderColor: getBorderUnionRightStyle(e),
      }),
      icon: (record: TableRecord) => {
        if (record.cns_row_type == 'contract') {
          return {
            src: '/canvas_table/btn_add_contract.svg',
          };
        }
      },
    },
    {
      width: 120,
      field: (record: TableRecord) => {
        return numberFormatter(record['cns_well_bill_delivery_sum'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_delivery_sum'}) as any,
        font: e.cns_row_type == 'detail' ? themeTable.font : themeTable.fontMedium,
      }),
    },
    {
      field: (record: TableRecord) => {
        return dateFormatter(record['cns_well_bill_delivery_date'], {placeholder: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_delivery_date'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => record['cns_well_bill_doc_number'],
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_well_bill_doc_number'}) as any,
      }),
    },
  ];
};

// Cчет-фактуры
const tableBodyBill = (): CellDefine<TableRecord>[] => {
  return [
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';

        if (record.cns_row_type == 'detail' && !record.cns_bills.length) return '-';

        if (record['cns_bill_count'] <= 1) {
          return record['cns_bill_number'];
        }

        return `Всего: ${record['cns_bill_count']}`;
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_number'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';
        if (record.cns_row_type == 'detail' && !record.cns_bills.length) return '-';

        return record['cns_bill_date'] ? dateFormatter(record['cns_bill_date'], {placeholder: ''}) : '';
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_date'}) as any,
      }),
    },
    {
      field: (record: TableRecord) => '',
      width: 30,
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_size'}) as any,
        padding: [0, 0, 0, 0],
        borderColor: getBorderUnionRightStyle(e),
      }),
      icon: (record: TableRecord) => {
        if (record.cns_row_type == 'contract') {
          return {
            src: '/canvas_table/btn_add_contract.svg',
          };
        }
      },
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';
        return numberFormatter(record['cns_bill_size'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_size'}) as any,
        font: e.cns_row_type == 'detail' ? themeTable.font : themeTable.fontMedium,
      }),
    },
    {
      field: (record: TableRecord) => {
        if (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') return '';
        return numberFormatter(record['cns_bill_price'], {isNull: '-', isEmpty: ''});
      },
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_price'}) as any,
        font: e.cns_row_type == 'detail' ? themeTable.font : themeTable.fontMedium,
      }),
    },
    {
      field: (record: TableRecord) => numberFormatter(record['cns_bill_sum'], {isNull: '-', isEmpty: ''}),
      style: (e: TableRecord) => ({
        ...getBodyCellStyle(e, {field: 'cns_bill_sum'}) as any,
        font: e.cns_row_type == 'detail' ? themeTable.font : themeTable.fontMedium,
      }),
    },
  ];
};

export function getTableBody(mode: TableMode): CellDefine<TableRecord>[][] {
  // console.log('getTableBody')

  const last: CellDefine<TableRecord>[] = [];

  last.push({
    field: () => '',
    caption: () => 'EMPTY',
    style: {
      borderColor: themeTable.transparent,
    },
  });

  return [
    [
      ...tableBodyMain(mode),
      ...(tokenActions.budgetPlan != AccessItem.NONE ? tableBodyBudgetPlan() : []),
      ...(tokenActions.contractsDates != AccessItem.NONE && tokenActions.budgetPlan == AccessItem.NONE ? headersBpSize() : []),
      ...(tokenActions.planeDates != AccessItem.NONE ? tableBodyPlaneDates() : []),
      ...((tokenActions.contracts != AccessItem.NONE || tokenActions.contractsDates != AccessItem.NONE) ? tableBodyContakt() : []),
      ...(tokenActions.invoices != AccessItem.NONE ? tableBodyInvoices() : []),
      ...(tokenActions.otklBp != AccessItem.NONE ? tableBodyOtklBp() : []),
      ...(tokenActions.budgetFact != AccessItem.NONE ? tableBodyPercentSum() : []),
      ...(tokenActions.wellBill != AccessItem.NONE ? tableBodyCompletedObj() : []),
      ...(tokenActions.bill != AccessItem.NONE ? tableBodyBill() : []),
      ...last,
    ],
  ];
}
