import { CellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../canvas_table/components/TableRecord';
import { getTableColumnKey } from './getTableColumnKey';
import { getWData } from './getWData';
import { getPassportCache } from './loadData';
import { tableHeaders } from './table_header/table_headers';

export function allowSendApproveBudget(record: TableRecord) {
  if (tokenActions.budgetPlan != AccessItem.EDIT) {
    return false;
  }

  return allowMoveApproveBudget(record);
}

export function allowViewOfSendBudget(record: TableRecord) {
  if (
    (tokenActions.budgetPlanApprove != AccessItem.ALLOW && tokenActions.budgetPlan != AccessItem.EDIT)
  ) {
    return false;
  }

  return allowMoveApproveBudget(record);
}

// Для случая синхронизации, когда у пользователя нет прав самостоятельно отправлять на согласование
export function allowMoveApproveBudget(record: TableRecord): boolean {
  if (record.cns_level < 0) return false;
  if (record.cns_row_type == 'project') {
    return !!getWData().rows.find((e) => e.cns_allow_send_approve);
  }

  let res = !!Number(record.cns_budget_plan_sum_wat) && !!record.cns_title && !!record.cns_ed_izm;

  const items = getWData().rows3;

  if (record.cns_row_type == 'section') {
    return !!items.find(
      (nomeclatura) => nomeclatura.cns_section_id == record.cns_section_id && allowMoveApproveBudget(nomeclatura),
    );
  }

  if (record.cns_row_type == 'work_type') {
    return !!items.find(
      (nomeclatura) => nomeclatura.cns_group_id == record.cns_group_id && allowMoveApproveBudget(nomeclatura),
    );
  }

  if (res && record.cns_row_type == 'nomenclature') {
    return [RecordStatus.BUDGET_PLAN_REJECTED, RecordStatus.BUDGET_ORIENTED_APPROVED].includes(record.cns_status);
  }

  return false;
}

export function allowBtnTitle1(record: TableRecord) {
  return false;
}

export function allowViewBudgetPlanCell(field: string, record: TableRecord) {
  if (getWData().excludedColumns.includes(field ?? '')) {
    return false;
  }

  // EMPTY

  return true;
}

export function allowViewPlannedDatesCell(field: string) {
  if (
    ['cns_plane_date_start', 'cns_plane_date_end'].includes(field ?? '') &&
    !getWData().rows3.find((e) => e.cns_status === RecordStatus.BUDGET_PLAN_APPROVED)
  ) {
    return false;
  }

  // EMPTY

  return true;
}

export function allowEditNomeclatura(record: TableRecord) {
  if (getPassportCache().status != 'active') {
    return false;
  }

  return (
    record.cns_row_type == 'nomenclature' &&
    (record.cns_status === RecordStatus.BUDGET_ORIENTED_APPROVED ||
      record.cns_status === RecordStatus.BUDGET_PLAN_REJECTED)
  );
}

export function allowViewBudgetFactCell(field: string) {
  if (
    ![
      'cns_budget_fakt_size',
      'cns_budget_fakt_price',
      'cns_budget_fakt_sum_wat',
      'cns_budget_fakt_far',
      'cns_contact_date',
      'cns_contact_contragent',
    ].includes(field ?? '')
  )
    return true;

  return getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);
}

export default function allowEditCell(currentRow: TableRecord, columnKey: keyof TableRecord) {
  if (getPassportCache().status != 'active') {
    return false;
  }

  const isFillBudgetPlan = ['cns_title', 'cns_ed_izm', 'cns_budget_plan_size', 'cns_budget_plan_price'].includes(
    columnKey,
  );

  if (tokenActions.budgetPlan != AccessItem.EDIT && isFillBudgetPlan) {
    return false;
  }

  if (tokenActions.contractsDates != AccessItem.ALLOW && columnKey == 'cns_contact_date') {
    return false;
  }

  const isAllowEditNomeclatura = allowEditNomeclatura(currentRow);

  if ((isAllowEditNomeclatura && isFillBudgetPlan)) {
    return true;
  }

  if (currentRow.cns_row_type == 'nomenclature') {
    if (columnKey === 'cns_contact_date' && currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) {
      return true;
    }

        if (
            columnKey === "cns_responsibles" &&
            currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED &&
            tokenActions.responsible == AccessItem.EDIT
        ) {
            return true;
        }

        if (
            columnKey === "cns_contractors" &&
            currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED &&
            tokenActions.recommendedContragents == AccessItem.EDIT
        ) {
            return true;
        }
   }

  if (
    (columnKey == 'cns_plane_date_start' || columnKey == 'cns_plane_date_end' ) &&
    tokenActions.planeDates == AccessItem.EDIT &&
    (((currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED && currentRow.cns_level > 0) ||
      currentRow.cns_row_type == 'project')
    )
  ) {
    return true;
  }

  // EMPTY

  return false;
}

export function allowOpenEditor(currentRow: TableRecord, columnKey: keyof TableRecord) {
  // console.log('allowOpenEditor', columnKey);
  if (getPassportCache().status != 'active') {
    return false;
  }

  const isFillBudgetPlan = ['cns_title', 'cns_ed_izm', 'cns_budget_plan_size', 'cns_budget_plan_price'].includes(
    columnKey,
  );
  const isFillPlannedDates = ['cns_plane_date_start', 'cns_plane_date_end'].includes(columnKey);
  if (tokenActions.budgetPlan != AccessItem.EDIT && isFillBudgetPlan) {
    return false;
  }

  const isAllowEditNomeclatura = allowEditNomeclatura(currentRow);

  if (
    (isFillBudgetPlan && isAllowEditNomeclatura) ||
    (isFillPlannedDates &&
      tokenActions.planeDates == AccessItem.EDIT &&
      (currentRow.cns_id || currentRow.cns_parent_id == '-1') &&
      ((currentRow.cns_level > 0 && currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) ||
        (currentRow.cns_row_type == 'project' &&
          getWData().rows.find((row) => row.cns_status === RecordStatus.BUDGET_PLAN_APPROVED))))
  ) {
    return true;
  }

    if (currentRow.cns_row_type == 'nomenclature' ) {
      if (columnKey == 'cns_contact_date' && tokenActions.planeDates == AccessItem.EDIT && currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) {
        return true;
      }

      if (
        columnKey === "cns_responsibles" &&
        currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED &&
        tokenActions.responsible != AccessItem.NONE
      ) {
        return true;
      }

      if (
        columnKey === 'cns_contractors' &&
        currentRow.cns_status === RecordStatus.BUDGET_PLAN_APPROVED &&
        tokenActions.recommendedContragents != AccessItem.NONE
      ) {
        return true;
      }
    }

  // EMPTY

  return false;
}

export function allowHoverCell(currentRow: TableRecord, col: number) {
  if (![1, 2, 8].includes(col)) {
    return;
  }

  const level = currentRow.cns_row_type;
  if (level === 'section' || level === 'work_type') {
    return true;
  }
}

export function findNextEditCell(
  col: number,
  row: number,
  rows: TableRecord[],
  getTableBody: CellDefine<TableRecord>[][],
  direction: 'up' | 'down' | 'left' | 'right',
): { col: number; row: number } {
  console.log(`findNextEditCell`, direction);

  if (direction === 'right') {
    for (let j = col + 1; j < getTableBody[0].length; j++) {
      const columnKey = getTableColumnKey(j, getWData().body);
      if (allowOpenEditor(rows[row - tableHeaders.length], columnKey)) {
        return { col: j, row: row - tableHeaders.length };
      }
    }

    for (let i = row - tableHeaders.length + 1; i < rows.length; i++) {
      for (let j = 0; j < getTableBody[0].length; j++) {
        const columnKey = getTableColumnKey(j, getWData().body);
        if (allowOpenEditor(rows[i], columnKey)) {
          return { col: j, row: i };
        }
      }
    }
  }

  if (direction === 'left') {
    for (let j = col - 1; j > 0; j--) {
      const columnKey = getTableColumnKey(j, getWData().body);
      if (allowOpenEditor(rows[row - tableHeaders.length], columnKey)) {
        return { col: j, row: row - tableHeaders.length };
      }
    }
  }

  if (direction === 'down') {
    for (let i = row - tableHeaders.length + 1; i < rows.length; i++) {
      const columnKey = getTableColumnKey(col, getWData().body);
      if (allowOpenEditor(rows[i], columnKey)) {
        return { col: col, row: i };
      }
    }
  }

  if (direction === 'up') {
    for (let i = row - tableHeaders.length - 1; i > 0; i--) {
      const columnKey = getTableColumnKey(col, getWData().body);
      if (allowOpenEditor(rows[i], columnKey)) {
        return { col: col, row: i };
      }
    }
  }

  return { col: -1, row: -1 };
}
