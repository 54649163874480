import React, {FunctionComponent} from "react";
import { getErrorFieldPrefics, getTypeError, getTypeModalEnum, TypeModalEnum } from '../utils/getErrorFieldPrefics';
import dateFormat, {dateFormatter, numberFormatter} from "../../../../shared/dateFormat";
import {Button} from "antd";
import ModalErrorConfirm from "./modal_error_confirm";
import {ProjectError} from "../../../../ApiEpo";
import {getLoadDataInfo} from "../../../table_extends/loadData";
import {getEmptyTableRecordContragent, TableRecord, TableRecordContragent} from "../TableRecord";
import DownloadFile from '../../../../shared/DownloadFile';
import { DownloadOutlined } from '@ant-design/icons';

const ModalErrorLines: FunctionComponent<{error: ProjectError, contractId: string, contractLines: TableRecordContragent[], nomenclatures: TableRecord[], hasErrorSizeWithDates: boolean, typeError: TypeModalEnum}> = (props) => {

    const error = props.error ?? {};
    const loadData = getLoadDataInfo();

    const preficsErrorField = getErrorFieldPrefics(props.typeError);
    const docType = getTypeModalEnum(props.typeError);
    let typeLabel = 'Контракт'

    const contract = props.contractLines[0] ?? getEmptyTableRecordContragent();

    if (props.contractLines.length) {
        const lines: {
            id: string,
            title: string,
            size: string,
            price: string,
            sum: string,
            ed_izm: string,
            doc_id: string
            doc_name: string
        }[] = props.contractLines
            .map((e, index) => {
                if (props.typeError == 'contracts') {
                    typeLabel = 'Контракт'
                    return {
                        id: e.line!.cns_id,
                        title: e.line!.cns_title ?? '',
                        ed_izm: e.line!.cns_ed_izm,
                        size: e.size,
                        price: e.price,
                        sum: e.sum_wat,
                        doc_id: e.id,
                        doc_name: `Документы для контракта ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`
                    }
                }

                if (props.typeError == 'invoices') {
                    typeLabel = 'Счет'
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_invoice_size}`,
                        price: `${e.line!.cns_invoice_price}`,
                        sum: `${e.line!.cns_invoice_sum}`,
                        doc_id: e.id,
                        doc_name: `Документы для счета ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`
                    }
                }

                if (props.typeError == 'way_bills') {
                    typeLabel = 'ТТН'
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_well_bill_size}`,
                        price: `${e.line!.cns_well_bill_delivery_sum / Number(e.line!.cns_well_bill_size)}`,
                        sum: `${e.line!.cns_well_bill_delivery_sum}`,
                        doc_id: e.id,
                        doc_name: `Документы для ттн ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`
                    }
                }

                if (props.typeError == 'bills') {
                    typeLabel = 'Счет-фактура'
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_bill_size}`,
                        price: `${e.line!.cns_bill_price}`,
                        sum: `${e.line!.cns_bill_sum}`,
                        doc_id: e.id,
                        doc_name: `Документы для счет-фактуры ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`
                    }
                }

                return {id: '', title: '', size: '', price: '', sum: '', ed_izm: '', doc_name: '', doc_id: ''}
            }).filter(e => e.id && Number(e.size));

        const hasErrorSize = error.error?.fields?.includes(`${preficsErrorField}volume`);
        const hasErrorPrice = error.error?.fields?.includes(`${preficsErrorField}price`);
        const hasErrorSum = error.error?.fields?.includes(`${preficsErrorField}amount`);

        const countErrors = props.hasErrorSizeWithDates ? 2 : 1; //props.errors.filter(e => !e.closed_at).length;
        const countErrorsClosed = 0 // props.errors.filter(e => !!e.closed_at).length;

        const isClosed = !!error.closed_at;

        return <>
            <div key={`modal-error-view__error_title_${props.contractId}`}>
                {/*<span className={'modal-error-view__error_title'}>{contract.contragent}</span>*/}
                {/*{!!countErrors && <span*/}
                {/*    className={'modal-error-view__error_count'}>*/}
                {/*        {countErrors}*/}
                {/*    </span>}*/}
                {!!countErrorsClosed && <span
                    className={'modal-error-view__error_count closed'}>
                        {countErrorsClosed}
                    </span>}
            </div>
            <div className={'modal-error-view__error_contract'}
                 key={`modal-error-view__error_contract_${contract.id}`}
            >
                <div
                    className={'modal-error-view__error_contract_title'}>
                    <span>{typeLabel}, №{contract.doc_number} </span>
                    <span>от {dateFormatter(contract.doc_date ?? '')}</span>
                </div>
                {/*<span>{contract.size} {contract.line!.cns_ed_izm}</span>*/}
                {/*<span> × {numberFormatter(contract.price)}</span>*/}
                {/*<span>   = {numberFormatter(contract.sum_wat)} </span>*/}
            </div>
            <div key={`modal-error-view__error_contract_lines_${contract.id}`}>
                {lines.map(line => {
                    return <>
                        <div
                            className={`modal-error-view__error_contract_line  ${isClosed ? 'closed' : ''}`}
                            key={`modal-error-view__error_contract_line_${line.id}`}
                        >
                            <div
                                key={`modal-error-view__error_contract_line_title_${line.id}`}> {line.title} </div>
                            <span className={hasErrorSize ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_size_${line.id}`}
                            >{numberFormatter(line.size, {isTwoDigit: true})} {line.ed_izm}</span>
                            <span className={hasErrorPrice ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_price_${line.id}`}
                            >× {numberFormatter(line.price, {isTwoDigit: true})} ₽</span>
                            <span className={hasErrorSum ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_sum_${line.id}`}
                            >= {numberFormatter(line.sum, {isTwoDigit: true})} ₽</span>
                        </div>
                    </>;
                })}
                <DownloadFile
                    isBtn={true}
                    file={`${process.env.REACT_APP_API_URL}/api/passport/${docType}/${lines[0]?.doc_id}/files-archive`}
                    title={'Скачать документы'}
                    name={lines[0]?.doc_name}
                />
            </div>
        </>;
    }

    return <div key={`modal-error-view__error_${error?.id}`}></div>;

}

export default ModalErrorLines;
