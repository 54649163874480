import {CloseOutlined} from '@ant-design/icons';
import {AutoComplete, Button, Form, Input, message, notification, Select, Tag} from 'antd';
import moment from 'moment/moment';
import {FunctionComponent, useEffect, useState} from 'react';
import tokenActions, {AccessItem} from '../../../../actions/tokenActions';
import {useContractors} from '../../../../actions/useContractors';
import {api, documentsApi} from '../../../../shared/api_client';
import DownloadFile from '../../../../shared/DownloadFile';
import {sizes} from '../../../../shared/sizes';
import {MyDatePicker} from '../../../../shared/stopEvent';
import {updateBudgetFact} from '../../../table_extends/calcLocalValueRecord';
import {getResultRows} from '../../../table_extends/getResultRows';
import {getWData} from '../../../table_extends/getWData';
import {syncData} from '../../../table_extends/syncData';
import {getTableBody, TableMode} from '../../../table_extends/table_body/table_body';
import {getTableHeaders} from '../../../table_extends/table_header/table_headers';
import {TableRecord, TableRecordContragent, TableRecordContragentForm} from '../TableRecord';
import {DateFormat} from "../../../../shared/dateFormat";
import enUS from 'antd/es/calendar/locale/en_US';

const ModalContragent: FunctionComponent<{
    keyModal: any;
    record: TableRecord;
    updateContragent: (items: TableRecordContragent[]) => void;
    close: () => void;
}> = (props) => {
    const canvasItem = document.querySelector('canvas')?.getBoundingClientRect();
    const [form] = Form.useForm<TableRecordContragentForm>();
    // const contragentValue = Form.useWatch("contragent", form);
    const [search, setSearch] = useState('');
    const contractors = useContractors(search);
    const [index, setIndex] = useState(0);
    const [contragentValues, setContragentValues] = useState<TableRecordContragentForm[]>([]);
    const [loading, setLoading] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState(false);

    function getEmptyContract(contragentCurLines: TableRecordContragentForm[]): TableRecordContragentForm {
        const price = Number(props.record.cns_budget_plan_price ?? '0');
        const size = Math.max(
            Number(props.record.cns_budget_plan_size ?? '0') -
            contragentCurLines.reduce((acc, item) => acc + Number(item.size ?? '0'), 0),
            0,
        );
        return {
            id: Math.random().toString(),
            project_contract_data_id: null,
            document_id: '',
            contragent: '',
            contragent_id: '',
            documents: [],
            parent_id: props.record.cns_parent_id,
            parent_contract_id: null,
            doc_number: `${props.record.cns_contracts.length + 1}`,
            doc_date: props.record.cns_contact_date
                ? moment(props.record.cns_contact_date, DateFormat)
                : moment(),
            date_start: props.record.cns_contact_date_start ? moment(props.record.cns_contact_date_start, DateFormat) : moment(),
            date_end: props.record.cns_contact_date_end ? moment(props.record.cns_contact_date_end, DateFormat) : moment(),
            price: `${price}`,
            size: `${size}`,
            sum_wat: `${price * size}`,
            files: 0,
        };
    }

    let y =
        getWData().cellOpen?.cell?.top + getWData().cellOpen?.cell?.height - window.scrollY - getWData().grid.scrollTop;

    const x = getWData().cellOpen?.cell?.left - getWData().grid?.scrollLeft;

    const hasSpaceBottom =
        document.querySelector('.grid-scrollable')!.getBoundingClientRect().bottom -
        (getWData().cellOpen!.cell.bottom - document.querySelector('.grid-scrollable')!.scrollTop) >
        550;

    y = y + (canvasItem?.top ?? 0) + (hasSpaceBottom ? 0 : -500);

    if (y < 0) {
        y = 0;
    }

    useEffect(() => {
        const vals = props.record.cns_contracts
            .filter((e) => e.id?.includes('-'))
            .map((e) => ({
                ...e,
                doc_date: moment(e.doc_date ?? '', DateFormat),
                date_start: moment(e.date_start ?? '', DateFormat),
                date_end: moment(e.date_end ?? '', DateFormat),
            }));
        const vals2 = [...vals, getEmptyContract(vals)];
        setContragentValues(vals2);
        setIndex(0);
        form.setFieldsValue(vals2[0]);
        setLoading(false);
    }, [props.record.cns_id]);

    const handleAdd = async () => {
        try {
            await form.validateFields();
            console.log('Success:', form.getFieldsValue());

            if (!contragentValues[index]?.documents?.length) {
                message.error('Не загружен файл');
                return;
            }

            setLoading(true);

            await syncData.addContract(props.record, {
                contractor_id: form.getFieldValue('contragent_id'),
                contract_date: form.getFieldValue('doc_date').format('DD.MM.YYYY'),
                contract_number: form.getFieldValue('contract_number'),
                volume: Number(form.getFieldValue('size') ?? '0'),
                price: Number(form.getFieldValue('price') ?? '0'),
                file_ids: contragentValues[index]?.documents?.map((e) => e.id) ?? [],
                amount: Number(form.getFieldValue('sum_wat') ?? '0'),
            });
            console.log('end promise add');

            setIndex(contragentValues.length - 1);
            const vals2 = props.record.cns_contracts
                .filter((e) => e.id?.includes('-'))
                .map((e) => ({
                    ...e,
                    doc_date: moment(e.doc_date ?? '', DateFormat),
                    date_start: moment(e.date_start ?? '', DateFormat),
                    date_end: moment(e.date_end ?? '', DateFormat),
                }));
            setContragentValues([...vals2, getEmptyContract(vals2)]);

            getWData().grid.layout = {
                header: getTableHeaders(),
                body: getTableBody(TableMode.VIEW),
            };
        } catch (e) {
        }
        console.log('final promise add');

        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    function updateValues(_: keyof TableRecordContragentForm, isUpdSumWat = false) {
        const values = {
            ...form.getFieldsValue(),
            documents: contragentValues[index].documents,
            files: contragentValues[index].documents?.length,
        };
        let sum_wat = `${Number(values.size || '0') * Number(values.price || '0')}`;

        if (sum_wat == 'NaN' || !isUpdSumWat) {
            sum_wat = values.sum_wat;
        }

        const vales = [
            ...contragentValues.slice(0, index),
            {
                ...values,
                sum_wat: sum_wat,
            },
            ...contragentValues.slice(index + 1),
        ];
        setContragentValues(vales);
        props.updateContragent(vales.map((e) => ({ ...e, doc_date: e.doc_date?.format(DateFormat), date_start: e.date_start?.format(DateFormat), date_end: e.date_end?.format(DateFormat) })));
        if (isUpdSumWat) {
            form.resetFields(['sum_wat']);
            form.setFieldValue('sum_wat', sum_wat);
        }
    }

    async function uploadFile(event: any) {
        try {
            setUploadingFiles(true);
            const docs = [...(contragentValues[index].documents ?? [])];
            for (let file of event.target.files) {
                console.log(file);
                const res = await documentsApi.files.uploadCreate({file: file});
                console.log(res);
                if ((res.data as any).error_epo_be) {
                    notification.error({message: 'Не удалось загрузить файл'});
                } else {
                    docs.push(res.data);
                    setContragentValues([
                        ...contragentValues.slice(0, index),
                        {
                            ...contragentValues[index],
                            documents: docs,
                            files: docs.length,
                        },
                        ...contragentValues.slice(index + 1),
                    ]);

                    form.resetFields();
                    form.setFieldsValue({...contragentValues[index], documents: docs, files: docs.length});

                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
            }
        } catch (e) {
        } finally {
            setUploadingFiles(false);
        }
    }

    const isNewContract = !contragentValues[index]?.id?.includes('-');

    async function handleRemoveContract() {
        try {
            setLoading(true);
            await syncData.deleteContract(props.record, contragentValues[index].id);
            setIndex(contragentValues.length - 1);
            form.setFieldsValue(contragentValues[contragentValues.length - 1]);
        } catch (e) {
        } finally {
            setLoading(false);

            const vals2 = props.record.cns_contracts
                .filter((e) => e.id?.includes('-'))
                .map((e) => ({
                    ...e,
                    doc_date: moment(e.doc_date ?? '', DateFormat),
                    date_start: moment(e.date_start ?? '', DateFormat),
                    date_end: moment(e.date_end ?? '', DateFormat),
                }));
            setContragentValues([...vals2, getEmptyContract(vals2)]);
            setIndex(0);

            getWData().grid.layout = {
                header: getTableHeaders(),
                body: getTableBody(TableMode.VIEW),
            };
        }
    }

    function removeFile(indexDoc: number) {
        const docs = contragentValues[index].documents.filter((_, i) => i !== indexDoc);

        setContragentValues([
            ...contragentValues.slice(0, index),
            {
                ...contragentValues[index],
                documents: docs,
            },
            ...contragentValues.slice(index + 1),
        ]);
        form.resetFields();
        form.setFieldsValue({...contragentValues[index], documents: docs, files: docs.length});
    }

    function validateNumber(field: string) {
        if (Number(form.getFieldValue(field as any)) > 0) {
            return Promise.resolve();
        }
        return Promise.reject('Поле обязательно для заполнения');
    }

    (window as any).modalContragent = {
        index,
        contragentValues,
        form,
        x,
        y,
        record: props.record,
        contractors,
    };

    return (
        <div
            key={props.keyModal}
            className="ModalContragent"
            style={
                window.innerWidth > sizes.mobile
                    ? {
                        top: `${y}px`,
                        left: `${x + (canvasItem?.left ?? 0) - 500}px`,
                    }
                    : {
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }
            }
        >
            <div className={'ModalContranet__title'}>
                {props.record.cns_title} <CloseOutlined onClick={props.close}/>
            </div>
            {contragentValues.filter((e) => e.contragent_id).length > 0 && (
                <div>
                    {contragentValues.map((e, index2) => (
                        <Tag
                            key={`tag_${e.contragent_id + index2}`}
                            color={index2 === index ? 'blue' : undefined}
                            onClick={() => {
                                setIndex(index2);
                                form.resetFields();
                                form.setFieldsValue({...e, files: e.documents.length} as any);
                            }}
                        >
                            {e.id?.includes('-') ? e.contragent : 'Новый договор'}
                        </Tag>
                    ))}
                </div>
            )}
            {/*{contragentValues[index].id} <br/>*/}
            {/*{JSON.stringify(contragentValues[index])}*/}
            <Form<TableRecordContragentForm>
                form={form}
                name="basic"
                labelCol={{span: 10}}
                wrapperCol={{span: 16}}
                style={window.innerWidth > sizes.mobile ? {maxWidth: sizes.mobile} : {}}
                initialValues={contragentValues[index]}
                onFinish={handleAdd}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                disabled={tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL || !isNewContract}
            >
                <Form.Item<TableRecordContragentForm>
                    label="Подрядчик"
                    name="contragent_id"
                    rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
                >
                    <Select
                        showSearch
                        options={contractors?.contracts?.data?.map((e) => ({
                            value: e.value,
                            label: e.text,
                        }))}
                        style={window.innerWidth > sizes.mobile ? {maxWidth: 219} : {}}
                        onSelect={() => updateValues('contragent_id', false)}
                        onSearch={(text) => setSearch(text)}
                        placeholder=""
                        searchValue={search}
                        filterOption={_ => true}
                    />
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Номер договора"
                    name="doc_number"
                    rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
                >
                    <Input onChange={() => updateValues('doc_number', false)} style={{width: 130}}/>
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Дата заключения договора"
                    name="doc_date"
                    rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
                >
                    <MyDatePicker
                    locale={enUS}
                        inputReadOnly={window.innerWidth < sizes.tablet}
                        placeholder={''}
                        style={window.innerWidth > sizes.mobile ? {width: 130} : {}}
                        allowClear={false}
                        format={DateFormat}
                        value={contragentValues[index]?.doc_date ?? moment()}
                        onChange={(_) => {
                            updateValues('doc_date', false);
                        }}
                    />
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Цена за единицу с НДС, руб."
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                            validator: () => validateNumber('price'),
                        },
                    ]}
                >
                    <Input onChange={() => updateValues('price', true)} style={{width: 130}}/>
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Объем, шт."
                    name="size"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                            validator: () => validateNumber('size'),
                        },
                    ]}
                >
                    <Input onChange={() => updateValues('size', true)} style={{width: 130}}/>
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Сумма с НДС, руб."
                    name="sum_wat"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                            validator: () => validateNumber('sum_wat'),
                        },
                    ]}
                >
                    <Input onChange={() => updateValues('sum_wat', false)} style={{width: 130}}/>
                </Form.Item>
                <Form.Item<TableRecordContragentForm>
                    label="Договор"
                    className={'documents_wrap'}
                    name={'files'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                            validator: () => validateNumber('files'),
                        },
                    ]}
                >
                    <span className={'upload_file'}>
                        <Input
                            style={
                                window.innerWidth > sizes.mobile
                                    ? {
                                        maxWidth: 130,
                                        width: '100%',
                                        display: 'none',
                                    }
                                    : {display: 'none'}
                            }
                        />
                        {isNewContract && (
                            <div
                                className={`wrap_file ${tokenActions.contracts != AccessItem.EDIT  && tokenActions.contracts != AccessItem.FULL ? 'disabled' : ''}`}
                                style={{maxWidth: 219, width: '100%'}}
                            >
                                <span className={'wrap_file__title'}>Выбрать файл</span>
                                <Input
                                    type="file"
                                    style={{maxWidth: 219, width: '100%'}}
                                    className={'file'}
                                    onChange={uploadFile}
                                    multiple
                                />
                            </div>
                        )}

                        {
                            <div
                                className={''}
                                style={window.innerWidth > sizes.mobile ? {maxWidth: 219, width: '100%'} : {}}
                            >
                                {contragentValues[index]?.documents
                                    ?.filter((e) => e)
                                    ?.map((doc, index) => (
                                        <div key={`doc_${index}`} className={`doc_line ${isNewContract ? 'new' : ''}`}>
                                            {isNewContract && <div title={doc.name}>{doc.name}</div>}
                                            {!isNewContract && (
                                                <DownloadFile name={doc.name} file={doc.url} key={`doc ${index}`}/>
                                            )}

                                            {isNewContract && <CloseOutlined onClick={() => removeFile(index)}/>}
                                        </div>
                                    ))}
                                {uploadingFiles && <>Загрузка файлов</>}
                            </div>
                        }
                    </span>
                </Form.Item>
            </Form>
            <div>
                {isNewContract && (
                    <Button
                        type="primary"
                        onClick={handleAdd}
                        disabled={tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL}
                        loading={loading}
                        data-test="add_contract"
                    >
                        Сохранить
                    </Button>
                )}
                {!isNewContract && (
                    <Button
                        danger
                        type={'primary'}
                        disabled={tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL}
                        onClick={handleRemoveContract}
                        loading={loading}
                        data-test="remove_contract"
                    >
                        Удалить
                    </Button>
                )}
            </div>
        </div>
    );
};

export const ModalContragentBind: FunctionComponent<any> = () => {
    return (
        <ModalContragent
            keyModal={`modal_contragent${getWData().refresh}`}
            record={getWData().cellOpen!.record}
            close={() => closeModalContragent()}
            updateContragent={(contragentData) => {
                const index = getWData().rows3.findIndex((e) => e.cns_id === getWData().cellOpen?.record.cns_id);
                if (index > -1) {
                    getWData().rows3[index].cns_contracts = contragentData;
                }
                updateBudgetFact(getWData().rows3[index]);
                getWData().resultRows = getResultRows();
                getWData().grid.records = getWData().resultRows;
                getWData().grid.invalidate();
            }}
        />
    );
};

export function closeModalContragent() {
    if (!getWData().cellOpen?.isModalContragent) return;
    console.log('closeModalContragent');

    if (getWData().cellOpen?.isModalContragent) {
        getWData().cellOpen!.isModalContragent = false;
    }
    getWData().setRefresh(Math.random());
}
