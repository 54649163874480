export interface NomenclatureListModalState {
  title: string,
  unit: string,
  place: {
    value: string[],
    section_id: string[],
    work_type_id: string[],
    nomenclature_id: string,
  }
}

export function getEmptyNomenclatureListModalState(): NomenclatureListModalState {
  return {
    title: '',
    unit: '',
    place: {
      value: [],
      section_id: [],
      work_type_id: [],
      nomenclature_id: '',
    },
  };
}

export interface TreeNode {
  title: string,
  value: string,
  disabled: boolean,
  children: {
    title: string,
    value: string,
    disabled: boolean,
  }[],
}

export function getEntityType(section_id?: string, type_id?: string, nomenclature_id?: string) {
  if (nomenclature_id) {
    return 'nomenclature';
  } else if (type_id) {
    return 'work_type';
  } else if (section_id) {
    return 'section';
  }

  return 'nomenclature';
}

export function getEntityTitle(type: 'section' | 'work_type' | 'nomenclature') {
  switch (type) {
    case 'section':
      return 'Раздел';
    case 'work_type':
      return 'Вид работ';
    case 'nomenclature':
      return 'Номенклатура';
  }
}

export interface LocalValueNomenclature {
  unit: string,
  region_id: string,
  price: string
}
