import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {AutoComplete, Form, Input, Modal, TreeSelect} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import {uniq} from 'lodash';
import {useNomenclaturesTable} from '../../actions/useNomenclaturesTable';
import {getEmptyNomenclatureListModalState, NomenclatureListModalState, TreeNode} from './NomenclatureListHelpers';
import {delay} from '../../shared/delay';

const NomenclaturesListModal: FunctionComponent<{
    isOpen: boolean | string,
    type: 'nomenclature' | 'work_type' | 'section',
    onClose: () => void,
    onDone: (type: 'section' | 'work_type' | 'nomenclature', state: NomenclatureListModalState, section_id?: string, work_type_id?: string, nomenclature_id?: string) => void,
    select_section_id?: string,
    select_work_type_id?: string,
    select_nomenclature_id?: string,
}> = (props) => {
    const {nomenclatures, nomenclaturesLoading, nomenclaturesError} = useNomenclaturesTable();
    const [state, setState] = useState<NomenclatureListModalState>(getEmptyNomenclatureListModalState());

    const selectItem = useMemo(() => {
        const item = nomenclatures?.rows?.find(row => {
            if (!row.title) return false;

            if (props.isOpen === true) {
                switch (props.type) {
                    case 'section':
                        return row.section_id == props.select_section_id;
                    case 'work_type':
                        return row.section_id == props.select_section_id;
                    case 'nomenclature':
                        return row.type_id == props.select_work_type_id;
                }
            } else {
                switch (props.type) {
                    case 'section':
                        return row.section_id == props.select_section_id;
                    case 'work_type':
                        return row.type_id == props.select_work_type_id;
                    case 'nomenclature':
                        return row.nomenclature_id == props.select_nomenclature_id;
                }
            }
        });

        return item ?? {title: '', unit: '', section_id: '', type_id: '', nomenclature_id: ''};
    }, [props.isOpen, nomenclatures]);

    useEffect(() => {
        if (props.isOpen === false || props.isOpen === undefined) {
            setState(getEmptyNomenclatureListModalState());
        } else {
            const item = selectItem;
            const title = props.isOpen === true ? '' : item?.title ?? '';
            const unit = props.isOpen === true ? '' : item?.unit ?? '';
            let place: { value: string[], section_id: string[], work_type_id: string[], nomenclature_id: string } = {
                value: [item.nomenclature_id ?? item.type_id ?? item.section_id ?? ''],
                section_id: [item.section_id ?? ''],
                work_type_id: [item.type_id ?? ''],
                nomenclature_id: item.nomenclature_id ?? '',
            };

            if (props.type == 'work_type' && typeof props.isOpen === 'string') {
                place.value = [props.select_section_id ?? ''];
            }

            if (props.type == 'nomenclature' && typeof props.isOpen === 'string') {
                place.value = [props.select_work_type_id ?? ''];
            }

            const state: NomenclatureListModalState = {
                title: title,
                unit: unit,
                place: place,
            };

            setState(state);
            delay(100).then(_ => setState(state));

        }
    }, [props.isOpen, nomenclatures]);

    const title = useMemo(() => {
            if (props.isOpen === true)
                switch (props.type) {
                    case 'nomenclature':
                        return 'Добавить номенклатуру';
                    case 'work_type':
                        return 'Добавить вид работ';
                    case 'section':
                        return 'Добавить раздел';
                    default:
                        return 'Добавить номенклатуру';
                } else {
                switch (props.type) {
                    case 'nomenclature':
                        return 'Изменить номенклатуру';
                    case 'work_type':
                        return 'Изменить вид работ';
                    case 'section':
                        return 'Изменить раздел';
                    default:
                        return 'Изменить номенклатуру';
                }
            }
        }
        ,
        [props.type],
    );

    const placeholderItem = useMemo(() => {
        switch (props.type) {
            case 'nomenclature':
                return 'номенклатуры';
            case 'work_type':
                return 'вид работ';
            case 'section':
                return 'раздела';
            default:
                return 'номенклатуры';
        }
    }, [props.type]);

    const unitVariants = uniq([state.unit, nomenclatures?.rows?.filter(e => e.unit).map(e => e.unit ?? '')].flat()).filter(e => e);

    const treeData = useMemo(() => {
        const sections = nomenclatures?.rows.filter(row => row.row_type == 'section') ?? [];
        const workTypes = nomenclatures?.rows.filter(row => row.row_type == 'work_type') ?? [];

        const res: TreeNode[] = [];

        for (const section of sections) {
            res.push({
                title: section.title ?? '',
                value: section.section_id ?? '',
                disabled: props.type == 'section' || props.type == 'nomenclature',
                children: (props.type == 'nomenclature') ? workTypes
                    .filter(row => row.section_id == section.section_id)
                    .map(row => ({
                        title: row.title ?? '',
                        value: row.type_id ?? '',
                        disabled: false,
                    })) : [],
            });
        }

        return res;
    }, [nomenclatures, props]);

    useEffect(() => {
        setState({
            ...state,
            place: {
                value: [props.select_nomenclature_id ?? props.select_work_type_id ?? props.select_section_id ?? ''],
                section_id: [props.select_section_id ?? ''],
                work_type_id: [props.select_work_type_id ?? ''],
                nomenclature_id: props.select_nomenclature_id ?? '',
            },
        });
    }, [props.isOpen]);

    (window as any).modalNomencalature = {
        props,
        state,
        title,
        unitVariants,
        placeholderItem,
        selectItem,
    };

    const idCanSave = useMemo(() => {
        if (!state.title) return false;
        if (props.type == 'nomenclature' && !state.unit) return false;
        if (props.type == 'work_type' && !state.place) return false;
        if (props.type == 'nomenclature' && !state.place) return false;


        return true;
    }, [props, state]);

    return (
        <Modal
            open={!!props.isOpen} onClose={props.onClose} onCancel={props.onClose}
            onOk={() => props.onDone(props.type, state)}
            okButtonProps={{ disabled: !idCanSave }}
            title={title}
        >
            <br/>
            {(props.isOpen === true || state.title != '') && <>
                <Form labelCol={{span: 8}} key={`${props.type}-${props.isOpen}`}>
                    <FormItem label={'Наименование'}>
                        <Input placeholder={`Введите наименование ${placeholderItem}`} autoFocus
                               onInput={(e: any) => setState({...state, title: e.target.value})}
                               value={state.title}/>
                    </FormItem>
                    {props.type == 'nomenclature' && <FormItem label={'Единица измерения'}>
                        <AutoComplete
                            placeholder={'Выберите или введите единицу измерения'}
                            options={unitVariants.map(item => ({ value: item }))}
                            filterOption={(inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            onSearch={value => {
                                setState({...state, unit: value});
                            }}
                            onSelect={value => {
                                setState({...state, unit: value});
                            }}
                            value={state.unit}
                        />
                    </FormItem>}
                    {(props.type == 'work_type' || props.type == 'nomenclature') && <FormItem label={'Расположение'}>
                        <TreeSelect
                            multiple={props.type == 'nomenclature'}
                            key={`${props.type}-${props.isOpen}`}
                            value={props.type == 'nomenclature' ? state.place.value.length ? state.place.value : [] : state.place.value?.[0]}
                            placeholder={props.type == 'work_type' ? 'Выберите раздел для добавления' : 'Выберите вид работы для добавления'}
                            allowClear
                            treeDefaultExpandAll
                            treeData={treeData}
                            onChange={(value, node) => {
                                let flag = false;
                                let res: {
                                    value: string[],
                                    section_id: string[],
                                    work_type_id: string[],
                                    nomenclature_id: string
                                };

                                for (let i = 0; i < treeData.length; i++) {
                                    if (typeof value == 'object') {
                                        if (value.includes(treeData[i].value)) {
                                            res = {
                                                value: value,
                                                section_id: value,
                                                work_type_id: [''],
                                                nomenclature_id: '',
                                            };
                                            flag = true;
                                        }
                                    } else {
                                        if (value == treeData[i].value) {
                                            res = {
                                                value: [value],
                                                section_id: [''],
                                                work_type_id: [value],
                                                nomenclature_id: '',
                                            };
                                            flag = true;
                                        }
                                    }
                                }

                                if (!flag) {
                                    for (let i = 0; i < treeData.length; i++) {
                                        const section = treeData[i];
                                        for (const workTypeKey in section.children) {
                                            const workType = section.children[workTypeKey];

                                            if (typeof value == 'object') {
                                                if (value.includes(workType.value)) {
                                                    res = {
                                                        value,
                                                        section_id: [section.value],
                                                        work_type_id: value,
                                                        nomenclature_id: '',
                                                    };
                                                    flag = true;
                                                }
                                            } else {
                                                if (value == workType.value) {
                                                    res = {
                                                        value: [value],
                                                        section_id: [section.value],
                                                        work_type_id: [value],
                                                        nomenclature_id: '',
                                                    };
                                                    flag = true;
                                                }
                                            }
                                        }
                                    }
                                }

                                if (flag) {
                                    setState({ ...state, place: res! });
                                }
                            }
                            }
                        />
                    </FormItem>}
                </Form>
            </>}
        </Modal>
    );
};


export default NomenclaturesListModal;
