import { FunctionComponent, useEffect, useState } from 'react';
import LayoutComponent from '../../shared/layout_component';
import { useTranslation } from 'react-i18next';
import {notification, Table, App} from 'antd';
import nomenclaturesColumns from './NomenclaturesListColumns';
import { useNomenclaturesTable } from '../../actions/useNomenclaturesTable';
import { TableRow } from '../../ApiDirectory';
import NomenclaturesListModal from "./NomenclaturesListModal";
import {directoryApi} from "../../shared/api_client";
import { getEntityTitle, getEntityType, LocalValueNomenclature } from './NomenclatureListHelpers';

const NomenclaturesList: FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        nomenclatures,
        nomenclaturesLoading,
        nomenclaturesError,
        nomenclaturesClearCache,
    } = useNomenclaturesTable();
    const [isOpen, setIsOpen] = useState<{
        id: string | boolean;
        type: 'section' | 'work_type' | 'nomenclature',
        section_id?: string,
        type_id?: string,
        nomenclature_id?: string
    }>();
    const { notification } = App.useApp();

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [processSave, setProcessSave] = useState<{id: string, region_id?: string, type: 'price' | 'unit' | 'title'}[]>([]);

    const [localValues, setLocalValues] = useState<{ [nomenclature_id: string]: LocalValueNomenclature }>({});

    const rows: (TableRow & { hasChildren: boolean })[] = nomenclatures?.rows
        .map(row => {
            if (row.row_type == 'section') {
                const hasChildren = !!nomenclatures?.rows.find(row2 => row2.section_id == row.section_id && row2.row_type == 'work_type');
                return {
                    ...row,
                    hasChildren,
                };
            }

            if (row.row_type == 'work_type') {
                const hasChildren = !!nomenclatures?.rows.find(row2 => row2.type_id == row.type_id && row2.row_type == 'nomenclature');
                return {
                    ...row,
                    hasChildren,
                };
            }

            return {
                ...row,
                hasChildren: false,
            };
        })
        .filter(row => {
            if (row.row_type == 'section') return true;

            if (row.row_type == 'work_type') {
                if (expandedRowKeys.includes(row.section_id!)) {
                    return true;
                }
            }

            if (row.row_type == 'nomenclature') {
                if (expandedRowKeys.includes(row.type_id!)) {
                    return true;
                }
            }

            return false;
        }) ?? [];

    useEffect(() => {
        const res: { [nomenclature_id: string]: LocalValueNomenclature } = {};

        rows.forEach(row => {
            if (row.row_type == 'nomenclature') {
                res[row.nomenclature_id!] = {
                    unit: row.unit ?? '',
                    price: '0',
                    region_id: '',
                };
            }
        });

        setLocalValues(res);
    }, [rows]);

    (window as any).nomenclaturesList = {
        expandedRowKeys,
        setExpandedRowKeys,
        rows,
        nomenclatures,
        nomenclaturesLoading,
        nomenclaturesError,
        isOpen,
    };

    const [isOpenPopover, setIsOpenPopover] = useState<{ id: string }>({id: ''})
    const [isOpenRename, setIsOpenRename] = useState<{ id: string }>({id: ''})

    useEffect(() => {
        function onClick(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (target.closest('.nomenclatures-table__popover') == null) {
                setIsOpenPopover({id: ''});
            }
        }

        window.addEventListener('click', onClick);
        return () => window.removeEventListener('click', onClick);
    }, []);

    return (
        <>
            <LayoutComponent
                title={'Справочник номенклатур'}
                className="NomenclaturesList"
                onAction={() => {
                }}
            >
                <div className="Nomenclatures__wrapper">
                    <Table
                        className={'nomenclatures-table'}
                        columns={nomenclaturesColumns({
                            localValues: localValues,
                            isOpenRename: isOpenRename,
                            setIsOpenRename: setIsOpenRename,
                            t,
                            processSave: processSave,
                            isOpenPopover: isOpenPopover,
                            setIsOpenPopover: setIsOpenPopover,
                            onEdit: (type, section_id, type_id, nomenclature_id, value) => {

                            },
                            onEditEnd: (type, section_id, type_id, nomenclature_id, region_id, value) => {

                            },
                            onArchive: async (section_id, type_id, nomenclature_id) => {
                                try {
                                    if (nomenclature_id) {
                                        await directoryApi.nomenclatures.nomenclaturesDelete(nomenclature_id);
                                    }
                                    if (type_id) {
                                        await directoryApi.workTypes.workTypesDelete(type_id);
                                    }
                                    if (section_id) {
                                        await directoryApi.workSections.workSectionsDelete(section_id);
                                    }
                                    notification.success({ message: `${getEntityTitle(getEntityType(section_id, type_id, nomenclature_id)) } ${nomenclature_id ? 'удалена' : type_id ? 'удален' : 'удален'}` });
                                } catch (e) {
                                    notification.error({ message: 'Не удалось удалить запись' });
                                }
                                nomenclaturesClearCache();
                            },
                            onRestore: (id) => {
                            },
                            onView: (id) => {
                            },
                            onAdd: async (section_id, type_id) => {
                                if (!section_id && !type_id) {
                                    setIsOpen({ type: 'section', id: true });
                                } else if (section_id && !type_id) {
                                    setIsOpen({ type: 'work_type', id: true, section_id: section_id });
                                } else if (section_id && type_id) {
                                    setIsOpen({
                                        type: 'nomenclature',
                                        id: true,
                                        section_id: section_id,
                                        type_id: type_id,
                                    });
                                }
                            },
                            onAddNomenclature: () => {
                                setIsOpen({ type: 'nomenclature', id: true });
                            },
                            setExpandedRowKeys,
                            expandedRowKeys,
                        })}
                        rowKey={(row, index) => `${row.nomenclature_id} ${row.type_id} ${row.section_id} ${row.row_type} ${index}`}
                        dataSource={rows}
                        pagination={false}
                        onRow={(record) => ({
                            onClick: () => {
                                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                                const isExpanded = expandedRowKeys.includes(id);

                                if (record.row_type == 'section') {
                                    const workTypeIds = rows.filter(row => row.section_id == id && row.row_type == 'work_type').map(row => row.type_id!);
                                    setExpandedRowKeys(isExpanded ? [...expandedRowKeys.filter((key) => key !== id && !workTypeIds.includes(key))] : [...expandedRowKeys, id]);
                                }

                                if (record.row_type == 'work_type') {
                                    setExpandedRowKeys(isExpanded ? [...expandedRowKeys.filter((key) => key !== id)] : [...expandedRowKeys, id]);
                                }
                            },
                        })}
                    />
                </div>
            </LayoutComponent>

            <NomenclaturesListModal
                isOpen={isOpen?.id ?? false}
                type={isOpen?.type ?? 'nomenclature'}
                onClose={() => setIsOpen(undefined)}
                select_section_id={isOpen?.section_id}
                select_work_type_id={isOpen?.type_id}
                select_nomenclature_id={isOpen?.nomenclature_id}
                onDone={async (type, state) => {
                    console.log({ type, state });
                    try {
                        if (type == 'section') {
                            if (state.place.section_id.filter(e => e).length) {
                                await directoryApi.workSections.workSectionsUpdate(state.place.section_id[0], { title: state.title });
                            } else {
                                await directoryApi.workSections.workSectionsCreate({ title: state.title });
                            }
                        } else if (type == 'work_type') {
                            if (state.place.work_type_id.filter(e => e).length) {
                                await directoryApi.workTypes.workTypesUpdate(state.place.work_type_id[0], { title: state.title });
                            } else {
                                await directoryApi.workTypes.workTypesCreate({
                                    title: state.title,
                                    sections: [{ section_id: state.place.section_id[0] }],
                                });
                            }
                        } else if (type == 'nomenclature') {
                            if (state.place.nomenclature_id) {
                                await directoryApi.nomenclatures.nomenclaturesUpdate(state.place.nomenclature_id, {
                                    title: state.title,
                                    unit: state.unit,
                                });
                            } else {
                                await directoryApi.nomenclatures.nomenclaturesCreate({
                                    title: state.title,
                                    unit: state.unit,
                                    sections: state.place.work_type_id.map((e, index) => ({
                                        section_id: nomenclatures?.rows.find(e2 => e2.row_type == 'work_type' && e2.type_id == e)?.section_id ?? '',
                                        type_id: e,
                                    })),
                                });
                            }
                        }

                        setIsOpen(undefined);
                        nomenclaturesClearCache();
                        notification.success({ 'message': 'Сохранено' });
                    } catch (e) {
                        console.error(e);
                        notification.error({ message: 'Ошибка', description: (e as any)?.error?.message ?? 'Не удалось выполнить запрос' });
                    }
                }}
            />
        </>
    );
};


export default NomenclaturesList;
