import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getTableColumnKey } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';

export function checkOpenModalContragent(col: number, row: number, currentRow: TableRecord) {
  const columnKey = getTableColumnKey(col, getWData().body);
  const columns: (keyof TableRecord)[]= ['cns_budget_fakt_size', 'cns_budget_fakt_price', 'cns_budget_fakt_sum_wat'];
  const columns2: (keyof TableRecord)[]= ['cns_budget_fakt_size2', 'cns_budget_fakt_size', 'cns_budget_fakt_price', 'cns_budget_fakt_sum_wat'];

  if (!(getPassportCache().status == 'active')) return false;

  const allowAddContract = tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts == AccessItem.FULL;

  return (
    ( (allowAddContract ? columns : columns2).includes(columnKey) &&
      tokenActions.budgetFact != AccessItem.NONE &&
      (tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts === AccessItem.VIEW || tokenActions.contracts === AccessItem.FULL) &&
      currentRow?.cns_row_type == 'nomenclature' &&
      currentRow?.cns_contact_date !== '' &&
      currentRow?.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) ??
    false
  );
}

export function checkOpenModalContragentView(col: number, row: number, currentRow: TableRecord) {
  const columnKey = getTableColumnKey(col, getWData().body);
  const columns: (keyof TableRecord)[]= ['cns_budget_fakt_size', 'cns_budget_fakt_price', 'cns_budget_fakt_sum_wat'];
  return (
    (getPassportCache().status == 'active' &&
      columns.includes(columnKey) &&
      tokenActions.budgetFact != AccessItem.NONE &&
      (tokenActions.contracts === AccessItem.EDIT || tokenActions.contracts === AccessItem.VIEW || tokenActions.contracts === AccessItem.FULL) &&
      currentRow?.cns_row_type == 'contract' &&
      currentRow?.cns_contact_date !== '') ??
    false
  );
}
