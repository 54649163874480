export const localeKeys = {
  shared: {
    // Загрузка
    loading: 'shared.loading',
    // Загрузить
    loadBtn: 'shared.loadBtn',
    // Произошла ошибка
    errorExists: 'shared.errorExists',
    // Не удалось выполнить запрос
    errorRequest: 'shared.errorRequest',
    //Удалить
    deleteBtn: 'shared.deleteBtn',
    // Восстановить
    restoreBtn: 'shared.restoreBtn',
    //Изменить
    editBtn: 'shared.editBtn',
    //Просмотр
    viewBtn: 'shared.viewBtn',
    // Закрыть
    closeBtn: 'shared.closeBtn',
    // Сохранить
    saveBtn: 'shared.saveBtn',
    // Отмена
    cancelBtn: 'shared.cancelBtn',
    // Поле обязательно для заполнения
    requiredField: 'shared.requiredField',
    // Поле обязательно для заполнения
    requiredField2: 'shared.requiredField',
    // Роли
    roles: {
      // Главный руководитель проекта
      "chief_pm": "roles.chief_pm",
      // Старший руководитель проекта
      "senior_pm": "roles.senior_pm",
      // Руководитель проекта
      "pm": "roles.pm",
      // Начальник участка
      "section_chief": "roles.section_chief",
      // Неизвестная должность
      "unknown": "roles.unknown",
    },
    // Еще
    more: 'shared.more',
    // Поиск
    search: 'shared.search',
    // Экспортировать
    exportBtn: 'shared.exportBtn',
  },
  authScreen: {
    title: 'authScreen.title',
  },
  passportListScreen: {
    // Заголовок
    title: 'passportListScreen.title',
    // Добавить новый паспорт
    addPassport: 'passportListScreen.addPassport',
    // Таблица
    table: {
      // Шифр проекта
      title: 'passportListScreen.table.title',
      // РП / СРП
      srp: 'passportListScreen.table.srp',
      // Местоположение
      location: 'passportListScreen.table.location',
      // Площадь здания
      square: 'passportListScreen.table.square',
      // Бюджет план, ₽
      plan_amount1: 'passportListScreen.table.plan_amount1',
      plan_amount2: 'passportListScreen.table.plan_amount2',
      // Бюджет факт, ₽
      fact_amount: 'passportListScreen.table.fact_amount',
      fact_amount2: 'passportListScreen.table.fact_amount2',
      fact_amount3: 'passportListScreen.table.fact_amount3',
      //  Остаток
      balance: 'passportListScreen.table.balance',
      balance2: 'passportListScreen.table.balance2',
      // Количество ошибок
      errors1: 'passportListScreen.table.errors1',
      errors2: 'passportListScreen.table.errors2',
      errors3: 'passportListScreen.table.errors3',
      totalByProjects: 'passportListScreen.table.totalByProjects',
      // Изменить
      editBtn: 'passportListScreen.table.editBtn',
      // Архивировать
      archiveBtn: 'passportListScreen.table.archiveBtn',
      // Восстановить
      restoreBtn: 'passportListScreen.table.restoreBtn',
    },
    // Показать архивные проекты
    showArchiveProjects: 'passportListScreen.showArchiveProjects',
    // Скрыть архивные проекты
    hideArchiveProjects: 'passportListScreen.hideArchiveProjects',
    // Для начала работы создайте <br/>новый проект
    emptyCreate: 'passportListScreen.emptyCreate',
    // В данный момент проекты отсутствуют
    empty: 'passportListScreen.empty',
    modal: {
      // Новый проект
      addPassport: 'passportListScreen.modal.addPassport',
      // Изменить проект
      editPassport: 'passportListScreen.modal.editPassport',
      // Просмотр проекта
      viewPassport: 'passportListScreen.modal.viewPassport',
      // Закрыть
      close: 'passportListScreen.modal.close',
      // Сохранить
      save: 'passportListScreen.modal.save',
      // Создать проект
      create: 'passportListScreen.modal.create',
      // Поле обязательно для заполнения
      fieldRequired: 'passportListScreen.modal.fieldRequired',
      // О проекте
      subtitle: 'passportListScreen.modal.subtitle',
      // Шифр
      code: 'passportListScreen.modal.code',
      // Регион
      region: 'passportListScreen.modal.region',
      // Дата начала
      dateStart: 'passportListScreen.modal.dateStart',
      // Дата окончания
      dateEnd: 'passportListScreen.modal.dateEnd',
      // Площадь здания, м2
      totalArea: 'passportListScreen.modal.totalArea',
      // Коэфф-т твердых покрытий, %
      hardSurfaceRatio: 'passportListScreen.modal.hardSurfaceRatio',
      // Люди
      membersSubtitle: 'passportListScreen.modal.membersSubtitle',
      //Разделы
      sectionsSubtitle: 'passportListScreen.modal.sectionsSubtitle',
      // Добавить
      addMember: 'passportListScreen.modal.addMember',
      // Раздел
      section: 'passportListScreen.modal.section',
      // Новый раздел
      addSectionBtn: 'passportListScreen.modal.addSectionBtn',
      // defaultSections
      defaultSections: 'passportListScreen.modal.defaultSections',
      // Проект создан
      createdProject: 'passportListScreen.modal.createdProject',
      // Проект с таким именем уже существует
      projectExists: 'passportListScreen.modal.projectExists',
      // Коэфф-т не может быть больше 100
      coefficientErrorMax: 'passportListScreen.modal.coefficientErrorMax',
      // Коэфф-т не может быть меньше 0
      coefficientErrorMin: 'passportListScreen.modal.coefficientErrorMin',
    }
  },
  profileScreen: {
    // Профиль
    title: 'profileScreen.title',
    // Привязать Телеграм
    bindTelegram: 'profileScreen.bindTelegram',
    // Отвязать
    unbindTelegram: 'profileScreen.unbindTelegram',
    // Выход
    logout: 'profileScreen.logout',
    // Аватар
    avatar: 'profileScreen.avatar',
    // Телеграм
    telegram: 'profileScreen.telegram',
  },
  contragentsListScreen: {
    // Подрядчики
    title: 'contragentsListScreen.title',
    // Название организации или ИНН
    placeholderSearch: 'contragentsListScreen.placeholderSearch',
    // Для начала работы создайте /nнового подрядчика
    emptyCreate:  'contragentsListScreen.emptyCreate',
    // Новый подрядчик
    addContragentBtn: 'contragentsListScreen.addContragentBtn',
    // В данный момент подрядчики отсутствуют
    empty: 'contragentsListScreen.empty',
    // Нет данных
    noData: 'contragentsListScreen.noData',
    // ИНН
    inn: 'contragentsListScreen.inn',
    ratings:{
      //Зеленый
      green: 'contragentsListScreen.ratings.green',
      //Желтый
      yellow: 'contragentsListScreen.ratings.yellow',
      //Белый
      white: 'contragentsListScreen.ratings.white',
      //Без рейтинга
      withoutRating: 'contragentsListScreen.ratings.withoutRating',
      //Черный
      black: 'contragentsListScreen.ratings.black',
    },
    level3: {
      // Работы
      works: 'contragentsListScreen.level3.works',
      // Комментарий
      comments: 'contragentsListScreen.level3.comments',
      // Адрес
      address: 'contragentsListScreen.level3.address',
      // Без адреса
      withoutAddress: 'contragentsListScreen.level3.withoutAddress',
      // Контакты
      contacts: 'contragentsListScreen.level3.contacts',
    },
    columns: {
      // Подрядчик
      contragent: 'contragentsListScreen.columns.contragent',
      // Работы
      works: 'contragentsListScreen.columns.works',
      // Рейтинг
      rating: 'contragentsListScreen.columns.rating',
      // Регион
      region: 'contragentsListScreen.columns.region',
      // Комментарий
      comments: 'contragentsListScreen.columns.comments',
      // Адрес
      address: 'contragentsListScreen.columns.address',
      // Контакты Подрядчика
      contacts: 'contragentsListScreen.columns.contacts',
      // Контакты в PNK
      contactsPnk: 'contragentsListScreen.columns.contactsPnk',
    },
    modal: {
      // Новый подрядчик
      addContragent: 'contragentsListScreen.modal.addContragent',
      // Просмотр подрядчика
      viewContragent: 'contragentsListScreen.modal.viewContragent',
      // Редактирование подрядчика
      editContragent: 'contragentsListScreen.modal.editContragent',
      // Создать подрядчика
      createContragent: 'contragentsListScreen.modal.createContragent',
      // Подрядчик создан
      contragentCreated: 'contragentsListScreen.modal.contragentCreated',
      // Поставщик обновлен
      contragentUpdated: 'contragentsListScreen.modal.contragentUpdated',
      // Поставщик будет создан после подключения к интернету
      contragentCreatedAfterInternet: 'contragentsListScreen.modal.contragentCreatedAfterInternet',
      // Основное
      main: 'contragentsListScreen.modal.main',
      fields: {
        // Наименование
        name: 'contragentsListScreen.modal.fields.name',
        // Номер и дата договора
        contractNumber: 'contragentsListScreen.modal.fields.contractNumber',
        // Номер договора
        contractNumberValue: 'contragentsListScreen.modal.fields.contractNumberValue',
        // Тип
        type: 'contragentsListScreen.modal.fields.type',
        // Регион
        region: 'contragentsListScreen.modal.fields.region',
        // Рейтинг
        rating: 'contragentsListScreen.modal.fields.rating',
        // Материал/Работа
        material: 'contragentsListScreen.modal.fields.material',
        // ИНН
        inn: 'contragentsListScreen.modal.fields.inn',
        // ОГРН
        ogrn: 'contragentsListScreen.modal.fields.ogrn',
        // КПП
        kpp: 'contragentsListScreen.modal.fields.kpp',
        // Полное наименование
        fullName: 'contragentsListScreen.modal.fields.fullName',
        // Адрес
        address: 'contragentsListScreen.modal.fields.address',
        // Почтовый индекс
        postIndex: 'contragentsListScreen.modal.fields.postIndex',
        // Телефон
        phone: 'contragentsListScreen.modal.fields.phone',
        // Email
        email: 'contragentsListScreen.modal.fields.email',
        // Договоры
        documents: 'contragentsListScreen.modal.fields.documents',
        // Реквизиты
        requisites: 'contragentsListScreen.modal.fields.requisites',
        // Наименование банка
        bankName: 'contragentsListScreen.modal.fields.bankName',
        // Счет
        account: 'contragentsListScreen.modal.fields.account',
        // БИК
        bik: 'contragentsListScreen.modal.fields.bik',
        // К/С
        ks: 'contragentsListScreen.modal.fields.ks',
        // Добавить реквизиты
        addRequisites: 'contragentsListScreen.modal.fields.addRequisites',
        // Контакты Подрядчика
        contactsContragent: 'contragentsListScreen.modal.fields.contactsContragent',
        // Комментарий
        comment: 'contragentsListScreen.modal.fields.comment',
        // Контакты в PNK
        contactsPnk: 'contragentsListScreen.modal.fields.contactsPnk',
      }
    },
    modalRemove: {
      // Удалить подрядчика?
      removeContragent: 'contragentsListScreen.modalRemove.removeContragent',
      // Подрядчик удален
      contragentRemoved: 'contragentsListScreen.modalRemove.contragentRemoved',
      // Подрядчик будет удален после подключения к интернету
      contragentRemovedAfterInternet: 'contragentsListScreen.modalRemove.contragentRemovedAfterInternet',
    }
  },
  header: {
    // Проекты
    projects: 'header.projects',
    // Подрядчики
    suppliers: 'header.suppliers',
    // Номенклатуры
    nomenclatures: 'header.nomenclatures',
    // Цены
    prices: 'header.prices',
    // Фин. контроллер
    financialController: 'header.financialController',
    // Профиль
    profile: 'header.profile',
    // Уведомления
    notifications: 'header.notifications',
    // Добавить
    addBtn: 'header.addBtn',
    // Добавить контракт
    addContract: 'header.addContract',
    // Добавить счета
    addInvoice: 'header.addInvoice',
    // Добавить ТТН
    addWaybill: 'header.addWaybill',
    // Добавить СФ
    addBill: 'header.addBill',
    // Новый подрядчик
    addContragent: 'header.addContragent',
  },
  finController: {
    // Финансовый контроллер
    title: 'finController.title',
    // Выбран count платеж size ед. на sum руб.
    selectedCount: 'finController.selectedCount',
    // Выбрано count платежа size ед. на sum руб.
    selectedCount2: 'finController.selectedCount2',
    // Выбрано count платежей size ед. на sum руб.
    selectedCount3: 'finController.selectedCount3',
    columns: {
      // Загрузить файл чека
      deployScan: 'finController.columns.deployScan',
      // Перевести в статус
      moveToStatus: 'finController.columns.moveToStatus',
      // К оплате
      toPay: 'finController.columns.toPay',
      // Подрядчик
      contragent: 'finController.columns.contragent',
      // № счёта
      accountNumber: 'finController.columns.accountNumber',
      // Дата
      invoiceDate: 'finController.columns.invoiceDate',
      // Сумма
      sum: 'finController.columns.sum',
      // Статус
      status: 'finController.columns.status',
      // Действия
      actions: 'finController.columns.actions',
      // Название
      name: 'finController.columns.name',
      // Единица измерения
      unit: 'finController.columns.unit',
      // Объем
      volume: 'finController.columns.volume',
      // Цена за единицу с НДС
      price: 'finController.columns.price',
      // Сумма с НДС
      sumWithVAT: 'finController.columns.sumWithVAT',
      // Организация
      organization: 'finController.columns.organization',
      // Данные отсутствуют
      noData: 'finController.columns.noData'
    }
  },
  files: {
    //Загрузить файл
    loadFileBtn: 'files.loadFileBtn',
    //Загрузка файлов
    loadingFiles: 'files.loadingFiles',
    // Не удалось загрузить файл
    uploadError: 'files.uploadError',
  },
  table: {
    add: {
      contract: {
        // Добавление контрактов
        title: "table.add.contract.title",
        // Обновление контракта
        titleUpd: "table.add.contract.titleUpd",
        // Контракт превышает Бюджет План
        quotaExceeded: "table.add.contract.quotaExceeded",
        // Номер документа:
        number: "table.add.contract.number",
        // Дата:
        date: "table.add.contract.date",
        // Статьи затрат:
        nomenclatures: "table.add.contract.nomenclatures",
        // Подрядчик:
        contractor: "table.add.contract.contractor",
        // Дата начала работ:
        startDate: "table.add.contract.startDate",
        // Дата окончания работ:
        endDate: "table.add.contract.endDate",
        // Контракт создан
        created: "table.add.contract.created",
        // Контракт обновлен
        updated: "table.add.contract.updated",
        // Не удалось создать контракт
        createError: "table.add.contract.createError",
        // Сохранить контракт
        save: "table.add.contract.save",
        // Контракт будет сохранен после подключения интернета
        saveWarning: "table.add.contract.saveWarning",
        // Превышен лимит
        limitExceeded: "table.add.contract.limitExceeded",
        // Объем
        volume: "table.add.contract.volume",
        // Всего {{size}}, из них не законтрактовано {{notContracted}}
        volumeHelper: "table.add.contract.volumeHelper",
        // Цена за единицу
        price: "table.add.contract.price",
        // Плановая цена за единицу {{price}}
        priceHelper: "table.add.contract.priceHelper",
        // Сумма с НДС:
        sum: "table.add.contract.sum",
        // Плановая сумма с НДС {{sum}} и не законтрактовано {{notContracted}}
        sumHelper: "table.add.contract.sumHelper",
      },
      invoice: {
        // Счет успешно создан
        created: "table.add.invoice.created",
        // Не удалось создать счета
        createError: "table.add.invoice.createError",
        // В работе
        inWork: "table.add.invoice.inWork",
        // Контракт будет сохранен после подключения интернета
        saveOfflineWarning: "table.add.invoice.saveOfflineWarning",
        // № {{doc_number}} от {{doc_date}} на сумму {{sum_wat}}
        titleDoc: "table.add.invoice.titleDoc",
        // Сохранить счет
        save: "table.add.invoice.save",
        // Добавление счетов
        title: "table.add.invoice.title",
        // Счет превышает Контракт
        quotaExceeded: "table.add.invoice.quotaExceeded",
        // Номер документа
        number: "table.add.invoice.number",
        // Дата:
        date: "table.add.invoice.date",
        // Статьи затрат:
        nomenclatures: "table.add.invoice.nomenclatures",
        // Выберите строки паспорта
        selectRows: "table.add.invoice.selectRows",
        // Подрядчик:
        contractor: "table.add.invoice.contractor",
        // {{label}} (контрактов: {{count}})
        labelWithCount: "table.add.invoice.labelWithCount",
        // {{label}} (нет контрактов)`
        labelWithoutCount: "table.add.invoice.labelWithoutCount",
        // Контракт:
        contract: "table.add.invoice.contract",
        // Превышен лимит
        limitExceeded: "table.add.contract.limitExceeded",
        // Объем
        volume: "table.add.contract.volume",
        // Цена за единицу:
        price: "table.add.contract.price",
        // Сумма с НДС:
        sum: "table.add.contract.sum",
      },
      wayBill: {
        // Добавление ТТН
        title: "table.add.wayBill.title",
        // ТТН превышает Контракт
        quotaExceeded: "table.add.wayBill.quotaExceeded",
        // ТТН успешно создан
        created: "table.add.wayBill.created",
        // Не удалось создать ТТН
        createError: "table.add.wayBill.createError",
        // ТТН будет сохранен после подключения интернета
        saveOfflineWarning: "table.add.wayBill.saveOfflineWarning",
        // Сохранить ТТН
        save: "table.add.wayBill.save",
      },
      bill: {
        // Добавление счет-фактур
        title: "table.add.bill.title",
        // Счет-фактура превышает Контракт
        quotaExceeded: "table.add.bill.quotaExceeded",
        // Счет-фактура успешно создана
        created: "table.add.bill.created",
        // Не удалось создать счет-фактуру
        createError: "table.add.bill.createError",
        // Счет-фактура будет сохранена после подключения интернета
        saveOfflineWarning: "table.add.bill.saveOfflineWarning",
        // Сохранить счет-фактуру
        save: "table.add.bill.save",
      }
    },
    columns: {
      // "Наименование"
      name: "table.columns.name",
      // "Ед. Изм."
      unit: "table.columns.unit",

      // "Бюджет План"
      budgetPlan: "table.columns.budgetPlan",
      // "\n\nОбъем, Ед."
      budgetPlanSize: "table.columns.budgetPlanSize",
      // "Цена за Ед.\n\n $ c налогом"
      budgetPlanPrice: "table.columns.budgetPlanPrice",
      // "Сумма\n\n$ с налогом"
      budgetPlanSum: "table.columns.budgetPlanSum",
      // "FAR\n\n$ / sf (м²)"
      budgetPlanFar: "table.columns.budgetPlanFar",
      // "%  от Суммы \n\nБюджета"
      budgetPlanPercent: "table.columns.budgetPlanPercent",
      // "Дата\n\nначала"
      budgetPlanDateStart: "table.columns.budgetPlanDateStart",
      // "Дата\n\nокончания"
      budgetPlanDateEnd: "table.columns.budgetPlanDateEnd",

      // "Данные по контрактам"
      contracts: "table.columns.contracts",
      // "Плановая Дата\n\nконтракта"
      contractsPlaneDate: "table.columns.contractsPlaneDate",
      // "Дата начала \nработ по \nконтракту"
      contractsDateWorkStart: "table.columns.contractsDateWorkStart",
      // "Дата окончания \nработ по \nконтракту"
      contractsDateWorkEnd: "table.columns.contractsDateWorkEnd",
      // "Ответственный \n\nза заключение"
      contractsResponsible: "table.columns.contractsResponsible",
      // "Рекомендованный \nПодрядчик или \nПоставщик"
      contractsRecommender: "table.columns.contractsRecommender",
      // "Объем \n\nпо контракту, Ед."
      contractsSize: "table.columns.contractsSize",
      // "Цена за Ед.\n\n$ c налогом"
      contractsPrice: "table.columns.contractsPrice",
      // "Сумма\n\n$ с налогом"
      contractsSum: "table.columns.contractsSum",

      // "Внесение счетов на оплату"
      invoices: "table.columns.invoices",
      // "Номер \n\nсчета"
      invoicesNumber: "table.columns.invoicesNumber",
      // "Дата \n\nсчета"
      invoicesDate: "table.columns.invoicesDate",
      // "Объем \n\nк оплате, Ед."
      invoicesSize: "table.columns.invoicesSize",
      // "Цена за Ед.\n\n$ с налогом"
      invoicesPrice: "table.columns.invoicesPrice",
      // "Сумма\n\n$ с налогом"
      invoicesSum: "table.columns.invoicesSum",
      // "Статус \n\nсчета"
      invoicesStatus: "table.columns.invoicesStatus",



      // "Отклонение от Бюджет Плана"
      budgetPlanDiff: "table.columns.budgetPlanDiff",
      // "Сумма\n\n$ с налогом."
      budgetPlanDiffSum: "table.columns.budgetPlanDiffSum",
      // "% от Суммы Контракта / Бюджета"
      budgetPlanDiffPercent: "table.columns.budgetPlanDiffPercent",
      // "Оплачено \n\nуже"
      budgetPlanDiffPaid: "table.columns.budgetPlanDiffPaid",
      // "Будет оплачено \n\nесли ОК"
      budgetPlanDiffToPay: "table.columns.budgetPlanDiffToPay",


      // "Выполнение на объекте"
      wayBill: "table.columns.wayBill",
      // "Выполнено или Поставлено"
      waybillInstalled: "table.columns.waybillInstalled",
      // "Сумма\n\n$ с налогом"
      waybillSum: "table.columns.waybillSum",
      // "Дата\n\nпоставки"
      waybillDate: "table.columns.waybillDate",
      // "Документ\n\nномер"
      waybillDocument: "table.columns.waybillDocument",
      // "Объем, Ед."
      waybillSize: "table.columns.waybillSize",


      // "Cчет-фактуры"
      bills: "table.columns.bills",
      // "Номер \n\nСчет-фактуры"
      billNumber: "table.columns.billNumber",
      // "Дата \n\nСчет-фактуры"
      billDate: "table.columns.billDate",
      // "\n\nОбъем, Ед."
      billSize: "table.columns.billSize",
      // "Цена за Ед.\n\n$ с налогом"
      billPrice: "table.columns.billPrice",
      // "Сумма\n\n$ с налогом"
      billSum: "table.columns.billSum",
    },
    menu: {
      // Смотреть историю
      show_history: 'table.menu.show_history',
      // Скачать в XLSX
      download_xlsx: 'table.menu.download_xlsx',
      // Импортировать из XLSX
      import_xlsx: 'table.menu.import_xlsx',
      // Добавить раздел
      add_section: 'table.menu.add_section',
      // Добавить группу
      add_group: 'table.menu.add_group',
      // Добавить номенклатуру
      add_nomenclature: 'table.menu.add_nomenclature',
      // Добавить несколько номенклатур
      add_nomenclatures: 'table.menu.add_nomenclatures',
      // Вставить из буфера
      paste_from_clipboard: 'table.menu.paste_from_clipboard',
      // Вставить из буфера ниже
      paste_from_clipboard_below: 'table.menu.paste_from_clipboard_below',
      // Добавить номенклатуру ниже
      add_nomenclature_below: 'table.menu.add_nomenclature_below',
      // Добавить демо данные
      add_demo_data: 'table.menu.add_demo_data',
      // Переименовать
      rename_row: 'table.menu.rename_row',
      // Удалить
      remove_row: 'table.menu.remove_row',
      // Разрешить изменение
      allow_editing: 'table.menu.allow_editing',
      // История ячейки
      history_cell: 'table.menu.history_cell',
      modal_many: {
        // Сколько добавить строк?
        how_count_rows: 'table.menu.modal_many.how_count_rows',
        // Выберите количество добавляемых номенклатур
        select_count_rows: 'table.menu.modal_many.select_count_rows',
      },
      // Удалить строку?
      remove_row2: 'table.menu.remove_row2',
      // Удалить секцию?
      remove_section: 'table.menu.remove_section',
      // Удалить группу?
      remove_group: 'table.menu.remove_group',
      // Удалить номенклатуру?
      remove_nomenclature: 'table.menu.remove_nomenclature',
      import: {
        // Импорт из XLSX
        import_xlsx: 'table.menu.import.import_xlsx',
        // Импортировать
        import: 'table.menu.import.import',
      },
    }
  }
};
