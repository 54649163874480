import {getEmptyTableRecord, TableRecord} from "../canvas_table/components/TableRecord";
import {getWData} from "./getWData";
import dateFormat, { dateFormatter, numberFormatter } from '../../shared/dateFormat';
import {ContractorShort} from "../../ApiDirectory";

export function loadDataMapContracts(line: TableRecord): TableRecord[] {
  const result: TableRecord[] = [];
  let i = 1;

  const items = [...line.cns_contracts];
  items.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());

  for (const contract of items) {
    const contractor = line.cns_contractors.find(e => e.value == contract.contragent_id);

    const invoiceSize = line.cns_invoices.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.size), 0);
    const invoiceSum = line.cns_invoices.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.sum_wat), 0);
    const invoicePrice = invoiceSum / (invoiceSize || 1);

    const wayBillSize = line.cns_way_bills.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.size), 0);
    const wayBillSum = line.cns_way_bills.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.sum_wat), 0);

    const billSize = line.cns_bills.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.size), 0);
    const billSum = line.cns_bills.filter(e => e.parent_contract_id == contract.document_id).reduce((acc, e) => acc + Number(e.sum_wat), 0);
    const billPrice = billSum / (billSize || 1);

    for (const contragentKey in line.cns_contractors) {
      const contractor = line.cns_contractors[contragentKey];

      const contractorDb = getWData().db.dbContractors.find(c => c.id == contractor.value);

      if (contractorDb) {
        const color = contractorDb.rating_label === 'Желтый' ? '#fff7af' : contractorDb.rating_label === 'Зеленый' ? '#bbffce' : contractorDb.rating_label === 'Черный' ? 'black' : contractorDb.rating_label === 'Без рейтинга' ? undefined : undefined;

        contractor.item = contractorDb;
        contractor.color = color;
      }
    }

    const line2: TableRecord = {
      ...getEmptyTableRecord(),
      cns_id: `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}_${contract.document_id}`,
      cns_title: `Контракт № ${contract.doc_number} от ${dateFormatter(contract.doc_date)} (${contractor?.label})`,
      cns_parent_id2: `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}`,
      cns_section_id: line.cns_section_id,
      cns_group_id: line.cns_group_id,
      cns_nomenclature_id: line.cns_nomenclature_id,
      cns_row_type: 'contract',
      cns_level: line.cns_level + 1,
      cns_number: `${line.cns_number}.${i}`,
      cns_ed_izm: '-',
      cns_budget_plan_size: null as any,
      cns_budget_plan_price: null as any,
      cns_budget_plan_sum_wat: null as any,
      cns_contact_date: contract.doc_date,
      cns_contact_date_start: contract.date_start,
      cns_contact_date_end: contract.date_end,
      cns_budget_fakt_size: contract.size,
      cns_budget_fakt_price: contract.price,
      cns_budget_fakt_sum_wat: contract.sum_wat,
      cns_contracts: [contract],
      cns_contractors: line.cns_contractors.filter(e => e.value == contract.contragent_id),
      cns_invoices: line.cns_invoices.filter(e => e.parent_contract_id == contract.document_id),
      cns_invoice_count: null as any,
      cns_invoice_status: null as any,
      cns_invoice_size: invoiceSize,
      cns_invoice_price: invoicePrice,
      cns_invoice_sum: invoiceSum,
      cns_way_bills: line.cns_way_bills.filter(e => e.parent_contract_id == contract.document_id),
      cns_well_bill_percent: null as any,
      cns_well_bill_size: numberFormatter(wayBillSize),
      cns_well_bill_delivery_sum: wayBillSum,
      cns_well_bill_doc_number: '-',
      cns_well_bill_delivery_date: '-',
      cns_bills: line.cns_bills.filter(e => e.parent_contract_id == contract.document_id),
      cns_bill_number: '-',
      cns_bill_date: '-',
      cns_bill_size: billSize,
      cns_bill_price: billPrice,
      cns_bill_sum: billSum,
      cns_otkl_percent: null as any,
      cns_otkl_sum: null as any,
      cns_payment_future: null as any,
      cns_percent_payment: null as any,
    }

    // console.log({line2});

    result.push(line2);
    i++;
  }

  return result;
}

export function loadDataMapInvoices(line: TableRecord): TableRecord[] {
  // console.log(`loadDataMapInvoices`, line);
  const result: TableRecord[] = [];
  let i = 1;

  for (const invoice of line.cns_invoices) {
    const indexContract = line.cns_contracts.findIndex(e => e.document_id == invoice.parent_contract_id);

    // console.log(`(${line.cns_number} => ${`${line.cns_number}.${indexContract + 1}.${i}`}) invoice: ${invoice.doc_number} от ${dateFormatter(invoice.doc_date)} ${invoice.document_id}`);

    // console.log(`loadDataMapInvoices`, line, `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}_${invoice.parent_contract_id}`);

    result.push({
      ...getEmptyTableRecord(),
      cns_id: `${invoice.id}`,
      cns_title: `-`,
      cns_parent_id2: `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}_${invoice.parent_contract_id}`,
      cns_section_id: line.cns_section_id,
      cns_group_id: line.cns_group_id,
      cns_nomenclature_id: line.cns_nomenclature_id,
      cns_row_type: 'detail',
      cns_level: line.cns_level + 2,
      cns_number: `${line.cns_number}.${indexContract + 1}.${i}`,
      cns_ed_izm: '-',
      cns_budget_plan_size: null as any,
      cns_budget_plan_price: null as any,
      cns_budget_plan_sum_wat: null as any,
      cns_invoice_count: invoice.doc_number,
      cns_invoice_date: invoice.doc_date,
      cns_invoice_size: Number(invoice.size),
      cns_invoice_price: Number(invoice.price),
      cns_invoice_sum: Number(invoice.sum_wat),
      cns_invoice_status: invoice.status ?? '-',
      cns_invoices: [invoice],
    });
    i++;
  }

  return result;
}

export function loadDataMapWayBills(line: TableRecord): TableRecord[] {
  // console.log(`loadDataMapWayBills`, line)
  const result: TableRecord[] = [];
  let i = 1;

  for (const waybill of line.cns_way_bills) {
    const indexContract = line.cns_contracts.findIndex(e => e.document_id == waybill.parent_contract_id);

    result.push({
      ...getEmptyTableRecord(),
      cns_id: `${waybill.id}`,
      cns_title: `-`,
      cns_parent_id2: `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}_${waybill.parent_contract_id}`,
      cns_section_id: line.cns_section_id,
      cns_group_id: line.cns_group_id,
      cns_nomenclature_id: line.cns_nomenclature_id,
      cns_row_type: 'detail',
      cns_level: line.cns_level + 2,
      cns_number: `${line.cns_number}.${indexContract + 1}.${i}`,
      cns_ed_izm: '-',
      cns_budget_plan_size: null as any,
      cns_budget_plan_price: null as any,
      cns_budget_plan_sum_wat: null as any,
      cns_well_bill_size: waybill.size,
      cns_well_bill_doc_number: waybill.doc_number,
      cns_well_bill_delivery_sum: Number(waybill.sum_wat),
      cns_well_bill_delivery_date: waybill.doc_date,
      cns_way_bills: [{...waybill}],
    });
    i++;
  }

  return result;
}

export function loadDataMapBills(line: TableRecord): TableRecord[] {
  const result: TableRecord[] = [];
  let i = 1;

  for (const bill of line.cns_bills) {
    const indexContract = line.cns_contracts.findIndex(e => e.document_id == bill.parent_contract_id);

    result.push({
      ...getEmptyTableRecord(),
      cns_id: `${bill.id}`,
      cns_title: `-`,
      cns_parent_id2: `${line.cns_section_id}_${line.cns_group_id}_${line.cns_nomenclature_id}_${bill.parent_contract_id}`,
      cns_section_id: line.cns_section_id,
      cns_group_id: line.cns_group_id,
      cns_nomenclature_id: line.cns_nomenclature_id,
      cns_row_type: 'detail',
      cns_level: line.cns_level + 2,
      cns_number: `${line.cns_number}.${indexContract + 1}.${i}`,
      cns_ed_izm: '-',
      cns_budget_plan_size: null as any,
      cns_budget_plan_price: null as any,
      cns_budget_plan_sum_wat: null as any,
      cns_bill_number: bill.doc_number,
      cns_bill_date: bill.doc_date,
      cns_bill_size: Number(bill.size),
      cns_bill_price: Number(bill.price),
      cns_bill_sum: Number(bill.sum_wat),
      cns_bills: [bill],
    });
    i++;
  }

  return result;
}

export function createDetailLines(line: TableRecord): TableRecord[] {
  const result: TableRecord[] = [];

  let contractLines = loadDataMapContracts(line);

  result.push(...contractLines);

  for (const contractLineKey in contractLines) {
    const resultInvoicesLines: TableRecord[] = [];
    const resultWayBillsLines: TableRecord[] = [];
    const resultBillsLines: TableRecord[] = [];

    const contractLine = contractLines[contractLineKey];

    const invoicesLines = loadDataMapInvoices(contractLine);

    resultInvoicesLines.push(...invoicesLines);

    const wayBillsLines = loadDataMapWayBills(contractLine);

    resultWayBillsLines.push(...wayBillsLines);

    const billsLines = loadDataMapBills(contractLine);

    resultBillsLines.push(...billsLines);

    const maxResultLines = Math.max(resultInvoicesLines.length, resultWayBillsLines.length, resultBillsLines.length);

    // console.log({ resultInvoicesLines });

    for (let i = 0; i < maxResultLines; i++) {
      const invoiceLine = resultInvoicesLines[i];
      const wayBillLine = resultWayBillsLines[i];
      const billLine = resultBillsLines[i];

      let resultLine: TableRecord = {
        ...getEmptyTableRecord(),
        ...invoiceLine,
        ...wayBillLine,
        ...billLine,
        // cns_title: `${invoiceLine ? `Есть счет ${invoiceLine.cns_title};` : ''}${wayBillLine ? `Есть ТТН ${wayBillLine.cns_title};` : ''}${billLine ? `Есть СФ ${billLine.cns_title};` : ''}`
        cns_title: `-`,
        cns_ed_izm: '-',
        cns_budget_plan_size: null as any,
        cns_budget_plan_price: null as any,
        cns_budget_plan_sum_wat: null as any,
        cns_contact_date_start: '-' as any,
        cns_contact_date_end: '-' as any,
        cns_responsibles: null as any,
        cns_contractors: null as any,
        cns_budget_fakt_size: null as any,
        cns_budget_fakt_price: null as any,
        cns_budget_fakt_sum_wat: null as any,
        cns_invoice_count: null as any,
        cns_invoice_status: null as any,
        cns_well_bill_percent: null as any,
        cns_well_bill_size: null as any,
        cns_otkl_percent: null as any,
        cns_otkl_sum: null as any,
        cns_payment_future: null as any,
        cns_percent_payment: null as any,
      };

      if (invoiceLine) {
        resultLine.cns_invoices = invoiceLine.cns_invoices;
        resultLine.cns_invoice_count = invoiceLine.cns_invoice_count;
        resultLine.cns_invoice_date = invoiceLine.cns_invoice_date;
        resultLine.cns_invoice_size = invoiceLine.cns_invoice_size;
        resultLine.cns_invoice_price = invoiceLine.cns_invoice_price;
        resultLine.cns_invoice_sum = invoiceLine.cns_invoice_sum;
        resultLine.cns_invoice_status = invoiceLine.cns_invoice_status;
      } else {
        resultLine.cns_invoice_count = null as any;
        resultLine.cns_invoice_date = '' as any;
        resultLine.cns_invoice_size = null as any;
        resultLine.cns_invoice_price = null as any;
        resultLine.cns_invoice_sum = null as any;
        resultLine.cns_invoice_status = null as any;
      }

      if (wayBillLine) {
        resultLine.cns_way_bills = wayBillLine.cns_way_bills;
        resultLine.cns_well_bill_size = wayBillLine.cns_well_bill_size;
        resultLine.cns_well_bill_doc_number = wayBillLine.cns_well_bill_doc_number;
        resultLine.cns_well_bill_delivery_sum = wayBillLine.cns_well_bill_delivery_sum;
        resultLine.cns_well_bill_delivery_date = wayBillLine.cns_well_bill_delivery_date;
      } else {
        resultLine.cns_well_bill_size = null as any;
        resultLine.cns_well_bill_doc_number = '-' as any;
        resultLine.cns_well_bill_delivery_sum = null as any;
        resultLine.cns_well_bill_delivery_date = '-' as any;
      }

      if (billLine) {
        resultLine.cns_bills = billLine.cns_bills;
        resultLine.cns_bill_number = billLine.cns_bill_number;
        resultLine.cns_bill_date = billLine.cns_bill_date;
        resultLine.cns_bill_size = billLine.cns_bill_size;
        resultLine.cns_bill_price = billLine.cns_bill_price;
        resultLine.cns_bill_sum = billLine.cns_bill_sum;
      } else {
        resultLine.cns_bill_size = null as any;
        resultLine.cns_bill_price = null as any;
        resultLine.cns_bill_sum = null as any;
      }

      result.push(resultLine);
    }
  }


  return result;
}



