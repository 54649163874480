/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MetaData {
  /**
   * Номер текущей страницы
   * @example 1
   */
  current_page?: number;
  /**
   * Количество элементов на странице
   * @example 10
   */
  per_page?: number;
  /**
   * Общее количество элементов
   * @example 20
   */
  total?: number;
  /**
   * Номер последней страницы
   * @example 2
   */
  last_page?: number;
}

export interface DeleteManyRequest {
  ids?: string[];
}

/** @format binary */
export type BinaryResponse = File;

export interface SimpleResponse {
  /**
   * Результат выполнения запроса
   * @example true
   */
  success?: boolean;
}

export interface SimpleResultResponse {
  /**
   * Результат выполнения запроса
   * @example true
   */
  result?: boolean;
}

export interface Error {
  /**
   * Сообщение об ошибке
   * @example "Ошибка"
   */
  message?: string;
  /** @example "ERROR_EXCEPTION" */
  code?: string;
}

export type DirectoryPlaneList = Directory[];

export interface Directory {
  /**
   * Идентификатор объекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование объекта
   * @example "Россия"
   */
  title?: string;
  /**
   * Код объекта
   * @example "ru"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Порядковый номер объекта в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления объекта в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления объекта в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export interface DirectoryList {
  data?: Directory[];
  meta?: MetaData;
}

export type SectionPlaneList = Section[];

export interface Section {
  /**
   * Идентификатор объекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование объекта
   * @example "Россия"
   */
  title?: string;
  /**
   * Код объекта
   * @example "ru"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Разница между датой заключения договора и стартом работ в днях
   * @example 30
   */
  contract_date_shift?: number;
  /**
   * Порядковый номер объекта в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления объекта в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления объекта в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export interface SectionList {
  data?: Section[];
  meta?: MetaData;
}

export interface CreateDirectoryRequest {
  /**
   * Наименование объекта справочника
   * @example "Регион"
   */
  title: string;
  /**
   * Код объекта справочника
   * @example "region"
   */
  name: string;
  /**
   * Статус объекта в справочнике
   * @default "active"
   */
  status?: "active" | "archive";
  /** Дополнительные свойства объекта */
  data?: object;
  /**
   * Порядок вывода объекта в справочнике
   * @default 0
   */
  order?: number;
}

export interface CreateSectionRequest {
  /**
   * Наименование раздела работ
   * @example "Регион"
   */
  title: string;
  /**
   * Код раздела
   * @example "region"
   */
  name: string;
  /**
   * Статус раздела в справочнике
   * @default "active"
   */
  status?: "active" | "archive";
  /** Дополнительные свойства раздела */
  data?: object;
  /**
   * Разница между датой заключения договора и стартом работ в днях
   * @default 30
   */
  contract_date_shift?: number;
  /**
   * Порядок вывода объекта в справочнике
   * @default 0
   */
  order?: number;
}

export interface UpdateDirectoryRequest {
  /**
   * Наименование объекта справочника
   * @example "Регион"
   */
  title?: string;
  /**
   * Код объекта справочника
   * @example "region"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @default "active"
   */
  status?: "active" | "archive";
  /** Дополнительные свойства объекта */
  data?: object;
  /**
   * Порядок вывода объекта в справочнике
   * @default 0
   */
  order?: number;
}

export interface UpdateSectionRequest {
  /**
   * Наименование раздела
   * @example "Сети"
   */
  title?: string;
  /**
   * Код раздела
   * @example "seti"
   */
  name?: string;
  /**
   * Статус раздела
   * @default "active"
   */
  status?: "active" | "archive";
  /** Дополнительные свойства раздела */
  data?: object;
  /**
   * Разница между датой заключения договора и стартом работ в днях
   * @default 30
   */
  contract_date_shift?: number;
  /**
   * Порядок вывода раздела в справочнике
   * @default 0
   */
  order?: number;
}

export interface HelperValue {
  /** Текст для отображения пользователю */
  text?: string;
  /** Значение для отправки на BE */
  value?: string;
  /** Означает что сущность находится не в статусе active(только для пользователей, групп и ролей) */
  disabled?: boolean;
}

export interface HelperValueList {
  data?: HelperValue[];
}

export interface HelperPageValueList {
  data?: HelperValue[];
  meta?: MetaData;
}

export interface ProjectMember {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  user_id?: string;
  /**
   * ФИО пользователя
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Код должности пользователя на проекте
   * @example "chief_pm"
   */
  position?: string;
}

export interface Project {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  id?: string;
  /**
   * Код проекта, используется для создания групп в СПП
   * @example "project_124124512512"
   */
  name?: string;
  /** Уникальное наименование проекта */
  title?: string;
  /**
   * Статус проекта
   * @example "active"
   */
  status?: string;
  /**
   * Идентификатор региона
   * @format uuid
   */
  region_id?: string;
  /**
   * Наименование региона
   * @example "Москва"
   */
  region_title?: string;
  /**
   * Локация
   * @example "Москва"
   */
  location?: string;
  /**
   * Дата старта проекта
   * @format date
   */
  start_date?: string;
  /**
   * Дата окончания проекта
   * @format date
   */
  end_date?: string;
  members?: ProjectMember[];
  /**
   * Площадь проекта
   * @format float
   * @example 100.5
   */
  total_area?: number;
  /**
   * Коэфф-т твердых покрытий, %
   * @format float
   * @example 10.5
   */
  hard_surface_ratio?: number;
  /**
   * Сумма планируемого бюджета. Видит только ГРП
   * @format float
   */
  plan_amount?: number;
  /**
   * Сумма фактического бюджета. Видит только ГРП
   * @format float
   */
  fact_amount?: number;
  /**
   * Сумма ориентировочного бюджета
   * @format float
   */
  guideline_amount?: number;
  /**
   * Оплачено уже, Сумма. Видит только ГРП
   * @format float
   */
  fact_payments_amount?: number;
  /**
   * Оплачено уже, Процент. Видит только ГРП
   * @format float
   */
  fact_payments_percent?: number;
  /**
   * Не оплаченная сумма БП. Видит только ГРП
   * @format float
   */
  fact_payments_balance?: number;
  /** Количество ошибок / всего. Видит только ГРП */
  errors_total?: number;
  /** Количество ошибок» / в том числе незакрытых. Видит только ГРП */
  errors_unclosed?: number;
  /**
   * Время создания проекта
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления проекта
   * @format date-time
   */
  updated_at?: string;
}

export interface ProjectSection {
  /**
   * Идентификатор объекта
   * @format uuid
   */
  id?: string;
  /** Номер раздела в проекте */
  row_number?: number;
  /**
   * Наименование объекта
   * @example "Россия"
   */
  title?: string;
  /**
   * Код объекта
   * @example "ru"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Порядковый номер объекта в справочнике
   * @example 1
   */
  order?: number;
  /** Сдвиг на -количество дней от даты старта работ до даты заключения договора */
  contract_date_shift?: number;
  members?: ProjectMember[];
  /**
   * Ориентировочный бюджет
   * @format float
   */
  guideline_amount?: number;
  /**
   * Время добавления объекта в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления объекта в справочнике
   * @format date-time
   */
  updated_at?: string;
}

export interface ProjectDetail {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  id?: string;
  /**
   * Код проекта, используется для создания групп в СПП
   * @example "project_124124512512"
   */
  name?: string;
  /** Уникальное наименование проекта */
  title?: string;
  /**
   * Статус проекта
   * @example "active"
   */
  status?: string;
  /**
   * Идентификатор региона
   * @format uuid
   */
  region_id?: string;
  /**
   * Наименование региона
   * @example "Москва"
   */
  region_title?: string;
  /**
   * Локация
   * @example "Москва"
   */
  location?: string;
  /**
   * Дата старта проекта
   * @format date
   */
  start_date?: string;
  /**
   * Дата окончания проекта
   * @format date
   */
  end_date?: string;
  members?: ProjectMember[];
  /**
   * Площадь проекта
   * @format float
   * @example 100.5
   */
  total_area?: number;
  /**
   * Коэфф-т твердых покрытий, %
   * @format float
   * @example 10.5
   */
  hard_surface_ratio?: number;
  sections?: ProjectSection[];
  /**
   * Время создания проекта
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления проекта
   * @format date-time
   */
  updated_at?: string;
  /** Признак подтверждения бюджет ориентира */
  guideline_budget_confirmed?: boolean;
}

export interface ProjectList {
  data?: Project[];
}

export interface ProjectTable {
  /** Список кололонок, которые могут быть как одиночными, так и вложенными, в этом случае будет  массив вложенных колонок */
  headers?: string[];
  /** Список строк таблицы */
  body?: string[][];
  /** Список колонок, которые не должны отображаться в таблице */
  hidden?: string[];
}

export interface UploadedFile {
  /**
   * Идентификтор файла
   * @format uuid
   */
  id?: string;
  /**
   * Имя файла
   * @example "file.pdf"
   */
  name?: string;
  /**
   * Mime-тип файла
   * @example "application/pdf"
   */
  content_type?: string;
  /** Размер файла в байтах */
  size?: number;
  /**
   * Расширение файла (если удалось определить)
   * @example ".pdf"
   */
  ext?: string;
  /** Название бакета в S3 хранилище */
  bucket?: string;
  /** Путь до файла в S3 */
  path?: string;
  /** Ссылка на файл */
  url?: string;
  /**
   * Дата загрузки файла
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата обновления файла
   * @format date-time
   */
  "updated-at"?: string;
}

export interface DocumentFile {
  /**
   * Идентификтор файла
   * @format uuid
   */
  id?: string;
  /**
   * Идентификатор контракта
   * @format uuid
   */
  contract_id?: string;
  /**
   * Имя файла
   * @example "file.pdf"
   */
  name?: string;
  /** Путь до файла в S3 */
  path?: string;
  /** Ссылка на файл */
  url?: string;
}

export interface Setting {
  /**
   * Идентификтор настройки
   * @format uuid
   */
  id?: string;
  /**
   * Наименование настройки
   * @example "Текст ошибки проверки пароля"
   */
  title?: string;
  /**
   * Код настройки
   * @example "PASSWORD_ERROR_CHECK"
   */
  name?: string;
  /**
   * Значение настройки
   * @example "Ваш пароль недостаточно сложный"
   */
  value?: string;
  /** словарь индивидуальных свойств настройки (тип значения, ед. изм., мин. значение и т.д.) */
  properties?: object;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type SettingsList = Setting[];

export interface SettingUpdateRequest {
  /**
   * Наименование настройки
   * @maxLength 255
   * @example "Текст ошибки проверки пароля"
   */
  title: string;
  /**
   * Значение настройки
   * @maxLength 255
   * @example "Ваш пароль недостаточно сложный"
   */
  value: string;
}

export interface SettingMassUpdateRequest {
  settings: {
    /**
     * Идентификтор настройки
     * @format uuid
     */
    id: string;
    data: SettingUpdateRequest;
  }[];
}

export interface Notification {
  /**
   * Идентификатор уведомления
   * @format uuid
   */
  id?: string;
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Текст уведомления
   * @example "Создан проект Тестовый"
   */
  message?: string;
  /**
   * Наименование проекта
   * @example "Тестовый"
   */
  comment?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface NotificationList {
  data?: Notification[];
  meta?: MetaData;
}

export interface SocketProject {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  id?: string;
  /** Код проекта */
  name?: string;
  /** Наименование проекта */
  title?: string;
  /**
   * Дана старта проекта
   * @format date-time
   */
  start_date?: string;
  /** @format date-time */
  end_date?: string;
  /**
   * Дата окончания проекта
   * @format float
   */
  total_area?: number;
}

export interface PassportRow {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Идентификтор раздела
   * @format uuid
   */
  section_id?: string;
  /**
   * Идентификатор группы
   * @format uuid
   */
  type_id?: string;
  /**
   * Идентификатор номенклатуры
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Идентификатор раздела или группы или проекта
   * @format uuid
   */
  parent_id?: string;
  /**
   * Тип строки
   * @example "section"
   */
  row_type?: "project" | "section" | "type" | "nomenclature";
  /**
   * Уровень иерархии строки (0 - проект, 1 - раздел, 2 - группа, 3 - номенклатура)
   * @example 1
   */
  level?: number;
  /** Тип номенклатуры (1 - работа, 2 - материал, 3 - работа и материал) */
  nomenclature_type?: 1 | 2 | 3;
  /**
   * Номер строки в паспорте
   * @example 1
   */
  row_number?: string;
  /**
   * Наименование раздела/группы/номенклатуры
   * @example "Сети"
   */
  title?: string;
  /**
   * Ед. изм.
   * @example "шт."
   */
  unit?: string;
  /** Признак что по строке БП согласован */
  bp_is_approved?: boolean;
  /**
   * Объем по строке в БП
   * @format float
   */
  bp_volume?: number;
  /**
   * Стоимость бюджет-ориентира
   * @format float
   */
  guideline_amount?: number;
  /** Массив значений БП (объем, цена, сумма, FAR, FAR%, статус согласования, флаг что необходимо согласование, причина отклонения) */
  plan_budget?: {
    /** @format float */
    volume?: number;
    /** @format float */
    price?: number;
    /** @format float */
    amount?: number;
    /** @format float */
    far_amount?: number;
    /** @format float */
    far_percent?: number;
    /** 0 - черновик, 1 - на согласовании, 2 - согласован, 3 - отклонен */
    plan_status?: 0 | 1 | 2 | 3;
    plan_need_approve?: boolean;
    plan_reject_reason?: string;
  };
  /** Массив значений ФБ (объем, цена, сумма, FAR, дата последнего изменения) */
  fact_budget?: {
    /** @format float */
    volume?: number;
    /** @format float */
    price?: number;
    /** @format float */
    amount?: number;
    /** @format float */
    far_amount?: number;
    /** @format date-time */
    fact_last_updated?: string;
  };
  /** Массив значений (даты старта и окончания) */
  graph?: {
    /** @format date-time */
    start_date?: string;
    /** @format date-time */
    end_date?: string;
  };
  /** Массив значений данных по контрактам (дата контракта, плановая дата, список контрактов) */
  contract_data?: {
    /** @format date-time */
    contract_date?: string;
    /** @format date-time */
    planning_date?: string;
    contracts?: Contract[];
  };
  /** Список участников проекта */
  members?: ProjectMember[];
}

export interface Contract {
  /**
   * Идентификатор контракта
   * @format uuid
   */
  id?: string;
  /** @format uuid */
  project_contract_data_id?: string;
  /** Контрагент */
  contractor?: string;
  /**
   * Дата контракта
   * @format date
   * @example "02.02.2024"
   */
  contract_date?: string;
  /** @format float */
  price?: number;
  /** @format float */
  volume?: number;
  /** @format float */
  amount?: number;
  files?: DocumentFile[];
}

export interface DeleteRowResult {
  /** Показатель успеха удаления строки */
  result?: boolean;
  /** Номер удаленной строки */
  row_number?: string;
  /** Измененные строки */
  updated_rows?: PassportRow[];
  /** Строки с изменением номера */
  shifted_rows?: PassportRow[];
}

export interface RenamedRowResult {
  /** Показатель успеха */
  result?: boolean;
  /** Название колонки в паспорте объекта */
  column?: "section_id" | "type_id" | "nomenclature_id";
}

export interface PlanStatusResponse {
  /** Результат выполнения операции */
  result?: boolean;
  data?: {
    /**
     * Идентификатор проекта
     * @format uuid
     */
    project_id?: string;
    /**
     * Тип строк
     * @example "section"
     */
    type?: string;
    /** Статус строк (0 - черновик, 1 - на согласовании, 2 - согласовано, 3 - отклонено) */
    status?: number;
    /** Список идентификаторов разделов, если type = section */
    section_ids?: string[];
    /**
     * Идентификатор раздела, если type = work_type или nomenclature
     * @format uuid
     */
    section_id?: string;
    /** Список идентификаторов групп, если type = work_type */
    type_ids?: string[];
    /**
     * Идентификатор группы, если type = nomenclature
     * @format uuid
     */
    type_id?: string;
    /** Список идентификаторов номенклатур, если type = nomenclature */
    nomenclature_ids?: string[];
    /** True если номенклатура согласована, если type = nomenclature */
    bp_is_approved?: boolean;
  };
  updated_rows?: PassportRow[];
}

export interface PassportAlert {
  /** Сообщение */
  message?: string;
}

export interface PassportError {
  /** Сообщение */
  message?: string;
  /** Код ошибки */
  code?: string;
  /** Дополнительная информация по ошибке */
  data?: object;
}

export interface InvoiceListItem {
  /**
   * Идентификтор счета
   * @format uuid
   */
  id?: string;
  /**
   * Дата счета
   * @format date
   * @example "22.11.2022"
   */
  invoice_date?: string;
  /**
   * Номер счета
   * @example "А122344"
   */
  invoice_number?: string;
  /**
   * Объем номенклатуры
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма
   * @format float
   * @example 1000
   */
  amount?: number;
  /**
   * Cтатус счета
   * @example "to_pay"
   */
  status?: string;
  /**
   * Расшифровка статуса
   * @example "К оплате"
   */
  status_label?: string;
  /** Причина отклонения счета */
  reject_reason?: string;
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  contractor_id?: string;
  /** Наименование контрагента */
  contractor_name?: string;
  /**
   * Дата создания счета
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата последнего изменения счета
   * @format date-time
   */
  updated_at?: string;
  /**
   * Идентификатор проекта, к которому относится счет
   * @format uuid
   */
  project_id?: string;
  /**
   * Наименование проекта
   * @example "82/ДМД-3_к1-3_DC3/01.04.25/5"
   */
  project_title?: string;
  /**
   * Идентификатор раздела, к которому относится счет
   * @format uuid
   */
  section_id?: string;
  /**
   * Наименование раздела
   * @example "Земля"
   */
  section_title?: string;
  /**
   * Идентификатор группы, к которой относится счет
   * @format uuid
   */
  type_id?: string;
  /**
   * Наименование группы
   * @example "Наружные сети электроснабжения"
   */
  type_title?: string;
  /**
   * Идентификатор номенклатуры, к которой относится счет
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Наименование номенклатуры
   * @example "Сети 0,4 кВ Корпуса"
   */
  nomenclature_title?: string;
  /**
   * Ед. измерения номенклатуры
   * @example "шт."
   */
  nomenclature_unit?: string;
  /**
   * Вид номенклатуры (1 - работа, 2 - материал, 3 - работа и материал)
   * @example 1
   */
  nomenclature_type?: number;
}

export interface InvoicesList {
  data?: InvoiceListItem[];
  meta?: MetaData;
}

export interface InvoiceStatusUpdated {
  /** Результат обновления статуса */
  result?: boolean;
  data?: {
    /** Целевой статус счета */
    status?: string;
    /** Причина отклонения счета */
    rejected_reason?: string;
    ids?: string[];
  };
}

export interface ContractStatusUpdated {
  /** Результат обновления статуса */
  result?: boolean;
  data?: {
    /** Целевой статус контракта */
    status?: string;
    /** Причина отклонения счета */
    rejected_reason?: string;
    ids?: string[];
  };
}

export interface InvoicesGroupedList {
  data?: InvoiceGroupedListItem[];
  meta?: MetaData;
}

export interface InvoiceGroupedListItem {
  /**
   * Идентификтор счета
   * @format uuid
   */
  id?: string;
  /**
   * Дата счета
   * @format date
   * @example "22.11.2022"
   */
  invoice_date?: string;
  /**
   * Номер счета
   * @example "А122344"
   */
  invoice_number?: string;
  /**
   * Объем счета
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Средняя цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма счета
   * @format float
   * @example 1000
   */
  amount?: number;
  /**
   * Cтатус счета
   * @example "to_pay"
   */
  status?: string;
  /**
   * Расшифровка статуса
   * @example "К оплате"
   */
  status_label?: string;
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  contractor_id?: string;
  /** Наименование контрагента */
  contractor_name?: string;
  /**
   * Идентификатор документа контракта
   * @format uuid
   */
  contract_id?: string;
  /** Причина отклонения счета */
  reject_reason?: string;
  nomenclatures?: InvoiceGroupedListItemNomenclature[];
}

export interface InvoiceGroupedListItemNomenclature {
  /**
   * Дата создания счета/номенклатуры
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата последнего изменения счета/номенклатуры
   * @format date-time
   */
  updated_at?: string;
  /**
   * Идентификатор проекта, к которому относится счет/номенклатура
   * @format uuid
   */
  project_id?: string;
  /**
   * Наименование проекта
   * @example "82/ДМД-3_к1-3_DC3/01.04.25/5"
   */
  project_title?: string;
  /**
   * Идентификатор раздела, к которому относится счет
   * @format uuid
   */
  section_id?: string;
  /**
   * Наименование раздела
   * @example "Земля"
   */
  section_title?: string;
  /**
   * Идентификатор группы, к которой относится счет
   * @format uuid
   */
  type_id?: string;
  /**
   * Наименование группы
   * @example "Наружные сети электроснабжения"
   */
  type_title?: string;
  /**
   * Идентификатор номенклатуры, к которой относится счет
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Наименование номенклатуры
   * @example "Сети 0,4 кВ Корпуса"
   */
  nomenclature_title?: string;
  /**
   * Ед. измерения номенклатуры
   * @example "шт."
   */
  nomenclature_unit?: string;
  /**
   * Вид номенклатуры (1 - работа, 2 - материал, 3 - работа и материал)
   * @example 1
   */
  nomenclature_type?: number;
  /**
   * Объем номенклатуры
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма
   * @format float
   * @example 1000
   */
  amount?: number;
}

export interface ContractsGroupedList {
  data?: ContractGroupedListItem[];
  meta?: MetaData;
}

export interface ContractGroupedListItem {
  /**
   * Идентификатор контракта
   * @format uuid
   */
  id?: string;
  /**
   * Номер документа контракта
   * @example "Б122344"
   */
  contract_number?: string;
  /**
   * Дата документа контракта
   * @format date
   * @example "22.11.2022"
   */
  contract_date?: string;
  /**
   * Дата начала работ по контракту
   * @format date
   * @example "01.02.2023"
   */
  start_date?: string;
  /**
   * Дата окончания работ по контракту
   * @format date
   * @example "01.12.2023"
   */
  end_date?: string;
  /**
   * Объем счета
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Средняя цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма счета
   * @format float
   * @example 1000
   */
  amount?: number;
  /**
   * Cтатус счета
   * @example "on_approval_by_spm"
   */
  status?: string;
  /**
   * Расшифровка статуса
   * @example "На согласовании СРП"
   */
  status_label?: string;
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  contractor_id?: string;
  /** Наименование контрагента */
  contractor_name?: string;
  /** Причина отклонения счета */
  reject_reason?: string;
  nomenclatures?: ContractGroupedListItemNomenclature[];
  approvals?: ContractGroupedListItemApproval[];
}

export interface ContractGroupedListItemNomenclature {
  /**
   * Дата создания контракта/номенклатуры
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата последнего изменения контракта/номенклатуры
   * @format date-time
   */
  updated_at?: string;
  /**
   * Идентификатор проекта, к которому относится контракт/номенклатура
   * @format uuid
   */
  project_id?: string;
  /**
   * Наименование проекта
   * @example "82/ДМД-3_к1-3_DC3/01.04.25/5"
   */
  project_title?: string;
  /**
   * Идентификатор раздела, к которому относится контракт
   * @format uuid
   */
  section_id?: string;
  /**
   * Наименование раздела
   * @example "Земля"
   */
  section_title?: string;
  /**
   * Идентификатор группы, к которой относится контракт
   * @format uuid
   */
  type_id?: string;
  /**
   * Наименование группы
   * @example "Наружные сети электроснабжения"
   */
  type_title?: string;
  /**
   * Идентификатор номенклатуры, к которой относится контракт
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Наименование номенклатуры
   * @example "Сети 0,4 кВ Корпуса"
   */
  nomenclature_title?: string;
  /**
   * Ед. измерения номенклатуры
   * @example "шт."
   */
  nomenclature_unit?: string;
  /**
   * Вид номенклатуры (1 - работа, 2 - материал, 3 - работа и материал)
   * @example 1
   */
  nomenclature_type?: number;
  /**
   * Объем номенклатуры
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма
   * @format float
   * @example 1000
   */
  amount?: number;
}

export interface InvoiceProjectsList {
  data?: InvoiceProjectItem[];
}

export interface InvoiceProjectItem {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование проекта
   * @example "Шушары 3 к.5"
   */
  title?: string;
  sections?: InvoiceProjectSectionItem[];
}

export interface InvoiceProjectSectionItem {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Идентификатор раздела
   * @format uuid
   */
  section_id?: string;
  /** Номер раздела в проекте */
  row_number?: number;
  /**
   * Наименование раздела
   * @example "Земля"
   */
  section_name?: string;
  /**
   * Кол-во счетов в разделе
   * @example 10
   */
  invoices_count?: number;
  /**
   * Сумма счетов из раздела
   * @format float
   */
  invoices_amount?: number;
  /**
   * Сумма объема счетов из раздела
   * @format float
   */
  invoices_volume?: number;
}

export interface ContractProjectsList {
  data?: ContractProjectItem[];
}

export interface ContractProjectItem {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование проекта
   * @example "Шушары 3 к.5"
   */
  title?: string;
  sections?: ContractProjectSectionItem[];
}

export interface ContractProjectSectionItem {
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Идентификатор раздела
   * @format uuid
   */
  section_id?: string;
  /** Номер раздела в проекте */
  row_number?: number;
  /**
   * Наименование раздела
   * @example "Земля"
   */
  section_name?: string;
  /**
   * Кол-во контрактов в разделе
   * @example 10
   */
  contracts_count?: number;
  /**
   * Сумма контрактов из раздела
   * @format float
   */
  contracts_amount?: number;
  /**
   * Сумма объема контрактов из раздела
   * @format float
   */
  contracts_volume?: number;
}

export interface InvoiceDocument {
  /**
   * Идентификатор счета
   * @format uuid
   */
  id?: string;
  /**
   * Дата счета
   * @format date
   * @example "22.11.2022"
   */
  invoice_date?: string;
  /**
   * Номер счета
   * @example "А122344"
   */
  invoice_number?: string;
  /**
   * Объем счета
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Средняя цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма счета
   * @format float
   * @example 1000
   */
  amount?: number;
  /**
   * Cтатус счета
   * @example "to_pay"
   */
  status?: string;
  /**
   * Расшифровка статуса
   * @example "К оплате"
   */
  status_label?: string;
  /** Причина отклонения счета */
  reject_reason?: string;
  /**
   * Идентифиактор контрагента
   * @format uuid
   */
  contractor_id?: string;
  /** Название контрагента */
  contractor_name?: string;
  /**
   * Идентификатор документа контракта
   * @format uuid
   */
  contract_id?: string;
  /**
   * Номер документа контракта
   * @example "Б122344"
   */
  contract_number?: string;
  /**
   * Дата документа контракта
   * @format date
   * @example "22.11.2022"
   */
  contract_date?: string;
  nomenclatures?: InvoiceGroupedListItemNomenclature[];
  files?: DocumentFile[];
}

export interface ContractDocument {
  /**
   * Идентификтор контракта
   * @format uuid
   */
  id?: string;
  /**
   * Номер документа контракта
   * @example "Б122344"
   */
  contract_number?: string;
  /**
   * Дата документа контракта
   * @format date
   * @example "22.11.2022"
   */
  contract_date?: string;
  /**
   * Дата начала работ по контракту
   * @format date
   * @example "01.02.2023"
   */
  start_date?: string;
  /**
   * Дата окончания работ по контракту
   * @format date
   * @example "01.12.2023"
   */
  end_date?: string;
  /**
   * Объем контракта
   * @format float
   * @example 10
   */
  volume?: number;
  /**
   * Средняя цена за ед. номенклатуры
   * @format float
   * @example 100
   */
  price?: number;
  /**
   * Сумма контракта
   * @format float
   * @example 1000
   */
  amount?: number;
  /**
   * Cтатус контракта
   * @example "to_pay"
   */
  status?: string;
  /**
   * Расшифровка статуса
   * @example "К оплате"
   */
  status_label?: string;
  /** Причина отклонения контракта */
  reject_reason?: string;
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  contractor_id?: string;
  /** Название контрагента */
  contractor_name?: string;
  nomenclatures?: InvoiceGroupedListItemNomenclature[];
  files?: DocumentFile[];
  approvals?: ContractGroupedListItemApproval[];
}

export interface ContractGroupedListItemApproval {
  /**
   * Идентификатор смены статуса
   * @format uuid
   */
  id?: string;
  /**
   * Идентификатор документа
   * @format uuid
   */
  document_id?: string;
  /**
   * Статус контракта
   * @example "on_approval_by_spm"
   */
  status?: string;
  /**
   * Наименование статуса
   * @example "На согласовании СРП"
   */
  status_label?: string;
  /** Причина отмены */
  reject_reason?: string;
  /**
   * Идентификатор согласовавшего пользователя
   * @format uuid
   */
  user_id?: string;
  /**
   * ФИО согласовавшего пользователя
   * @example "Иванов Иван Иванович"
   */
  user_fullname?: string;
  /**
   * Должность согласовавшего пользователя
   * @example "СРП"
   */
  user_position?: string;
  /**
   * Время согласования
   * @format date-time
   * @example "2024-09-05T05:59:28+00:00"
   */
  moved_at?: string;
  /**
   * Время перевода в этот статус
   * @format date-time
   * @example "2024-09-05T05:59:28+00:00"
   */
  created_at?: string;
}

export interface InvoiceHelperValue {
  /** Наименование статуса */
  text?: string;
  /** Значение для отправки на BE */
  value?: string;
  /** Количество документов в этом статусе */
  count?: number;
}

export interface InvoiceHelperValueList {
  data?: InvoiceHelperValue[];
}

export interface ProjectSimpleError {
  /**
   * Идентификатор ошибки
   * @format uuid
   */
  id?: string;
  /**
   * Идентификатор документа с ошибкой
   * @format uuid
   */
  document_id?: string;
  /**
   * Тип ошибки
   * @example "contract_over_error"
   */
  type?: string;
  /** Статус ошибки */
  status?: "late" | "open" | "closed";
  /**
   * Код ошибки
   * @example "D03"
   */
  code?: string;
  /**
   * Описание ошибки
   * @example "Имеется превышение по сумме законтрактованного объема, цены за единицу либо суммарной стоимости с учетом уже введенных контрактов"
   */
  description?: string;
  /**
   * Идентификатор пользователя, совершившего ошибку
   * @format uuid
   */
  user_id?: string;
  /** ФИО пользователя, совершившего ошибку */
  user_name?: string;
  /**
   * Должность пользователя, совершившего ошибку, на проекте
   * @example "НУ"
   */
  user_position?: string;
  /**
   * Идентификатор пользователя, погасившего ошибку
   * @format uuid
   */
  closed_by_user_id?: string;
  /** ФИО пользователя, погасившего ошибку */
  closed_by_user_name?: string;
  /**
   * Должность пользователя, погасившего ошибку, на проекте
   * @example "НУ"
   */
  closed_by_position?: string;
  /**
   * Время возникновения ошибки
   * @format date-time
   */
  created_at?: string;
  /**
   * Время погашения ошибки
   * @format date-time
   */
  closed_at?: string;
  /** Флаг видимости ошибке в списке, невидимые используются только для индикации в таблице */
  is_hidden?: boolean;
}

export interface ProjectErrorData {
  /**
   * уровень ошибки (error - красный, warning - красный мигающий)
   * @example "error"
   */
  level?: string;
  fields?: string[];
  contract?: {
    /**
     * Идентификатор контракта
     * @format uuid
     */
    id?: string;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
    /**
     * Дата контракта
     * @format date
     */
    contract_date?: string;
    /** Номер контракта */
    contract_number?: string;
    /**
     * Дата начала работ
     * @format date
     */
    start_date?: string;
    /**
     * Дата окончания работ
     * @format date
     */
    end_date?: string;
  };
  payment_invoice?: {
    /**
     * Идентификатор счета
     * @format uuid
     */
    id?: string;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
    /**
     * Дата счета
     * @format date
     */
    invoice_date?: string;
    /** Номер счета */
    invoice_number?: string;
    /** Статус счета */
    status?: string;
    contractor?: Contractor;
  };
  bill?: {
    /**
     * Идентификатор СФ
     * @format uuid
     */
    id?: string;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
    /**
     * Дата СФ
     * @format date
     */
    bill_date?: string;
    /** Номер СФ */
    bill_number?: string;
    /** Статус СФ */
    status?: string;
    contractor?: Contractor;
  };
  waybill?: {
    /**
     * Идентификатор ТТН
     * @format uuid
     */
    id?: string;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
    /**
     * Дата ТТН
     * @format date
     */
    waybill_date?: string;
    /** Номер ТТН */
    waybill_number?: string;
    /** Статус ТТН */
    status?: string;
    contractor?: Contractor;
  };
  contract_data?: {
    /**
     * Плановая дата контракта
     * @format date
     */
    planning_date?: string;
    /**
     * Дата начала
     * @format date
     */
    start_date?: string;
    /**
     * Дата начала работ по контракту
     * @format date
     */
    work_start_date?: string;
    /**
     * Дата окончания
     * @format date
     */
    end_date?: string;
    /**
     * Дата окончания работ по контракту
     * @format date
     */
    work_end_date?: string;
  };
  plan_budget?: {
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
  };
  fact_budget?: {
    /**
     * Объем
     * @format float
     */
    volume?: number;
    /**
     * Цена за ед.
     * @format float
     */
    price?: number;
    /**
     * Сумма
     * @format float
     */
    amount?: number;
  };
  exceed?: {
    /**
     * Превышение по объему
     * @format float
     */
    volume?: number;
    /**
     * Превышение по цене за ед.
     * @format float
     */
    price?: number;
    /**
     * Превышение по сумме
     * @format float
     */
    amount?: number;
  };
  delivered_completed?: {
    /**
     * Процент выполнения на объекте
     * @format float
     */
    completed_percent?: number;
  };
}

export interface ProjectError {
  /**
   * Идентификатор ошибки
   * @format uuid
   */
  id?: string;
  /**
   * Тип ошибки
   * @example "contract_over_error"
   */
  type?: string;
  /**
   * Код ошибки
   * @example "D03"
   */
  code?: string;
  /**
   * Идентификатор документа с ошибкой
   * @format uuid
   */
  document_id?: string;
  /** Флаг видимости ошибке в списке, невидимые используются только для индикации в таблице */
  is_hidden?: boolean;
  user?: {
    /**
     * Идентификатор пользователя, совершившего ошибку
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, совершившего ошибку */
    full_name?: string;
    /**
     * Должность на проекте
     * @example "НУ"
     */
    position?: string;
  };
  closed_by_user?: {
    /**
     * Идентификатор пользователя, погасившего ошибку
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, погасившего ошибку */
    full_name?: string;
    /**
     * Должность на проекте
     * @example "РП"
     */
    position?: string;
  };
  mentors?: {
    /**
     * Идентификатор пользователя, ответственнго за погашение ошибки
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, ответственнго за погашение ошибки */
    full_name?: string;
  }[];
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Идентификтор раздела
   * @format uuid
   */
  section_id?: string;
  /**
   * Идентификатор группы
   * @format uuid
   */
  type_id?: string;
  /**
   * Идентификатор номенклатуры
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Идентификатор раздела или группы или проекта
   * @format uuid
   */
  parent_id?: string;
  /**
   * Тип строки
   * @example "nomenclature"
   */
  row_type?: "project" | "section" | "type" | "nomenclature";
  /**
   * Уровень иерархии строки (0 - проект, 1 - раздел, 2 - группа, 3 - номенклатура)
   * @example 3
   */
  level?: number;
  /**
   * Номер строки в паспорте
   * @example "1.1.1"
   */
  row_number?: string;
  error?: ProjectErrorData;
  /**
   * Время возникновения ошибки
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления ошибки
   * @format date-time
   */
  updated_at?: string;
  /**
   * Время погашения ошибки
   * @format date-time
   */
  closed_at?: string;
}

export interface ProjectErrorDetail {
  /**
   * Идентификатор ошибки
   * @format uuid
   */
  id?: string;
  /**
   * Тип ошибки
   * @example "contract_over_error"
   */
  type?: string;
  /**
   * Код ошибки
   * @example "D03"
   */
  code?: string;
  /**
   * Идентификатор документа с ошибкой
   * @format uuid
   */
  document_id?: string;
  /** Флаг видимости ошибке в списке, невидимые используются только для индикации в таблице */
  is_hidden?: boolean;
  user?: {
    /**
     * Идентификатор пользователя, совершившего ошибку
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, совершившего ошибку */
    full_name?: string;
    /**
     * Должность на проекте
     * @example "НУ"
     */
    position?: string;
  };
  closed_by_user?: {
    /**
     * Идентификатор пользователя, погасившего ошибку
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, погасившего ошибку */
    full_name?: string;
    /**
     * Должность на проекте
     * @example "РП"
     */
    position?: string;
  };
  mentors?: {
    /**
     * Идентификатор пользователя, ответственнго за погашение ошибки
     * @format uuid
     */
    id?: string;
    /** ФИО пользователя, ответственнго за погашение ошибки */
    full_name?: string;
  }[];
  /**
   * Идентификатор проекта
   * @format uuid
   */
  project_id?: string;
  /**
   * Идентификтор раздела
   * @format uuid
   */
  section_id?: string;
  /**
   * Идентификатор группы
   * @format uuid
   */
  type_id?: string;
  /**
   * Идентификатор номенклатуры
   * @format uuid
   */
  nomenclature_id?: string;
  /**
   * Идентификатор раздела или группы или проекта
   * @format uuid
   */
  parent_id?: string;
  /**
   * Тип строки
   * @example "nomenclature"
   */
  row_type?: "project" | "section" | "type" | "nomenclature";
  /**
   * Уровень иерархии строки (0 - проект, 1 - раздел, 2 - группа, 3 - номенклатура)
   * @example 3
   */
  level?: number;
  /**
   * Номер строки в паспорте
   * @example "1.1.1"
   */
  row_number?: string;
  error?: ProjectErrorData;
  /** Только для типов ошибок с переполнением */
  nomenclatures?: ProjectError[];
  /**
   * Время возникновения ошибки
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления ошибки
   * @format date-time
   */
  updated_at?: string;
  /**
   * Время погашения ошибки
   * @format date-time
   */
  closed_at?: string;
}

export interface ProjectErrorList {
  data?: ProjectError[];
  meta?: MetaData;
}

export interface ProjectSimpleErrorList {
  data?: ProjectSimpleError[];
  meta?: MetaData;
}

export interface Contractor {
  /** @format uuid */
  id?: string;
  name?: string;
  type?: {
    /** @format uuid */
    id?: string;
    name?: string;
  };
  location?: string[];
  rating?: number;
  tags?: string[];
  comment?: string;
  contacts?: string;
  inn?: string;
  ogrn?: string;
  legal_address?: string;
  postal_address?: string;
  phone?: string;
  /** @format email */
  email?: string;
  doc_number?: string;
  /** @format date */
  doc_date?: string;
}

export interface HelperUserList {
  data?: User[];
}

export interface User {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /**
   * ФИО пользователя
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login?: string;
  /**
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификатор чата с ботом в Telegram */
  telegram_id?: string;
  /** Имя пользователя в Telegram */
  telegram_username?: string;
  /**
   * Статус акивности/архивности пользователя
   * @example "active"
   */
  status?: string;
  /** Признак суперпользователя */
  is_superuser?: boolean;
  /** Признак пользователя из AD */
  in_active_directory?: boolean;
  /**
   * @format float
   * @default "Лимит фактического превышения план бюджета в процентах"
   */
  exceeding_limit_percent?: number;
  /**
   * Ссылка на фото пользователя
   * @format url
   */
  avatar_url?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://epo-api.develop.pnk.itmegastar.com/epo/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title PNK EPO Service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://epo-api.develop.pnk.itmegastar.com/epo/api
 * @externalDocs https://www.notion.so/megastar/v2-3388738db3264bd7b57b4cfe343e1690
 * @contact <d.kornilov@itmegabox.com>
 *
 * Сервис ЭПО проекта PNK
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  projects = {
    /**
     * @description Получить список объектов доступных пользователю
     *
     * @tags Проект
     * @name ProjectsList
     * @summary Список проектов
     * @request GET:/projects
     * @secure
     */
    projectsList: (
      query?: {
        /**
         * Фильтр по статусу проекта
         * @default "active"
         */
        status?: "active" | "archive";
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectList, Error>({
        path: `/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый проект
     *
     * @tags Проект
     * @name ProjectsCreate
     * @summary Создать проект
     * @request POST:/projects
     * @secure
     */
    projectsCreate: (
      data: {
        /** Наименование проекта (шифр) */
        title: string;
        /**
         * Идентификатор региона (локация)
         * @format uuid
         */
        region_id?: string;
        /** Локация */
        location?: string;
        /**
         * Площадь, м2
         * @format float
         * @min 1
         */
        total_area?: number;
        /**
         * Коэфф-т твердых покрытий, %
         * @format float
         */
        hard_surface_ratio?: number;
        /**
         * Дата старта проекта
         * @format date
         * @example "2023-12-01T00:00:00.000Z"
         */
        start_date?: string;
        /**
         * Дата окончания проекта
         * @format date
         * @example "2023-12-31T00:00:00.000Z"
         */
        end_date?: string;
        /** Должности на проекте (люди) */
        members?: ProjectMember[];
        /** Идентификаторы разделов из справочника (разделы) */
        sections?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectDetail, Error>({
        path: `/projects`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр информации о проекте
     *
     * @tags Проект
     * @name ProjectsDetail
     * @summary Просмотр проекта
     * @request GET:/projects/{id}
     * @secure
     */
    projectsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ProjectDetail, Error>({
        path: `/projects/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить информацию о проекте
     *
     * @tags Проект
     * @name ProjectsUpdate
     * @summary Обновить проект
     * @request PUT:/projects/{id}
     * @secure
     */
    projectsUpdate: (
      id: string,
      data: {
        /** Наименование проекта (шифр) */
        title?: string;
        /**
         * Идентификатор региона
         * @format uuid
         */
        region_id?: string;
        /** Локация */
        location?: string;
        /**
         * Площадь, м2
         * @format float
         * @min 1
         */
        total_area?: number;
        /**
         * Коэфф-т твердых покрытий, %
         * @format float
         */
        hard_surface_ratio?: number;
        /**
         * Дата старта проекта
         * @format date
         * @example "2023-12-01T00:00:00.000Z"
         */
        start_date?: string;
        /**
         * Дата окончания проекта
         * @format date
         * @example "2023-12-31T00:00:00.000Z"
         */
        end_date?: string;
        /** Должности на проекте (люди) */
        members?: ProjectMember[];
        /** Идентификаторы разделов из справочника (разделы) */
        sections?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectDetail, Error>({
        path: `/projects/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Полное удаление проекта
     *
     * @tags Проект
     * @name ProjectsDelete
     * @summary Удалить проект
     * @request DELETE:/projects/{id}
     * @secure
     */
    projectsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/projects/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить таблицу паспорта объекта
     *
     * @tags Проект
     * @name TableDetail
     * @summary Получить таблицу
     * @request GET:/projects/{id}/table
     * @secure
     */
    tableDetail: (id: string, params: RequestParams = {}) =>
      this.request<ProjectTable, Error>({
        path: `/projects/${id}/table`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проект
     * @name ProjectActivate
     * @summary Возвращение проекта из архива
     * @request POST:/projects/{id}/activate
     * @secure
     */
    projectActivate: (id: string, params: RequestParams = {}) =>
      this.request<ProjectDetail, Error>({
        path: `/projects/${id}/activate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проект
     * @name ProjectArchive
     * @summary Архивация проекта
     * @request POST:/projects/{id}/archive
     * @secure
     */
    projectArchive: (id: string, params: RequestParams = {}) =>
      this.request<ProjectDetail, Error>({
        path: `/projects/${id}/archive`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проект
     * @name ProjectUpdateTotalArea
     * @summary Обновить площадь проекта
     * @request POST:/projects/{id}/area
     * @secure
     */
    projectUpdateTotalArea: (
      id: string,
      data: {
        /**
         * Площадь, м2
         * @format float
         * @min 1
         */
        total_area: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectDetail, Error>({
        path: `/projects/${id}/area`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workSections = {
    /**
     * @description Получить постраничный список разделов работ из справочника
     *
     * @tags Разделы работ
     * @name WorkSectionsList
     * @summary Список разделов
     * @request GET:/work-sections
     * @secure
     */
    workSectionsList: (
      query?: {
        /** Поиск по наименованию или коду раздела */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "contract_date_shift" | "created_at" | "updated_at";
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SectionList, Error>({
        path: `/work-sections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый раздел работ в справочнике
     *
     * @tags Разделы работ
     * @name WorkSectionsCreate
     * @summary Создать раздел
     * @request POST:/work-sections
     * @secure
     */
    workSectionsCreate: (data: CreateSectionRequest, params: RequestParams = {}) =>
      this.request<Section, Error>({
        path: `/work-sections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление нескольких разделов работ из справочника
     *
     * @tags Разделы работ
     * @name WorkSectionsDelete
     * @summary Удаление разделов
     * @request DELETE:/work-sections
     * @secure
     */
    workSectionsDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/work-sections`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Разделы работ
     * @name SortCreate
     * @summary Обновление порядковых номеров разделов в справочнике
     * @request POST:/work-sections/sort
     * @secure
     */
    sortCreate: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SectionPlaneList, Error>({
        path: `/work-sections/sort`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о разделе работ по его идентификатору
     *
     * @tags Разделы работ
     * @name WorkSectionsDetail
     * @summary Просмотр раздела
     * @request GET:/work-sections/{id}
     * @secure
     */
    workSectionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Section, Error>({
        path: `/work-sections/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные раздела работ в справочнике
     *
     * @tags Разделы работ
     * @name WorkSectionsUpdate
     * @summary Обновление раздела
     * @request PUT:/work-sections/{id}
     * @secure
     */
    workSectionsUpdate: (id: string, data: UpdateSectionRequest, params: RequestParams = {}) =>
      this.request<Section, Error>({
        path: `/work-sections/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление раздел работ из справочника
     *
     * @tags Разделы работ
     * @name WorkSectionsDelete2
     * @summary Удалить раздел
     * @request DELETE:/work-sections/{id}
     * @originalName workSectionsDelete
     * @duplicate
     * @secure
     */
    workSectionsDelete2: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/work-sections/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  excel = {
    /**
     * @description Импорт данных в раздел проекта
     *
     * @tags Экспорт/Импорт
     * @name SectionsImportCreate
     * @summary Импорт раздела
     * @request POST:/excel/sections/import
     * @secure
     */
    sectionsImportCreate: (
      data: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id: string;
        /**
         * Идентификатор раздела работ
         * @format uuid
         */
        section_id: string;
        /**
         * Файл с данными для импорта, максимальный размер 20Mb (csv, xls, xlsx)
         * @format binary
         */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/excel/sections/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Импорт данных в вид работ проекта
     *
     * @tags Экспорт/Импорт
     * @name WorkTypesImportCreate
     * @summary Импорт вида работ
     * @request POST:/excel/work-types/import
     * @secure
     */
    workTypesImportCreate: (
      data: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id: string;
        /**
         * Идентификатор раздела работ
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор вида работ
         * @format uuid
         */
        type_id: string;
        /**
         * Файл с данными для импорта, максимальный размер 20Mb (csv, xls, xlsx)
         * @format binary
         */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/excel/work-types/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт данных раздела проекта в файл .xlsx
     *
     * @tags Экспорт/Импорт
     * @name SectionsExportList
     * @summary Экспорт раздела в файл
     * @request GET:/excel/sections/export
     * @secure
     */
    sectionsExportList: (
      query: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id: string;
        /**
         * Идентификатор раздела работ
         * @format uuid
         */
        section_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BinaryResponse, Error>({
        path: `/excel/sections/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Экспорт данных проекта в файл .xlsx
     *
     * @tags Экспорт/Импорт
     * @name ProjectsExportList
     * @summary Экспорт проекта в файл
     * @request GET:/excel/projects/export
     * @secure
     */
    projectsExportList: (
      query: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BinaryResponse, Error>({
        path: `/excel/projects/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Экспорт данных вида работ проекта в файл .xlsx
     *
     * @tags Экспорт/Импорт
     * @name WorkTypesExportList
     * @summary Экспорт вида работ в файл
     * @request GET:/excel/work-types/export
     * @secure
     */
    workTypesExportList: (
      query: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id: string;
        /**
         * Идентификатор раздела работ
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор вида работ
         * @format uuid
         */
        type_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BinaryResponse, Error>({
        path: `/excel/work-types/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  helpers = {
    /**
     * @description Получить список регионов для выпадающего списка
     *
     * @tags Хелперы
     * @name RegionsList
     * @summary Список регионов
     * @request GET:/helpers/regions
     * @secure
     */
    regionsList: (
      query?: {
        /** Поиск по наименованию или коду */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperValueList, Error>({
        path: `/helpers/regions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список разделов работ для выпадающего списка
     *
     * @tags Хелперы
     * @name WorkSectionsList
     * @summary Список разделов работ
     * @request GET:/helpers/work-sections
     * @secure
     */
    workSectionsList: (
      query?: {
        /** Поиск по наименованию или коду */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperValueList, Error>({
        path: `/helpers/work-sections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список локаций проектов для выпадающего списка
     *
     * @tags Хелперы
     * @name ProjectLocationsList
     * @summary Список использованных локаций в проектах
     * @request GET:/helpers/project-locations
     * @secure
     */
    projectLocationsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
        },
        Error
      >({
        path: `/helpers/project-locations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список контрагентов из справочника
     *
     * @tags Хелперы
     * @name ContractorsList
     * @summary Список контрагентов из справочника
     * @request GET:/helpers/contractors
     * @secure
     */
    contractorsList: (
      query?: {
        /** Поиск по наименованию и ИНН */
        search?: string;
        /**
         * Пропускать контрагентов с черным рейтингом
         * @min 0
         * @max 1
         * @default 0
         */
        skip_low_rating?: number;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /**
             * Контрагент
             * @example "ООО СпецСтрой"
             */
            text?: string;
            /**
             * Идентификатор контрагента
             * @format uuid
             */
            value?: string;
            /** ИНН контрагента */
            inn?: string;
            /** Рейтинг (0-без рейтинга, 1-черный, 2-желтый, 3-зеленый) */
            rating?: number;
          }[];
          meta?: MetaData;
        },
        Error
      >({
        path: `/helpers/contractors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список статусов счетов для выпадающего списка по активным проектам или по одному проекту
     *
     * @tags Хелперы
     * @name InvoiceStatusesList
     * @summary Список статусов счетов
     * @request GET:/helpers/invoice-statuses
     * @secure
     */
    invoiceStatusesList: (
      query?: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор раздела проекта
         * @format uuid
         */
        section_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceHelperValueList, Error>({
        path: `/helpers/invoice-statuses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список статусов контрактов для выпадающего списка по активным проектам или по одному проекту
     *
     * @tags Хелперы
     * @name ContractStatusesList
     * @summary Список статусов контрактов
     * @request GET:/helpers/contract-statuses
     * @secure
     */
    contractStatusesList: (
      query?: {
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор раздела проекта
         * @format uuid
         */
        section_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceHelperValueList, Error>({
        path: `/helpers/contract-statuses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список контрактов по проектам
     *
     * @tags Хелперы
     * @name ContractsList
     * @summary Список контрактов по проектам
     * @request GET:/helpers/contracts
     * @secure
     */
    contractsList: (
      query?: {
        /** Поиск по номеру контракта */
        search?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /**
             * Идентификтор документа контракта
             * @format uuid
             */
            id?: string;
            /** Контрагент */
            contractor?: string;
            /**
             * Дата контракта
             * @format date
             */
            contract_date?: string;
            /** Номер контракта */
            contract_number?: string;
          }[];
          meta?: MetaData;
        },
        Error
      >({
        path: `/helpers/contracts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список пользователей находящихся в группе ГРП
     *
     * @tags Хелперы
     * @name CpmUsersList
     * @summary Список пользователей в группе ГРП
     * @request GET:/helpers/cpm-users
     * @secure
     */
    cpmUsersList: (
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 100
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperUserList, Error>({
        path: `/helpers/cpm-users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * @description Загрузка временного файла в S3 хранилище
     *
     * @tags Файлы
     * @name UploadCreate
     * @summary Загрузка временного файла
     * @request POST:/files/upload
     * @secure
     */
    uploadCreate: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedFile, Error>({
        path: `/files/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление временного файла из S3 хранилища
     *
     * @tags Файлы
     * @name FilesDelete
     * @summary Удаление временного файла
     * @request DELETE:/files/{id}
     * @secure
     */
    filesDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/files/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  settings = {
    /**
     * @description Получить список настроек сервиса
     *
     * @tags Настройки
     * @name SettingsList
     * @summary Список настроек сервиса
     * @request GET:/settings
     * @secure
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<SettingsList, Error>({
        path: `/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные нескольких настроек сервиса
     *
     * @tags Настройки
     * @name UpdateCreate
     * @summary Обновить список настроек
     * @request POST:/settings/update
     * @secure
     */
    updateCreate: (data: SettingMassUpdateRequest, params: RequestParams = {}) =>
      this.request<SettingsList, Error>({
        path: `/settings/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Детальный просмотр данных настройки
     *
     * @tags Настройки
     * @name SettingsDetail
     * @summary Просмотр настройки
     * @request GET:/settings/{id}
     * @secure
     */
    settingsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Setting, Error>({
        path: `/settings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить одну настроку по ее идентификатору
     *
     * @tags Настройки
     * @name SettingsUpdate
     * @summary Обновить настройку
     * @request PUT:/settings/{id}
     * @secure
     */
    settingsUpdate: (id: string, data: SettingUpdateRequest, params: RequestParams = {}) =>
      this.request<Setting, Error>({
        path: `/settings/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * @description Получить список всех уведомлений пользователя
     *
     * @tags Уведомления
     * @name NotificationsList
     * @summary Список уведомлений пользователя
     * @request GET:/notifications
     * @secure
     */
    notificationsList: (
      query?: {
        /** @default 1 */
        page?: number;
        /** @default 10 */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationList, Error>({
        path: `/notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  passport = {
    /**
     * @description Получить данные проекта с проверкой доступа к нему
     *
     * @tags Паспорт проекта
     * @name ProjectsDetail
     * @summary Данные проекта
     * @request GET:/passport/projects/{id}
     * @secure
     */
    projectsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SocketProject, Error>({
        path: `/passport/projects/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Проверить доступ пользователя к проекту
     *
     * @tags Паспорт проекта
     * @name ProjectsCheckPermissionsDetail
     * @summary Проверка доступа к проекту
     * @request GET:/passport/projects/{id}/check-permissions
     * @secure
     */
    projectsCheckPermissionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          result?: boolean;
        },
        Error
      >({
        path: `/passport/projects/${id}/check-permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить раздел в паспорт проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsCreate
     * @summary Добавить раздел
     * @request POST:/passport/projects/{id}/sections
     * @secure
     */
    projectsSectionsCreate: (
      id: string,
      data: {
        /** Наименование раздела */
        title: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PassportRow, Error>({
        path: `/passport/projects/${id}/sections`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить раздел из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsDelete
     * @summary Удалить раздел
     * @request DELETE:/passport/projects/{pid}/sections/{id}
     * @secure
     */
    projectsSectionsDelete: (pid: string, id: string, params: RequestParams = {}) =>
      this.request<DeleteRowResult, Error>({
        path: `/passport/projects/${pid}/sections/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить группу в раздел паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesCreate
     * @summary Добавить группу в раздел
     * @request POST:/passport/projects/{pid}/sections/{sid}/types
     * @secure
     */
    projectsSectionsTypesCreate: (
      pid: string,
      sid: string,
      data: {
        /** Наименование группы */
        title?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PassportRow, Error>({
        path: `/passport/projects/${pid}/sections/${sid}/types`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить раздел из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesDelete
     * @summary Удалить раздел
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{id}
     * @secure
     */
    projectsSectionsTypesDelete: (pid: string, sid: string, id: string, params: RequestParams = {}) =>
      this.request<DeleteRowResult, Error>({
        path: `/passport/projects/${pid}/sections/${sid}/types/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить номенклатуру в группу раздела паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesCreate
     * @summary Добавить номенклатуру в группу
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures
     * @secure
     */
    projectsSectionsTypesNomenclaturesCreate: (
      pid: string,
      sid: string,
      tid: string,
      data: {
        /** Наименование номенклатуры */
        title?: string;
        /** Ед. изм. */
        unit?: string;
        /**
         * Вставка номенклатуры после этого идентификатора
         * @format uuid
         */
        insert_after?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          row?: PassportRow;
          shifted_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить список номенклатур в группу раздела паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesManyCreate
     * @summary Добавить список номенклатур в группу
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/many
     * @secure
     */
    projectsSectionsTypesNomenclaturesManyCreate: (
      pid: string,
      sid: string,
      tid: string,
      data: {
        /**
         * Вставка номенклатуры после этого идентификатора
         * @format uuid
         */
        insert_after?: string;
        rows: {
          /** Наименование номенклатуры */
          title: string;
          /** Ед. изм. */
          unit: string;
          /**
           * Цена за ед.
           * @format float
           */
          plan_price?: number;
          /**
           * Объем
           * @format float
           */
          plan_volume?: number;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          rows?: PassportRow[];
          shifted_rows?: PassportRow[];
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/many`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить раздел из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesDelete
     * @summary Удалить раздел
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{id}
     * @secure
     */
    projectsSectionsTypesNomenclaturesDelete: (
      pid: string,
      sid: string,
      tid: string,
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<DeleteRowResult, Error>({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Переименовать строку в паспорте проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsRowsRenameCreate
     * @summary Переименовать строку
     * @request POST:/passport/projects/{id}/rows/rename
     * @secure
     */
    projectsRowsRenameCreate: (
      id: string,
      data: {
        /**
         * Идентификатор строки
         * @format uuid
         */
        id: string;
        /** Наименование раздела/группы/номенклатуры */
        title: string;
        /** Тип строки */
        type: "section" | "type" | "nomenclature";
      },
      params: RequestParams = {},
    ) =>
      this.request<RenamedRowResult, Error>({
        path: `/passport/projects/${id}/rows/rename`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить строку в паспорте проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsRowsUpdateCreate
     * @summary Обновить строку
     * @request POST:/passport/projects/{id}/rows/update
     * @secure
     */
    projectsRowsUpdateCreate: (
      id: string,
      data: {
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Идентификатор номенклатуры
         * @format uuid
         */
        nomenclature_id?: string;
        value?: {
          /**
           * Бюджет-ориентир
           * @format float
           */
          guideline_amount?: number;
          /**
           * БП: цена за ед.
           * @format float
           */
          plan_price?: number;
          /**
           * БП: объем
           * @format float
           */
          plan_volume?: number;
          /** Ед. изм. */
          unit?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result?: boolean;
          row?: PassportRow;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${id}/rows/update`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Подтвердить бюджет-ориентир проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsBudgetGuidelineConfirmCreate
     * @summary Подтвердить бюджет-ориентир
     * @request POST:/passport/projects/{id}/budget/guideline/confirm
     * @secure
     */
    projectsBudgetGuidelineConfirmCreate: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResultResponse, Error>({
        path: `/passport/projects/${id}/budget/guideline/confirm`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Отправить несколько строк/групп/разделов на согласование БП
     *
     * @tags Паспорт проекта
     * @name ProjectsBudgetPlanStatusApproveRequestCreate
     * @summary Отправка на согласование строк БП
     * @request POST:/passport/projects/{id}/budget/plan/status/approve-request
     * @secure
     */
    projectsBudgetPlanStatusApproveRequestCreate: (
      id: string,
      data: {
        /** Список идентификаторов строк */
        ids?: string[];
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Тип строк отправляемых на согласование
         * @example "section"
         */
        type: "project" | "section" | "work_type" | "nomenclature";
      },
      params: RequestParams = {},
    ) =>
      this.request<PlanStatusResponse, Error>({
        path: `/passport/projects/${id}/budget/plan/status/approve-request`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Согласовать несколько строк/групп/разделов БП
     *
     * @tags Паспорт проекта
     * @name ProjectsBudgetPlanStatusApproveCreate
     * @summary Согласовать несколько строк БП
     * @request POST:/passport/projects/{id}/budget/plan/status/approve
     * @secure
     */
    projectsBudgetPlanStatusApproveCreate: (
      id: string,
      data: {
        /** Список идентификаторов строк */
        ids?: string[];
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Тип строк отправляемых на согласование
         * @example "section"
         */
        type: "project" | "section" | "work_type" | "nomenclature";
      },
      params: RequestParams = {},
    ) =>
      this.request<PlanStatusResponse, Error>({
        path: `/passport/projects/${id}/budget/plan/status/approve`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Отклонить несколько строк/групп/разделов БП
     *
     * @tags Паспорт проекта
     * @name ProjectsBudgetPlanStatusRejectCreate
     * @summary Отклонить несколько строк БП
     * @request POST:/passport/projects/{id}/budget/plan/status/reject
     * @secure
     */
    projectsBudgetPlanStatusRejectCreate: (
      id: string,
      data: {
        /** Список идентификаторов строк */
        ids?: string[];
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Тип строк отправляемых на согласование
         * @example "section"
         */
        type: "project" | "section" | "work_type" | "nomenclature";
        /** Причина отклонения */
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlanStatusResponse, Error>({
        path: `/passport/projects/${id}/budget/plan/status/reject`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Разрешить редактирование нескольких строк/групп/разделов БП
     *
     * @tags Паспорт проекта
     * @name ProjectsBudgetPlanUnlockCreate
     * @summary Разрешить редактирование нескольких строк БП
     * @request POST:/passport/projects/{id}/budget/plan/unlock
     * @secure
     */
    projectsBudgetPlanUnlockCreate: (
      id: string,
      data: {
        /** Список идентификаторов строк */
        ids?: string[];
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Тип строк отправляемых на согласование
         * @example "section"
         */
        type: "project" | "section" | "work_type" | "nomenclature";
      },
      params: RequestParams = {},
    ) =>
      this.request<PlanStatusResponse, Error>({
        path: `/passport/projects/${id}/budget/plan/unlock`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить контракт к номенклатуре
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesContractsCreate
     * @summary Добавить контракт
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/contracts
     * @secure
     */
    projectsSectionsTypesNomenclaturesContractsCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        /**
         * Идентификатор контрагента
         * @format uuid
         */
        contractor_id: string;
        /**
         * Дата заключения контракта
         * @format date
         * @example "02.02.2024"
         */
        contract_date: string;
        /**
         * Дата старта работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        start_date?: string;
        /**
         * Дата окончания работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        end_date?: string;
        /**
         * Номер контракта
         * @example "К10019аа/222"
         */
        contract_number: string;
        /**
         * Цена за ед., строго больше 0
         * @format float
         * @min 0
         */
        price: number;
        /**
         * Объем, строго больше 0
         * @format float
         * @min 0
         */
        volume: number;
        /**
         * Сумма, строго больше 0
         * @format float
         * @min 0
         */
        amount: number;
        /** Список идентификаторов загруженных файлов */
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/contracts`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить контракт из номенклатуре
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesContractsDelete
     * @summary Удалить контракт
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/contracts/{id}
     * @secure
     */
    projectsSectionsTypesNomenclaturesContractsDelete: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      id: string,
      query?: {
        /**
         * Флаг, отправлять ли события в сокет
         * @min 0
         * @max 1
         * @default 0
         */
        send_events?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/contracts/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов контракта в архив .zip
     *
     * @tags Паспорт проекта
     * @name ContractsFilesArchiveDetail
     * @summary Экспорт файлов контракта
     * @request GET:/passport/contracts/{id}/files-archive
     * @secure
     */
    contractsFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/passport/contracts/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Обновить данные рекомендуемых поставщиков
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesContractorsCreate
     * @summary Обновить контрагентов
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/contractors
     * @secure
     */
    projectsSectionsTypesNomenclaturesContractorsCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        contractor_ids: string[];
        /**
         * Флаг для принудительной отправки событий об обновлениях в сокет-сервер
         * @default false
         */
        send_events?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/contractors`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить ответственного за заключение контрактов
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesContractResponsibleCreate
     * @summary Обновить ответственного за заключение контрактов
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/contract-responsible
     * @secure
     */
    projectsSectionsTypesNomenclaturesContractResponsibleCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        user_ids?: string[];
        /**
         * Флаг для принудительной отправки событий об обновлениях в сокет-сервер
         * @default false
         */
        send_events?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/contract-responsible`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить счет на оплату к номенклатуре
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesInvoicesCreate
     * @summary Добавить счет на оплату
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/invoices
     * @secure
     */
    projectsSectionsTypesNomenclaturesInvoicesCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        /**
         * Идентификатор контрагента из рекомендованного списка
         * @format uuid
         */
        contractor_id: string;
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id: string;
        /**
         * Дата счета
         * @format date
         * @example "02.02.2024"
         */
        invoice_date: string;
        /**
         * Номер счета
         * @example 111111
         */
        invoice_number: string;
        /**
         * Цена за ед., строго больше 0
         * @format float
         * @min 0
         */
        price: number;
        /**
         * Объем, строго больше 0
         * @format float
         * @min 0
         */
        volume: number;
        /**
         * Общая сумма счета, строго больше 0
         * @format float
         * @min 0
         */
        amount: number;
        /** Список идентификаторов загруженных файлов */
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/invoices`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить счет на оплату из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesInvoicesDelete
     * @summary Удалить счет
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/invoices/{id}
     * @secure
     */
    projectsSectionsTypesNomenclaturesInvoicesDelete: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      id: string,
      query?: {
        /**
         * Флаг, отправлять ли события в сокет
         * @min 0
         * @max 1
         * @default 0
         */
        send_events?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/invoices/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов счета в архив .zip
     *
     * @tags Паспорт проекта
     * @name InvoicesFilesArchiveDetail
     * @summary Экспорт файлов счета
     * @request GET:/passport/invoices/{id}/files-archive
     * @secure
     */
    invoicesFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/passport/invoices/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Добавить ТТН к номенклатуре
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesWaybillsCreate
     * @summary Добавить ТТН
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/waybills
     * @secure
     */
    projectsSectionsTypesNomenclaturesWaybillsCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id?: string;
        /**
         * Дата ТТН
         * @format date
         * @example "02.02.2024"
         */
        waybill_date: string;
        /**
         * Номер ТТН
         * @example 111111
         */
        waybill_number: string;
        /**
         * Цена за ед., строго больше 0
         * @format float
         * @min 0
         */
        price: number;
        /**
         * Объем, строго больше 0
         * @format float
         * @min 0
         */
        volume: number;
        /**
         * Общая сумма счета, строго больше 0
         * @format float
         * @min 0
         */
        amount: number;
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/waybills`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить ТТН из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesWaybillsDelete
     * @summary Удалить ТТН
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/waybills/{id}
     * @secure
     */
    projectsSectionsTypesNomenclaturesWaybillsDelete: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      id: string,
      query?: {
        /**
         * Флаг, отправлять ли события в сокет
         * @min 0
         * @max 1
         * @default 0
         */
        send_events?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/waybills/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов ТТН в архив .zip
     *
     * @tags Паспорт проекта
     * @name WaybillsFilesArchiveDetail
     * @summary Экспорт файлов ТТН
     * @request GET:/passport/waybills/{id}/files-archive
     * @secure
     */
    waybillsFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/passport/waybills/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Добавить счет-фактуру к номенклатуре
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesBillsCreate
     * @summary Добавить счет-фактуру
     * @request POST:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/bills
     * @secure
     */
    projectsSectionsTypesNomenclaturesBillsCreate: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      data: {
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id?: string;
        /**
         * Дата ТТН
         * @format date
         * @example "02.02.2024"
         */
        bill_date: string;
        /**
         * Номер ТТН
         * @example 111111
         */
        bill_number: string;
        /**
         * Цена за ед., строго больше 0
         * @format float
         * @min 0
         */
        price: number;
        /**
         * Объем, строго больше 0
         * @format float
         * @min 0
         */
        volume: number;
        /**
         * Общая сумма счета, строго больше 0
         * @format float
         * @min 0
         */
        amount: number;
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/bills`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить счет-фактуру на оплату из паспорта проекта
     *
     * @tags Паспорт проекта
     * @name ProjectsSectionsTypesNomenclaturesBillsDelete
     * @summary Удалить счет-фактуру
     * @request DELETE:/passport/projects/{pid}/sections/{sid}/types/{tid}/nomenclatures/{nid}/bills/{id}
     * @secure
     */
    projectsSectionsTypesNomenclaturesBillsDelete: (
      pid: string,
      sid: string,
      tid: string,
      nid: string,
      id: string,
      query?: {
        /**
         * Флаг, отправлять ли события в сокет
         * @min 0
         * @max 1
         * @default 0
         */
        send_events?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/sections/${sid}/types/${tid}/nomenclatures/${nid}/bills/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов СФ в архив .zip
     *
     * @tags Паспорт проекта
     * @name BillsFilesArchiveDetail
     * @summary Экспорт файлов СФ
     * @request GET:/passport/bills/{id}/files-archive
     * @secure
     */
    billsFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/passport/bills/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Обновить статус списку документов типа ТТН
     *
     * @tags Паспорт проекта
     * @name WaybillsStatusCreate
     * @summary Обновить статус ТТН
     * @request POST:/passport/waybills/status
     * @secure
     */
    waybillsStatusCreate: (
      data: {
        document_ids: string[];
        /** Статус документа */
        status: "on_approval" | "approved" | "rejected";
        /** Причина отклонения */
        reject_reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/waybills/status`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить статус списку документов типа счет-фактура
     *
     * @tags Паспорт проекта
     * @name BillsStatusCreate
     * @summary Обновить статус СО
     * @request POST:/passport/bills/status
     * @secure
     */
    billsStatusCreate: (
      data: {
        document_ids: string[];
        /** Статус документа */
        status: "on_approval" | "approved" | "rejected";
        /** Причина отклонения */
        reject_reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/passport/bills/status`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить даты в графике и плановую дату
     *
     * @tags Паспорт проекта
     * @name ProjectsGraphDatesCreate
     * @summary Обновить даты в графике
     * @request POST:/passport/projects/{pid}/graph/dates
     * @secure
     */
    projectsGraphDatesCreate: (
      pid: string,
      data: {
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id: string;
        values: {
          /**
           * Идентификатор номенклатуры
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Дата начала
           * @format date
           * @example "02.02.2024"
           */
          start_date?: string;
          /**
           * Дата окончания
           * @format date
           * @example "02.02.2024"
           */
          end_date?: string;
          /**
           * Плановая дата заключения контракта
           * @format date
           * @example "02.02.2024"
           */
          planning_date?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          updated_rows?: PassportRow[];
          alerts?: PassportAlert[];
          errors?: PassportError[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/graph/dates`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить даты в графике по группам/разделам/проекту
     *
     * @tags Паспорт проекта
     * @name ProjectsGraphTopDatesCreate
     * @summary Обновить даты в графике по группам/разделам/проекту
     * @request POST:/passport/projects/{pid}/graph/top-dates
     * @secure
     */
    projectsGraphTopDatesCreate: (
      pid: string,
      data: {
        /** Флаг что даты задаются для проекта целиком */
        project?: boolean;
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /**
         * Идентификатор группы
         * @format uuid
         */
        type_id?: string;
        /**
         * Дата старта
         * @format date
         * @example "02.02.2024"
         */
        start_date?: string;
        /**
         * Дата окончания
         * @format date
         * @example "02.02.2024"
         */
        end_date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          updated_rows?: PassportRow[];
          alerts?: PassportAlert[];
        },
        Error
      >({
        path: `/passport/projects/${pid}/graph/top-dates`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  invoices = {
    /**
     * @description Получить плоский список номенклатур счета с фильтром по статусу и проекту/разделу
     *
     * @tags Фин. контроллер
     * @name InvoicesList
     * @summary Получить плоский список номенклатур счета
     * @request GET:/invoices
     * @secure
     */
    invoicesList: (
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во эл-тов на странице
         * @default 10
         */
        per_page?: number;
        /** Статус счетов */
        status?:
          | "on_work"
          | "approved"
          | "rejected"
          | "approved_cpm"
          | "to_pay"
          | "sent_cb"
          | "transferred_sgc"
          | "approved_sgc"
          | "paid";
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /** Поиск по номеру счета, имени или ИНН контрагента */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoicesList, Error>({
        path: `/invoices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить сгруппированный по счету список номенклатур с фильтром по статусу, проекту/разделу
     *
     * @tags Фин. контроллер
     * @name GroupedList
     * @summary Получить сгруппированный по счету список номенклатур
     * @request GET:/invoices/grouped
     * @secure
     */
    groupedList: (
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во эл-тов на странице
         * @default 10
         */
        per_page?: number;
        /** Статус счетов */
        status?:
          | "on_work"
          | "approved"
          | "rejected"
          | "approved_cpm"
          | "to_pay"
          | "sent_cb"
          | "transferred_sgc"
          | "approved_sgc"
          | "paid";
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /** Поиск по номеру счета, имени или ИНН контрагента */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoicesGroupedList, Error>({
        path: `/invoices/grouped`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Список активных проектов со счетами с фильтром по статусу счета
     *
     * @tags Фин. контроллер
     * @name ProjectsList
     * @summary Список активных проектов со счетами
     * @request GET:/invoices/projects
     * @secure
     */
    projectsList: (
      query?: {
        /** Статус счетов */
        status?:
          | "on_work"
          | "approved"
          | "rejected"
          | "approved_cpm"
          | "to_pay"
          | "sent_cb"
          | "transferred_sgc"
          | "paid";
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceProjectsList, Error>({
        path: `/invoices/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить детальную информацию по счету на оплату
     *
     * @tags Фин. контроллер
     * @name DocumentsDetail
     * @summary Получить детальную информацию по счету на оплату
     * @request GET:/invoices/documents/{id}
     * @secure
     */
    documentsDetail: (id: string, params: RequestParams = {}) =>
      this.request<InvoiceDocument, Error>({
        path: `/invoices/documents/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов счета в архив .zip
     *
     * @tags Фин. контроллер
     * @name DocumentsFilesArchiveDetail
     * @summary Экспорт файлов счета
     * @request GET:/invoices/documents/{id}/files-archive
     * @secure
     */
    documentsFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/invoices/documents/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Перевести счета в статус "К оплате"
     *
     * @tags Фин. контроллер
     * @name StatusToPayCreate
     * @summary Перевести счета в статус "К оплате"
     * @request POST:/invoices/status/to-pay
     * @secure
     */
    statusToPayCreate: (
      data: {
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceStatusUpdated, Error>({
        path: `/invoices/status/to-pay`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Перевести счет в статус "Оплачен"
     *
     * @tags Фин. контроллер
     * @name StatusPaidCreate
     * @summary Перевести счет в статус "Оплачен"
     * @request POST:/invoices/status/paid
     * @secure
     */
    statusPaidCreate: (
      data: {
        /**
         * Идентификатор счета
         * @format uuid
         */
        id?: string;
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceStatusUpdated, Error>({
        path: `/invoices/status/paid`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Перевести счета в статус "Отклонен"
     *
     * @tags Фин. контроллер
     * @name StatusRejectCreate
     * @summary Перевести счета в статус "Отклонен"
     * @request POST:/invoices/status/reject
     * @secure
     */
    statusRejectCreate: (
      data: {
        ids: string[];
        /** Причина отклонения счетов */
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceStatusUpdated, Error>({
        path: `/invoices/status/reject`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт реестра счетов в файл .xlsx
     *
     * @tags Фин. контроллер
     * @name ExportCreate
     * @summary Экспорт реестра счетов
     * @request POST:/invoices/export
     * @secure
     */
    exportCreate: (
      data: {
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<BinaryResponse, Error>({
        path: `/invoices/export`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  documents = {
    /**
     * @description Добавить контракт по нескольким строкам плана проектов
     *
     * @tags Документы
     * @name ContractsCreate
     * @summary Добавить контракт
     * @request POST:/documents/contracts
     * @secure
     */
    contractsCreate: (
      data: {
        /**
         * Идентификатор контрагента
         * @format uuid
         */
        contractor_id?: string;
        /**
         * Дата заключения контракта
         * @format date
         * @example "02.02.2024"
         */
        contract_date: string;
        /**
         * Дата старта работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        start_date?: string;
        /**
         * Дата окончания работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        end_date?: string;
        /**
         * Номер контракта
         * @example "К10019аа/222"
         */
        contract_number: string;
        nomenclatures: {
          /**
           * Идентификатор проекта
           * @format uuid
           */
          project_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          type_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Цена за ед., строго больше 0
           * @format float
           * @min 0
           */
          price?: number;
          /**
           * Объем, строго больше 0
           * @format float
           * @min 0
           */
          volume?: number;
          /**
           * Сумма, строго больше 0
           * @format float
           * @min 0
           */
          amount?: number;
        }[];
        /** Список идентификаторов загруженных файлов */
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/documents/contracts`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить контракт по нескольким строкам плана проектов
     *
     * @tags Документы
     * @name ContractsUpdate
     * @summary Обновить контракт
     * @request PUT:/documents/contracts/{id}
     * @secure
     */
    contractsUpdate: (
      id: string,
      data: {
        /**
         * Идентификатор контрагента
         * @format uuid
         */
        contractor_id?: string;
        /**
         * Дата заключения контракта
         * @format date
         * @example "02.02.2024"
         */
        contract_date?: string;
        /**
         * Дата старта работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        start_date?: string;
        /**
         * Дата окончания работ по контракту
         * @format date
         * @example "02.02.2024"
         */
        end_date?: string;
        /**
         * Номер контракта
         * @example "К10019аа/222"
         */
        contract_number?: string;
        nomenclatures?: {
          /**
           * Идентификатор проекта
           * @format uuid
           */
          project_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          type_id?: string;
          /**
           * Идентификатор проекта
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Цена за ед., строго больше 0
           * @format float
           * @min 0
           */
          price?: number;
          /**
           * Объем, строго больше 0
           * @format float
           * @min 0
           */
          volume?: number;
          /**
           * Сумма, строго больше 0
           * @format float
           * @min 0
           */
          amount?: number;
        }[];
        /** Список идентификаторов загруженных файлов */
        file_ids?: string[];
        delete_file_path?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/documents/contracts/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить счет на оплату по нескольким строкам плана проектов
     *
     * @tags Документы
     * @name InvoicesCreate
     * @summary Добавить счет на оплату
     * @request POST:/documents/invoices
     * @secure
     */
    invoicesCreate: (
      data: {
        /**
         * Идентификатор контрагента
         * @format uuid
         */
        contractor_id: string;
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id: string;
        /**
         * Дата счета
         * @format date
         * @example "02.02.2024"
         */
        invoice_date: string;
        /**
         * Номер счета
         * @example "К10019аа/222"
         */
        invoice_number: string;
        nomenclatures: {
          /**
           * Идентификатор проекта
           * @format uuid
           */
          project_id?: string;
          /**
           * Идентификатор раздела
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор группы
           * @format uuid
           */
          type_id?: string;
          /**
           * Идентификатор номенклатуры
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Цена за ед., строго больше 0
           * @format float
           * @min 0
           */
          price?: number;
          /**
           * Объем, строго больше 0
           * @format float
           * @min 0
           */
          volume?: number;
          /**
           * Сумма, строго больше 0
           * @format float
           * @min 0
           */
          amount?: number;
        }[];
        /** Список идентификаторов загруженных файлов */
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/documents/invoices`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить ТТН по нескольким строкам плана проектов
     *
     * @tags Документы
     * @name WaybillsCreate
     * @summary Добавить ТТН
     * @request POST:/documents/waybills
     * @secure
     */
    waybillsCreate: (
      data: {
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id?: string;
        /**
         * Дата документа
         * @format date
         * @example "02.02.2024"
         */
        waybill_date: string;
        /**
         * Номер документа
         * @example "К10019аа/222"
         */
        waybill_number: string;
        nomenclatures: {
          /**
           * Идентификатор проекта
           * @format uuid
           */
          project_id?: string;
          /**
           * Идентификатор раздела
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор группы
           * @format uuid
           */
          type_id?: string;
          /**
           * Идентификатор номенклатуры
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Цена за ед., строго больше 0
           * @format float
           * @min 0
           */
          price?: number;
          /**
           * Объем, строго больше 0
           * @format float
           * @min 0
           */
          volume?: number;
          /**
           * Сумма, строго больше 0
           * @format float
           * @min 0
           */
          amount?: number;
        }[];
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/documents/waybills`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить счет-фактуру по нескольким строкам плана проектов
     *
     * @tags Документы
     * @name BillsCreate
     * @summary Добавить счет-фактуру
     * @request POST:/documents/bills
     * @secure
     */
    billsCreate: (
      data: {
        /**
         * Идентификатор документа контракта
         * @format uuid
         */
        contract_id?: string;
        /**
         * Дата документа
         * @format date
         * @example "02.02.2024"
         */
        bill_date: string;
        /**
         * Номер документа
         * @example "К10019аа/222"
         */
        bill_number: string;
        nomenclatures: {
          /**
           * Идентификатор проекта
           * @format uuid
           */
          project_id?: string;
          /**
           * Идентификатор раздела
           * @format uuid
           */
          section_id?: string;
          /**
           * Идентификатор группы
           * @format uuid
           */
          type_id?: string;
          /**
           * Идентификатор номенклатуры
           * @format uuid
           */
          nomenclature_id?: string;
          /**
           * Цена за ед., строго больше 0
           * @format float
           * @min 0
           */
          price?: number;
          /**
           * Объем, строго больше 0
           * @format float
           * @min 0
           */
          volume?: number;
          /**
           * Сумма, строго больше 0
           * @format float
           * @min 0
           */
          amount?: number;
        }[];
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Результат операции */
          result?: boolean;
          updated_rows?: PassportRow[];
        },
        Error
      >({
        path: `/documents/bills`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  errors = {
    /**
     * @description Получить список ошибок в пасспорте проекта для каждой строки
     *
     * @tags Ошибки проекта
     * @name ErrorsList
     * @summary Список ошибок проектов по каждой строке
     * @request GET:/errors
     * @secure
     */
    errorsList: (
      query?: {
        /**
         * Фильтр по статусу ошибок, доступен множественный выбор через запятую
         * @default "open,late"
         */
        status?: "open" | "closed" | "late";
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор пользователя, совершившего ошибку
         * @format uuid
         */
        user_id?: string;
        /**
         * Идентификатор пользователя, ответственного за погашение ошибки
         * @format uuid
         */
        mentor_id?: string;
        /** Код типа ошибки */
        type?:
          | "contract_planning_date_error"
          | "contract_not_completed_error"
          | "contract_work_start_date_error"
          | "contract_work_end_date_error"
          | "contract_over_error"
          | "invoice_over_error"
          | "waybill_over_error"
          | "bill_over_error";
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во ошибок на странице
         * @default 10
         */
        per_page?: number;
        /**
         * Получить все ошибки без пагинации
         * @min 0
         * @max 1
         * @default 0
         */
        all?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectErrorList, Error>({
        path: `/errors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить упрощенный список ошибок в пасспорте проекта
     *
     * @tags Ошибки проекта
     * @name SimpleList
     * @summary Упрощенный список ошибок проектов
     * @request GET:/errors/simple
     * @secure
     */
    simpleList: (
      query?: {
        /**
         * Фильтр по статусу ошибок, доступен множественный выбор через запятую
         * @default "open,late"
         */
        status?: "open" | "closed" | "late";
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор пользователя, совершившего ошибку
         * @format uuid
         */
        user_id?: string;
        /**
         * Идентификатор пользователя, ответственного за погашение ошибки
         * @format uuid
         */
        mentor_id?: string;
        /** Код типа ошибки */
        type?:
          | "contract_planning_date_error"
          | "contract_not_completed_error"
          | "contract_work_start_date_error"
          | "contract_work_end_date_error"
          | "contract_over_error"
          | "invoice_over_error"
          | "waybill_over_error"
          | "bill_over_error";
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во ошибок на странице
         * @default 10
         */
        per_page?: number;
        /**
         * Получить все ошибки без пагинации
         * @min 0
         * @max 1
         * @default 0
         */
        all?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectSimpleErrorList, Error>({
        path: `/errors/simple`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Посмотреть ошибку из пасспорте проекта
     *
     * @tags Ошибки проекта
     * @name ErrorsDetail
     * @summary Посмотреть ошибку
     * @request GET:/errors/{id}
     * @secure
     */
    errorsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ProjectErrorDetail, Error>({
        path: `/errors/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Погасить ошибку в пасспорте проекта
     *
     * @tags Ошибки проекта
     * @name CloseCreate
     * @summary Погасить ошибку
     * @request POST:/errors/{id}/close
     * @secure
     */
    closeCreate: (id: string, params: RequestParams = {}) =>
      this.request<ProjectErrorDetail, Error>({
        path: `/errors/${id}/close`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Отменить ошибку в пасспорте проекта (только для ошибок с документами)
     *
     * @tags Ошибки проекта
     * @name CancelCreate
     * @summary Отменить ошибку документа
     * @request POST:/errors/{id}/cancel
     * @secure
     */
    cancelCreate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          result?: boolean;
          updated_rows?: PassportRow;
        },
        Error
      >({
        path: `/errors/${id}/cancel`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contracts = {
    /**
     * @description Получить список контрактов сгруппированных по документу с фильтром по статусу и проекту/разделу
     *
     * @tags Согласование контрактов
     * @name ContractsList
     * @summary Получить список контрактов сгруппированных по документу
     * @request GET:/contracts
     * @secure
     */
    contractsList: (
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во эл-тов на странице
         * @default 10
         */
        per_page?: number;
        /** Статус счетов */
        status?: "on_work" | "rejected" | "on_approval_by_spm" | "on_approval_by_dk" | "signing" | "signed";
        /**
         * Идентификатор проекта
         * @format uuid
         */
        project_id?: string;
        /**
         * Идентификатор раздела
         * @format uuid
         */
        section_id?: string;
        /** Поиск по номеру контракта или наименованию контрагента */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractsGroupedList, Error>({
        path: `/contracts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Список активных проектов с контрактами с фильтром по статусу
     *
     * @tags Согласование контрактов
     * @name ProjectsList
     * @summary Список активных проектов с контрактами
     * @request GET:/contracts/projects
     * @secure
     */
    projectsList: (
      query?: {
        /** Статус счетов */
        status?: "on_work" | "rejected" | "on_approval_by_spm" | "on_approval_by_dk" | "signing" | "signed";
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractProjectsList, Error>({
        path: `/contracts/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить детальную информацию по контракту
     *
     * @tags Согласование контрактов
     * @name DocumentsDetail
     * @summary Получить детальную информацию по контракту
     * @request GET:/contracts/documents/{id}
     * @secure
     */
    documentsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContractDocument, Error>({
        path: `/contracts/documents/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Экспорт файлов контракта в архив .zip
     *
     * @tags Согласование контрактов
     * @name DocumentsFilesArchiveDetail
     * @summary Экспорт файлов контракта
     * @request GET:/contracts/documents/{id}/files-archive
     * @secure
     */
    documentsFilesArchiveDetail: (id: string, params: RequestParams = {}) =>
      this.request<BinaryResponse, Error>({
        path: `/contracts/documents/${id}/files-archive`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Перевести контракт в новый статус
     *
     * @tags Согласование контрактов
     * @name StatusCreate
     * @summary Перевести контракт в новый статус
     * @request POST:/contracts/status
     * @secure
     */
    statusCreate: (
      data: {
        /**
         * Идентификатор контракта
         * @format uuid
         */
        id: string;
        /**
         * Новый статус
         * @example "on_approval_by_spm"
         */
        status: "on_approval_by_spm" | "on_approval_by_dk" | "signing" | "signed";
        /** Причина отклонения, если статус rejected */
        reason?: string;
        file_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractStatusUpdated, Error>({
        path: `/contracts/status`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
