import { ColumnsType } from 'antd/es/table';
import { TableRow } from '../../ApiDirectory';
import { Input, Popover } from 'antd';
import { stopEvent } from '../../shared/stopEvent';
import { LocalValueNomenclature } from './NomenclatureListHelpers';

const nomenclaturesColumns: (props: {
    localValues: { [nomenclature_id: string]: LocalValueNomenclature };
    onView: (id: string) => void;
    onEdit: (type: 'price' | 'unit' | 'title', section_id?: string, type_id?: string, nomenclature_id?: string, region_id?: string, value?: string) => void;
    onEditEnd: (type: 'price' | 'unit' | 'title', section_id?: string, type_id?: string, nomenclature_id?: string, region_id?: string, value?: string) => void;
    onAdd: (section_id?: string, type_id?: string) => void;
    onAddNomenclature: () => void;
    onArchive: (section_id?: string, type_id?: string, nomenclature_id?: string) => void;
    onRestore: (section_id?: string, type_id?: string, nomenclature_id?: string) => void;
    expandedRowKeys: string[],
    setExpandedRowKeys: (keys: string[]) => void;
    t: (key: string) => string;
    processSave: { id: string, region_id?: string, type: 'price' | 'unit' | 'title' }[]
    isOpenPopover: { id: string },
    setIsOpenPopover: (props: { id: string }) => void,
    isOpenRename: { id: string },
    setIsOpenRename: (props: { id: string }) => void
}) => ColumnsType<TableRow & { hasChildren: boolean }> = (props) => {
    return [
        {
            width: 500,
            title: <div className={'nomenclatures-table__title'}>
                <span>Наименование</span>
                <span className={'nomenclatures-table__title_dots'} onClick={e => stopEvent(e)}>
                    <Popover
                        overlayClassName={'nomenclatures-table__popover'}
                        trigger={'click'}
                        arrow={false}
                        placement={'rightTop'}
                        open={props.isOpenPopover.id == 'add_section'}
                        onOpenChange={() => props.setIsOpenPopover({ id: 'add_section' })}
                        content={<div className={'ModalAddRow'}>
                            <span onClick={() => {
                                props.onAdd();
                                props.setIsOpenPopover({ id: '' });
                            }}>Добавить новый раздел</span>
                        </div>}
                    >
                        <img src="/canvas_table/dots_1.svg" alt="" />
                    </Popover>
                </span>
            </div>,
            dataIndex: 'title',
            // fixed: 'left',
            render: (text, record) => {
                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                const isExpanded = props.expandedRowKeys.includes(id);

                if (props.isOpenRename.id == id) {
                    return <Input
                        onClick={stopEvent}
                        autoFocus
                        className={`nomenclatures-table__title ${record.row_type}`}
                        readOnly={props.processSave.find(p => p.id == record.nomenclature_id && p.type == 'title') != undefined}
                        value={record.title}
                        onChange={val => {
                            props.onEdit('title', record.section_id, record.type_id, record.nomenclature_id, undefined, val.target.value);
                        }}
                        onBlur={val => {
                            props.onEditEnd('title', record.section_id, record.type_id, record.nomenclature_id, undefined, val.target.value);
                            props.setIsOpenRename({ id: '' });
                        }}
                    />;
                }


                return <div className={`nomenclatures-table__title ${record.row_type}`}>
                    {record.row_type == 'section' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'} alt="минус" />) : null}
                    {record.row_type == 'section' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'} alt="минус" />) : null}

                    {record.row_type == 'work_type' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'} alt="минус" />) : null}
                    {record.row_type == 'work_type' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'} alt="минус" />) : null}

                    <span>&nbsp;{record.title}</span>
                    <div className={'nomenclatures-table__title_dots'} onClick={e => stopEvent(e)}>
                        <Popover
                            overlayClassName={'nomenclatures-table__popover'}
                            trigger={'click'}
                            arrow={false}
                            placement={'rightTop'}
                            open={props.isOpenPopover.id == id}
                            onOpenChange={val => {
                                if (val && props.isOpenPopover.id == id) {
                                    props.setIsOpenPopover({ id: '' });
                                } else {
                                    props.setIsOpenPopover({ id });
                                }
                            }}
                            content={<div className={'ModalAddRow'}>
                                {record.row_type == 'section' && <span onClick={() => {
                                    props.setIsOpenPopover({ id: '' });
                                    props.onAdd(record.section_id);
                                }}>Добавить вид работ</span>}
                                {record.row_type == 'work_type' && <span onClick={() => {
                                    props.setIsOpenPopover({ id: '' });
                                    props.onAdd(record.section_id, record.type_id);
                                }}>Добавить номенклатуру</span>}
                                <span onClick={e => {
                                    props.setIsOpenPopover({ id: '' });
                                    props.setIsOpenRename({ id });
                                    stopEvent(e);
                                }}>Переименовать</span>
                                <span>Удалить</span>
                            </div>}>
                            <img src="/canvas_table/dots_1.svg" alt="" />
                        </Popover>
                    </div>
                </div>;
            },
        },
        {
            width: 100,
            title: 'Ед. изм.',
            dataIndex: 'unit',
            render: (text, record) => {
                if (record.row_type == 'nomenclature') {
                    return <Input
                        readOnly={props.processSave.find(p => p.id == record.nomenclature_id && p.type == 'unit') != undefined}
                        className={record.row_type}
                        value={record.unit}
                        onChange={(e) => {
                            props.onEdit('unit', record.section_id, record.type_id, record.nomenclature_id, undefined, e.target.value);
                        }}
                        onBlur={val => {
                            props.onEditEnd('unit', record.section_id, record.type_id, record.nomenclature_id, undefined, val.target.value);
                        }}
                    />;
                }

                return <span className={`${record.row_type}`}></span>;
            },
        },
        {
            title: '',
            render: (text, record) => {
                return (
                    <div className={`${record.row_type}`}></div>
                );
            },
        },
    ];
};

export default nomenclaturesColumns;

